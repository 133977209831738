import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'
import { draggingRightPanelEventName } from 'events/dragging-right-panel'

import { type Failure } from 'api/hooks/useValidateComponent/types'

import { useFlags } from 'hooks/useFlags'

import classes from './ValidationFailures.module.scss'

export const FailureMessage = ({
  failure,
  setValidationMessage
}: {
  failure: Failure
  setValidationMessage: (vmessage: string) => void
}) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement | null>(null)
  const [isClamped, setIsClamped] = useState(false)

  const updateClampedState = useCallback(() => {
    /* istanbul ignore else */
    if (ref.current) {
      const el = ref.current.firstChild as HTMLElement
      setIsClamped(el.scrollHeight > el.clientHeight)
    }
  }, [])

  useEffect(updateClampedState, [updateClampedState])

  useEffect(() => {
    document.addEventListener(draggingRightPanelEventName, updateClampedState)
    window.addEventListener('resize', updateClampedState)
    return () => {
      document.removeEventListener(
        draggingRightPanelEventName,
        updateClampedState
      )
      window.removeEventListener('resize', updateClampedState)
    }
  }, [updateClampedState])

  const { enableLongValidationErrorExpansion } = useFlags()

  return (
    <li>
      <div ref={ref}>
        <Typography format="bcs">{failure.message}</Typography>
      </div>

      {isClamped && enableLongValidationErrorExpansion && (
        <Typography
          as="button"
          type="button"
          onClick={() => {
            setValidationMessage(failure.message)
          }}
          className={classes.ValidationFailures__Link}
          format="bcs"
          data-testid="component-properties-view-details"
        >
          {t('componentProperties.status.errorModalCta')}
        </Typography>
      )}
    </li>
  )
}
