import { useEffect } from 'react'

import {
  isAnyOf,
  type Dispatch,
  type ListenerMiddlewareInstance,
  type MiddlewareAPI
} from '@reduxjs/toolkit'

import { useSaveJob, type UseSaveJob } from 'api/hooks/useSaveJob/useSaveJob'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { jobActions, type RootState } from 'job-lib/store'

import {
  SET_STATE_FROM_YJS_ACTION,
  type SetStateAction
} from 'modules/redux-yjs-bindings/src/patchRedux'

export const effectUpdateWts = async (
  listenerApi: MiddlewareAPI<Dispatch, RootState>,
  saveJob: (ops: UseSaveJob) => Promise<unknown>
) => {
  const {
    job: { job, jobType }
  } = listenerApi.getState()

  if (!job || !jobType) {
    return
  }

  await saveJob({
    job
  })
}

export const useSaveJobListener = (
  listenerMiddleware: ListenerMiddlewareInstance<RootState>
) => {
  const saveJob = useSaveJob()
  const { jobSummaryId } = useProjectInfo()
  const { rolloutEnableWorkingCopyProvider } = useFlags()

  useEffect(() => {
    if (rolloutEnableWorkingCopyProvider) {
      return
    }

    const cleanup = listenerMiddleware.startListening({
      matcher: isAnyOf(
        jobActions.addComponent,
        jobActions.deleteNodes,
        jobActions.overrideComponentParameter,
        jobActions.deleteLink,
        jobActions.updateNodePosition,
        jobActions.setJobAndSave,
        jobActions.addJobVariable,
        jobActions.deleteJobVariable,
        jobActions.cloneComponentGroup,
        jobActions.updateJobVariable,
        jobActions.addNote,
        jobActions.updateNote,
        jobActions.deleteNote,
        jobActions.updateConnectors,
        (action): action is SetStateAction =>
          action.type === SET_STATE_FROM_YJS_ACTION
      ),
      effect: async (_action, listenerApi) => {
        listenerApi.cancelActiveListeners()

        await listenerApi.delay(100)

        await effectUpdateWts(listenerApi, saveJob)
      }
    })

    // active listeners are not cleared down by default
    // this ensures that when the pipeline ID changes any old active listeners are cleared
    return () => {
      cleanup({
        cancelActive: true
      })
    }
    // jobSummaryId is needed to clear down any active listeners when it changes
  }, [
    saveJob,
    listenerMiddleware,
    jobSummaryId,
    rolloutEnableWorkingCopyProvider
  ])
}
