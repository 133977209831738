import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { type NestedPipeline } from 'api/hooks/useEosClient/types/eos'
import { useGetStepsInfinite } from 'api/hooks/useGetSteps/useGetSteps'

import { CenteredSpinner } from '../components/CenteredSpinner'
import ErrorMessage from '../components/ErrorMessage'
import { InfiniteScrollAnchor } from '../components/InfiniteScrollAnchor'
import { Cell, Row } from '../components/PipelineTable'
import classes from './NestedPipelineSteps.module.scss'
import StepRow from './StepRow'

interface Props {
  nestedPipeline: NestedPipeline
  parentDepth: number
  rowClassName?: string
  treeLines: boolean[]
  parentIsLast: boolean
}

const NestedPipelineSteps: FC<Props> = ({
  nestedPipeline,
  parentDepth,
  rowClassName,
  treeLines,
  parentIsLast
}) => {
  const { steps, isFetching, hasMore, isError, fetchNextPage } =
    useGetStepsInfinite(nestedPipeline.id)

  const moreAnchor = hasMore && !isFetching

  const { t } = useTranslation()

  return (
    <>
      {steps?.map((childStep, index) => {
        // Builds a list of the parent tree lines for the child to draw
        const newTreeLines = [...treeLines, !parentIsLast]

        return (
          <StepRow
            rowClassName={rowClassName}
            key={childStep.id}
            step={childStep}
            depth={parentDepth + 1}
            pipelineName={nestedPipeline.name}
            isRoot={false}
            isLast={index === steps.length - 1}
            treeLines={newTreeLines}
          />
        )
      })}
      {(moreAnchor || isFetching || isError) && (
        <Row>
          <Cell fullWidth>
            {isFetching && <CenteredSpinner />}
            {moreAnchor && <InfiniteScrollAnchor onIntersect={fetchNextPage} />}
            {isError && (
              <ErrorMessage
                className={classes.NestedPipelineSteps__ErrorMessage}
              >
                {t('pipelineRunExplorer.networkError')}
              </ErrorMessage>
            )}
          </Cell>
        </Row>
      )}
    </>
  )
}

export default NestedPipelineSteps
