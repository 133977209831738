import { createModularConnectorDestinationStep } from './common/steps/ModularConnectorDestinationStep'
import { type ComponentLayout } from './types'

export const KafkaConfig: ComponentLayout = {
  componentId: 'modular-kafka-input-v1',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'connection'
          },
          {
            dplId: 'bootstrapServers'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'topic'
          },
          {
            dplId: 'consumerGroupIdPrefix'
          },
          {
            dplId: 'dataFormat'
          },
          {
            dplId: 'schemaRegistry'
          }
        ]
      },
      createModularConnectorDestinationStep('configure', null),
      {
        stepId: 'advanced',
        displayName: 'Advanced Settings',
        previousStepId: null,
        nextStepId: null,
        parameters: [{ dplId: 'pollTimeout' }]
      }
    ]
  }
}
