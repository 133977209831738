import { type FC } from 'react'

import { Icon, MicroCta } from '@matillion/component-library'
import classNames from 'classnames'

import type { PipelineStatus } from 'api/hooks/useEosClient/types/eos'

import { CellTypography } from '../components/CellTypography'
import { DEPTH_MARGIN } from '../components/Guidelines/Guidelines'
import TaskStatusIcon from '../components/TaskStatusIcon'
import classes from './PipelineNameCell.module.scss'

interface PipelineNameCellProps {
  depth: number
  pipelineName: string
  isExpanded: boolean
  hasNestedStuff?: boolean
  onClick: () => void
  status: PipelineStatus
}

const PipelineNameCell: FC<PipelineNameCellProps> = ({
  depth,
  pipelineName,
  isExpanded,
  hasNestedStuff,
  onClick,
  status
}) => {
  return (
    <div
      className={classNames(
        classes.PipelineNameCell,
        classes.PipelineNameCell__Expander
      )}
    >
      <div style={{ marginLeft: `${depth * DEPTH_MARGIN}px` }}>
        {hasNestedStuff && (
          <MicroCta
            aria-label={isExpanded ? 'collapse' : 'expand'}
            alt="transparent"
            size="sm"
            className={classNames(classes.RowExpandTrigger, {
              [classes.expanded]: isExpanded
            })}
            onClick={onClick}
          >
            <Icon.ChevronDown />
          </MicroCta>
        )}
      </div>
      <TaskStatusIcon status={status} depth={String(depth)} />
      <CellTypography text={pipelineName} />
    </div>
  )
}

export default PipelineNameCell
