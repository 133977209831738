import { useTranslation } from 'react-i18next'
import { Panel, PanelGroup } from 'react-resizable-panels'
import { Outlet } from 'react-router-dom'

import { Tab } from '@matillion/component-library'
import { useLDClient } from '@matillion/hub-client'
import { ZoomToolbar } from 'file-editors/canvas/modules/Canvas/components/ZoomToolbar/ZoomToolbar'

import { Orientation, ResizeableHandler } from 'components/ResizeableHandler'

import { useFlags } from 'hooks/useFlags'

import { SampleComponentPanel } from 'modules/ComponentProperties/components/SampleComponent'
import { SampleProvider } from 'modules/ComponentProperties/components/SampleComponent/SampleProvider'
import { AgentStatus } from 'modules/core/AgentStatus'
import HeaderBar from 'modules/core/EtlDesigner/components/HeaderBar/HeaderBar'
import RightPanel from 'modules/core/EtlDesigner/components/RightPanel/RightPanel'
import { UndoRedoCapture } from 'modules/core/EtlDesigner/UndoRedoCapture'
import { TaskHistoryPanel } from 'modules/TaskHistoryPanel/TaskHistoryPanel'
import { FileTabs } from 'modules/ui/FileTabs/FileTabs'
import { Taskbar } from 'modules/ui/Taskbar/Taskbar'
import { TaskbarTabsContent, TaskbarTabsList } from 'modules/ui/TaskbarTabs'

import RailSidebar from '../components/SideBar/RailSidebar'
import useHandleBottomPanel, {
  MAX_PANEL_HEIGHT_PCT,
  MIN_PANEL_HEIGHT_PCT
} from '../hooks/useHandleBottomPanel'
import classes from './Layouts.module.scss'
import { TaskbarTabKeys } from './types'

export const MainLayout = () => {
  const { t } = useTranslation()
  const { enableAgentAvailabilityModal } = useFlags()
  const {
    bottomPanelRef,
    containerRef,
    activeTabKey,
    isCollapsed,
    defaultSize,
    handleSelectionChange,
    handleResize
  } = useHandleBottomPanel()
  const { track } = useLDClient()

  const taskbarTabs = [
    <Tab
      key={TaskbarTabKeys.TASK_HISTORY}
      title={t('taskbar.tabs.taskHistory')}
      data-testid="taskbar-tab-task-history"
    >
      <TaskHistoryPanel />
    </Tab>,
    <Tab
      key={TaskbarTabKeys.SAMPLE_DATA}
      title={t('taskbar.tabs.sampleData')}
      data-testid="taskbar-tab-sample-data"
      data-tracker-id="taskbar-tab-sample-data"
    >
      <SampleProvider>
        <SampleComponentPanel />
      </SampleProvider>
    </Tab>
  ]

  return (
    <>
      <HeaderBar />

      <div className={classes.ApplicationContent} ref={containerRef}>
        <PanelGroup direction="horizontal" autoSaveId="all-panels">
          <RailSidebar />

          <Panel className={classes.TaskbarPanel} id="main-panel" order={2}>
            <FileTabs />

            <PanelGroup
              id="jobs-panel-group"
              direction="vertical"
              autoSaveId="jobs-panel-group"
            >
              <Panel>
                <PanelGroup
                  id="canvas-panel-group"
                  direction="horizontal"
                  autoSaveId="canvas-panel-group"
                >
                  <Panel id="canvas-panel" order={1}>
                    <PanelGroup direction="vertical" id="canvas-taskbar-group">
                      <Panel
                        minSize={15}
                        id="taskbar-container-children"
                        order={1}
                      >
                        <UndoRedoCapture className={classes.CanvasContainer}>
                          <Outlet />
                        </UndoRedoCapture>
                      </Panel>
                      <ResizeableHandler
                        orientation={Orientation.HORIZONTAL}
                        hidden={!activeTabKey}
                        id="bottom-panel"
                      />
                      <Panel
                        defaultSize={isCollapsed ? 0 : defaultSize}
                        minSize={isCollapsed ? 0 : MIN_PANEL_HEIGHT_PCT}
                        maxSize={MAX_PANEL_HEIGHT_PCT}
                        collapsedSize={0}
                        ref={bottomPanelRef}
                        onResize={handleResize}
                        id="bottom-panel"
                        order={2}
                      >
                        <div
                          className={classes.TaskbarContainerPanel}
                          data-testid="taskbar-tabs-content-scrollable"
                        >
                          <TaskbarTabsContent activeTabId={activeTabKey}>
                            {taskbarTabs}
                          </TaskbarTabsContent>
                        </div>
                      </Panel>
                    </PanelGroup>
                  </Panel>

                  <RightPanel />
                </PanelGroup>
              </Panel>

              <Taskbar collapsed={isCollapsed}>
                <TaskbarTabsList
                  activeTabId={activeTabKey}
                  onChange={(tabKey) => {
                    if (tabKey === TaskbarTabKeys.SAMPLE_DATA) {
                      track('designer-sample-tab-opened')
                    }
                    handleSelectionChange(tabKey)
                  }}
                >
                  {taskbarTabs}
                </TaskbarTabsList>
                <div data-testid="canvas-zoom-controls">
                  <ZoomToolbar />
                </div>
              </Taskbar>
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </div>

      {enableAgentAvailabilityModal && <AgentStatus />}
    </>
  )
}
