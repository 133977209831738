import { type FC } from 'react'

import { Tab, Tabs } from '@matillion/component-library'

import { ComponentEditorTabType } from 'api/hooks/useGetComponentMetadata/types'

import { SQLViewer } from 'modules/ComponentProperties/components/SQLViewer'

import classes from './ComponentParameters.module.scss'
import { ComponentParameterWizardContainer } from './ComponentParameterWizardContainer'
import { ComponentNameParameter } from './components/ComponentNameParameter'
import { ComponentSupersededWarning } from './components/ComponentSupersededWarning/ComponentSupersededWarning'
import { type ComponentParametersWizardProps } from './types'

const ComponentParametersContent: FC<ComponentParametersWizardProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  onBlur
}) => {
  return (
    <>
      <ComponentNameParameter
        componentInstance={componentInstance}
        componentSummaryId={componentSummaryId}
        componentMetadata={componentMetadata}
        onEdit={onEdit}
        onBlur={onBlur}
      />
      <ComponentSupersededWarning
        componentMetadata={componentMetadata}
        componentSummaryId={componentSummaryId}
      />
      {componentMetadata.editorTabs.includes(ComponentEditorTabType.SQL) ? (
        <Tabs
          className={classes.ComponentParameter__Tabs}
          listClassName={classes.ComponentParameter__TabList}
          contentClassName={classes.ComponentParameter__TabPanel}
        >
          <Tab
            key="configuration"
            title="Configuration"
            data-testid="wizard-tab-component-properties-configuration"
          >
            <ComponentParameterWizardContainer
              componentInstance={componentInstance}
              componentMetadata={componentMetadata}
              componentSummaryId={componentSummaryId}
              onEdit={onEdit}
              onBlur={onBlur}
            />
          </Tab>
          <Tab
            key="sql"
            title="SQL"
            data-testid="wizard-tab-component-properties-sql"
          >
            <div className={classes.ComponentSQLViewer}>
              <SQLViewer />
            </div>
          </Tab>
        </Tabs>
      ) : (
        <ComponentParameterWizardContainer
          componentInstance={componentInstance}
          componentMetadata={componentMetadata}
          componentSummaryId={componentSummaryId}
          onEdit={onEdit}
          onBlur={onBlur}
        />
      )}
    </>
  )
}

export default ComponentParametersContent
