import { useMutation } from '@tanstack/react-query'

import { type PipelineContext } from 'api/hooks/copilot/model'
import useCopilotClient from 'api/hooks/useCopilotClient/useCopilotClient'

import { optimiseQuery } from '../requests'

export interface OptimiseQueryInput {
  requestId: string
  pipeline: PipelineContext
  warehouse: string
  message: string
}

export const useOptimiseQuery = () => {
  const client = useCopilotClient()

  return useMutation(async (input: OptimiseQueryInput) => {
    const { data } = await optimiseQuery(client, input)

    return data
  })
}
