import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import classes from './PipelineRunExplorer.module.scss'
import PipelineTree from './PipelineTree/PipelineTree'

const PipelineRunExplorer = () => {
  const { t } = useTranslation()
  const { flowInstanceId } = useProjectInfo()
  if (!flowInstanceId) {
    return (
      <div className={classes.Error} data-testid="pipeline-error-container">
        <Icon.InfoNoBg className={classes.Error__Icon} />
        <Typography className={classes.Error__Text}>
          {t('translation:taskExplorer.networkError')}
        </Typography>
      </div>
    )
  }
  return <PipelineTree pipelineRunId={flowInstanceId} />
}

export default PipelineRunExplorer
