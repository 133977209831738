import { useMemo, useState, type Dispatch, type SetStateAction } from 'react'

import {
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import { type FullPath } from 'modules/ParameterEditors/types'

import classes from '../../UrlEditor.module.scss'
import NameCell from '../NameCell/NameCell'

interface ContainerRow {
  id: string
  isFolder: boolean
  container: {
    containerName: string
  }
  actions: {
    onClick: () => void
    upOneLevel: () => void
  }
}

export type GridRow = ContainerRow & DataGridRow

export type GridColumns = Array<DataGridColumnProps<GridRow>>

interface Props {
  title: string
  fullPath: FullPath
  setFullPath: Dispatch<SetStateAction<FullPath>>
  setWarehouseUrl: Dispatch<SetStateAction<string>>
  buckets: string[]
  folders: string[]
  objects: string[]
}

export enum SortOrder {
  ASC = 'ASC'
}

export const sortRowsWithUpOneLevel = (rows: GridRow[], sortOrder: string) => {
  return rows.sort((a, b) => {
    const bContainerName = b.container.containerName
    const aContainerName = a.container.containerName

    if (bContainerName === '... /') {
      return 1
    } else if (sortOrder === SortOrder.ASC) {
      return aContainerName.localeCompare(bContainerName)
    } else {
      return bContainerName.localeCompare(aContainerName)
    }
  })
}

const getGridRow = (
  containerName: string,
  fullPath: FullPath,
  isFolder: boolean,
  setWarehouseUrl: Dispatch<SetStateAction<string>>,
  setFullPath: Dispatch<SetStateAction<FullPath>>
) => {
  return {
    id: isFolder ? containerName + 'folder' : containerName + 'file',
    container: {
      containerName
    },
    isFolder,
    actions: {
      onClick: () => {
        setWarehouseUrl(
          fullPath.path[0]
            ? `${fullPath.path.join('/')}/${containerName}`
            : `${containerName}`
        )
        if (isFolder) {
          setFullPath((fp) => {
            return {
              path: [...fp.path, containerName],
              isFolder
            }
          })
        }
      },
      upOneLevel: () => {
        setWarehouseUrl(
          fullPath.path.length > 1
            ? `${fullPath.path.slice(0, -1).join('/')}`
            : ''
        )
        setFullPath((fp) => {
          return { path: fp.path.slice(0, -1), isFolder: true }
        })
      }
    }
  }
}

export const useGrid = ({
  title,
  fullPath,
  setFullPath,
  setWarehouseUrl,
  buckets,
  folders,
  objects
}: Props) => {
  const [sort, setSort] = useState<SortOrder>(SortOrder.ASC)

  const parentName =
    fullPath.path.length > 1 ? fullPath.path.slice(-1) : fullPath.path
  const isParentNameFile =
    fullPath.path.length > 1 &&
    folders.length === 0 &&
    objects.length === 0 &&
    /^[^.]+\.[^.]+$/.exec(parentName[0])
  const columns: GridColumns = [
    {
      key: 'container',
      title,
      as: NameCell,
      mapValues: (row) => ({
        containerName: row.container.containerName,
        onClick: row.actions.onClick,
        upOneLevel: row.actions.upOneLevel,
        isFolder: row.isFolder,
        parentName,
        isParentNameFile
      }),
      headerClassName: classes['Buckets--NameHeader'],
      className: classes['Buckets--NameCell'],
      sortable: true,
      sortIconPosition: 'right'
    }
  ]

  const rows = useMemo<GridRow[]>(() => {
    let newRows = []

    if (fullPath.path[0]) {
      const folderRows = folders.map((containerName: string) =>
        getGridRow(containerName, fullPath, true, setWarehouseUrl, setFullPath)
      )
      const objectRows = objects.map((containerName: string) =>
        getGridRow(containerName, fullPath, false, setWarehouseUrl, setFullPath)
      )
      newRows = [
        getGridRow('... /', fullPath, false, setWarehouseUrl, setFullPath),
        ...folderRows,
        ...objectRows
      ]
    } else {
      newRows = buckets.map((containerName: string) =>
        getGridRow(containerName, fullPath, true, setWarehouseUrl, setFullPath)
      )
    }

    return sortRowsWithUpOneLevel(newRows, sort)
  }, [objects, folders, buckets, setWarehouseUrl, setFullPath, fullPath, sort])

  return { columns, rows, setSort, sort }
}
