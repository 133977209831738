import { memo, type FunctionComponent } from 'react'
import { BaseEdge, Position, type EdgeProps } from 'reactflow'

import classNames from 'classnames'

import { ReactComponent as FailedIcon } from 'assets/failed-small.svg'
import { ReactComponent as SuccessIcon } from 'assets/success-small.svg'
import { ReactComponent as FalseIcon } from 'assets/thumbs-down.svg'
import { ReactComponent as TrueIcon } from 'assets/thumbs-up.svg'

import {
  ConnectionPortType,
  type OutputPortType
} from 'job-lib/types/Components'

import classes from './FlowEdge.module.scss'

export const getEdgeColor = (sourcePort?: string | null) => {
  switch (sourcePort) {
    case ConnectionPortType.SUCCESS:
      return '#2d8825' // plasma-green
    case ConnectionPortType.FAILURE:
      return '#c2292b' // fire-red
    case ConnectionPortType.TRUE:
      return '#366fe5' // ocean-blue
    case ConnectionPortType.FALSE:
      return '#ea6d39' // lava-orange
    case ConnectionPortType.ITERATION:
      return '#366fe5' // ocean-blue
    default:
      return '#757575' // hurricane
  }
}

export const getEdgeIcon = (sourcePort?: OutputPortType | null) => {
  switch (sourcePort) {
    case ConnectionPortType.SUCCESS:
      return (
        <SuccessIcon
          className={classes.SuccessIcon}
          data-testid="connection-icon-success"
        />
      )
    case ConnectionPortType.FAILURE:
      return (
        <FailedIcon
          className={classes.FailedIcon}
          data-testid="connection-icon-fail"
        />
      )
    case ConnectionPortType.TRUE:
      return (
        <TrueIcon
          className={classes.TrueIcon}
          data-testid="connection-icon-true"
        />
      )
    case ConnectionPortType.FALSE:
      return (
        <FalseIcon
          className={classes.FalseIcon}
          data-testid="connection-icon-false"
        />
      )

    default:
      return null
  }
}

export const getEdgeStyle = ({
  source = null,
  selected = false,
  pathLength = 0,
  showEdgeIcon = false
}: {
  source?: string | null
  selected?: boolean
  pathLength?: number
  showEdgeIcon?: boolean
}) => {
  const dashSpacing = 20
  const dashLength = (pathLength - dashSpacing) / 2

  return {
    strokeDasharray: !showEdgeIcon
      ? undefined
      : `${dashLength} ${dashSpacing} ${dashLength}`,
    stroke: getEdgeColor(source),
    strokeWidth: selected ? 3 : 2,
    opacity: selected ? 0.5 : 1
  }
}

const getEdgeMarker = (sourcePort?: string | null) => {
  switch (sourcePort) {
    case ConnectionPortType.SUCCESS:
      return 'url(#marker-success)'
    case ConnectionPortType.FAILURE:
      return 'url(#marker-fail)'
    case ConnectionPortType.TRUE:
      return 'url(#marker-true)'
    case ConnectionPortType.FALSE:
      return 'url(#marker-false)'
    default:
      return 'url(#marker-unconditional)'
  }
}

const handleDirections = {
  [Position.Left]: { x: -1, y: 0 },
  [Position.Right]: { x: 1, y: 0 },
  [Position.Top]: { x: 0, y: -1 },
  [Position.Bottom]: { x: 0, y: 1 }
}

const getFlowEdgePath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  offset = 13,
  shortfall = 2
}: {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition: Position
  targetPosition: Position
  offset?: number
  shortfall?: number
}) => {
  /*
   * on the ETLD canvas, each node has a set distance that a connecting
   * line should go before bending; this is its offset. we need to figure
   * out what direction the offset should be moving in, based on where the
   * source port is on the node
   */
  const sourceDir = handleDirections[sourcePosition]
  const targetDir = handleDirections[targetPosition]

  /*
   * how many pixels away from the target destination the line should fall;
   * this is used to account for svg marker arrowheads needing to stop early
   * to be able to render a sharp point */
  const shortfallX = targetDir.x * shortfall
  const shortfallY = targetDir.y * shortfall

  /*
   * once we know the direction the offset is going, we can find the point
   * the offset finishes at using Maths. this lets us build an svg path
   * with these points later on
   */
  const sourceOffsetX = sourceX + sourceDir.x * offset
  const sourceOffsetY = sourceY + sourceDir.y * offset
  const targetOffsetX = targetX + targetDir.x * offset
  const targetOffsetY = targetY + targetDir.y * offset

  const modifiedTargetX = targetX + shortfallX
  const modifiedTargetY = targetY + shortfallY

  /*
   * where labels should be rendered on this edge;
   * this will be halfway along the offset-to-offset connecting line
   * */
  const labelOffsetX = Math.abs(targetX - sourceX) / 2
  const labelX =
    targetX < sourceX ? targetX + labelOffsetX : targetX - labelOffsetX

  const labelOffsetY = Math.abs(targetY - sourceY) / 2
  const labelY =
    targetY < sourceY ? targetY + labelOffsetY : targetY - labelOffsetY

  /*
   * Using the Distance formula to calculate the length of each segment of the path
   * and then adding them together to get the total length of the path
   * d = √((x2 - x1)² + (y2 - y1)²)
   *
   * To account for the beginning and end connection path lengths overlaying each other
   * when a component is close together. We set the connection path length to 0 if
   * the point to point distance of [sourceX,sourceY] distance is less than MINIMUM_PATH_LENGTH
   * away from the [modifiedTargetX,modifiedTargetY]
   */

  const MINIMUM_PATH_LENGTH = 30

  const sourcePathLength = Math.sqrt(
    Math.pow(sourceOffsetX - sourceX, 2) + Math.pow(sourceOffsetY - sourceY, 2)
  )
  const connectionPathLength = Math.sqrt(
    Math.pow(targetOffsetX - sourceOffsetX, 2) +
      Math.pow(targetOffsetY - sourceOffsetY, 2)
  )

  const targetPathLength = Math.sqrt(
    Math.pow(modifiedTargetX - targetOffsetX, 2) +
      Math.pow(modifiedTargetY - targetOffsetY, 2)
  )

  const sourceToTargetDistance = Math.sqrt(
    Math.pow(sourceX - modifiedTargetX, 2) +
      Math.pow(sourceY - modifiedTargetY, 2)
  )

  const pathLength =
    sourceToTargetDistance < MINIMUM_PATH_LENGTH
      ? 0
      : sourcePathLength + connectionPathLength + targetPathLength

  return [
    /*
     * this builds an SVG path representing the connecting line between nodes. on the ETLD canvas,
     * this line is made of the following points:
     *
     *                   sourceOffset
     *                       x,y
     * [sourceX,sourceY]------
     *                        \
     *                         \
     *                          \
     *                           ------[targetX,targetY]
     *                          x,y
     *                      targetOffset
     */
    `M${sourceX},${sourceY} L${sourceOffsetX},${sourceOffsetY} L${targetOffsetX},${targetOffsetY} L${modifiedTargetX},${modifiedTargetY}`,
    labelX,
    labelY,
    pathLength
  ] as const
}

export const FlowEdge: FunctionComponent<EdgeProps> = memo(
  ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    ...props
  }) => {
    const [path, labelX, labelY, pathLength] = getFlowEdgePath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition
    })

    const showEdgeIcon =
      props.data?.sourceHandle !== ConnectionPortType.UNCONDITIONAL &&
      pathLength !== 0

    return (
      <>
        <BaseEdge
          {...props}
          path={path}
          labelX={labelX}
          labelY={labelY}
          markerEnd={getEdgeMarker(props.data?.sourceHandle)}
          style={getEdgeStyle({
            source: props.data?.sourceHandle,
            selected: props.selected,
            pathLength,
            showEdgeIcon
          })}
        />
        {showEdgeIcon && (
          <foreignObject x={labelX - 10} y={labelY - 10} width={20} height={20}>
            <div
              className={classNames(classes.IconWrapper, {
                [classes['IconWrapper--Selected']]: props.selected
              })}
            >
              {getEdgeIcon(props.data?.sourceHandle)}
            </div>
          </foreignObject>
        )}
      </>
    )
  }
)

FlowEdge.displayName = 'FlowEdge'
