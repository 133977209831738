/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import Editor, {
  loader,
  type OnChange,
  type OnMount
} from '@monaco-editor/react'
import classnames from 'classnames'
import * as monaco from 'monaco-editor'
import { type editor } from 'monaco-editor'

import { type DisplayType } from 'components/CodeEditor/types'

import { useFlags } from 'hooks/useFlags'

import classes from './CodeEditor.module.scss'
import { MonacoAutocomplete } from './MonacoAutocomplete'
import {
  defaultAutocompleteProvider,
  sampleFieldsProvider,
  sampleLongProvider,
  sampleVariablesProvider,
  type MonacoAutocompleteItem,
  type MonacoAutocompleteProvider
} from './MonacoAutocompleteProvider'

loader.config({ monaco })
/**
 * Retrieves the word before the cursor in the Monaco editor.
 * @param editor - The Monaco editor instance.
 * @returns The word before the cursor.
 */
const getWordBeforeCursor = (
  editor: monaco.editor.IStandaloneCodeEditor
): string => {
  const position = editor.getPosition()
  if (!position) return ''

  const model = editor.getModel()
  const textBeforeCursor = model?.getValueInRange({
    startLineNumber: position.lineNumber,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: position.column
  })

  const match = textBeforeCursor?.match(/(\w+)\W*$/)
  return match ? match[1] : ''
}
export const monacoCustomTheme: monaco.editor.IStandaloneThemeData = {
  base: 'hc-light',
  inherit: true,
  rules: [
    { token: 'comment', foreground: '2d8825' },
    { token: 'keyword', foreground: 'A030AA' },
    { token: 'string', foreground: 'CA1C2F' },
    { token: 'string.sql', foreground: 'CA1C2F' },
    { token: 'number', foreground: '2DA3BA' },
    { token: 'type', foreground: 'CA1C2F' },
    { token: 'class', foreground: '222222' },
    { token: 'function', foreground: '222222' },
    { token: 'function.declaration', foreground: '222222' },
    { token: 'variable', foreground: 'CA1C2F' },
    { token: 'property', foreground: 'CA1C2F' },
    { token: 'operator', foreground: '777777' },
    { token: 'operator.sql', foreground: '777777' },
    { token: 'identifier', foreground: '222222' },
    { token: 'identifier.sql', foreground: '222222' },
    { token: 'punctuation', foreground: '222222' }
  ],
  colors: {
    'editor.foreground': '#222222',
    'editor.background': '#ffffff',
    'editorCursor.foreground': '#222222',
    'editor.lineHighlightBorder': '#FFFFFF00',
    'editorLineNumber.foreground': '#777777',
    'editorLineNumber.activeForeground': '#222222',
    'editor.LineNumber.background': 'f8f8f8f8',
    'editor.selectionBackground': '#dfedd3',
    'editor.selectionForeground': '#222222',
    'scrollbarSlider.background': '#f8f8f8',
    'scrollbarSlider.hoverBackground': '#f8f8f8',
    'scrollbarSlider.activeBackground': '#f8f8f8'
  }
}

interface MonacoEditorProps {
  autoFocus?: boolean
  className?: string
  readOnly?: boolean
  onChange?: (newValue: string) => unknown
  value?: string
  language?: string
  displayType: DisplayType
  options?: editor.IStandaloneEditorConstructionOptions
  autocompleteProviders: MonacoAutocompleteProvider[] | undefined
  defaultProvider: MonacoAutocompleteProvider | undefined
  defaultValue?: string
}

export const MonacoEditor = forwardRef<
  monaco.editor.IStandaloneCodeEditor,
  MonacoEditorProps
>(
  (
    {
      autoFocus,
      readOnly,
      value,
      onChange,
      className,
      language,
      displayType,
      options,
      defaultValue,
      autocompleteProviders = [
        sampleVariablesProvider,
        sampleFieldsProvider,
        sampleLongProvider
      ],
      defaultProvider = defaultAutocompleteProvider
    },
    ref
  ) => {
    const [position, setPosition] = useState<{
      top: number
      left: number
    }>({
      top: 0,
      left: 0
    })
    const [suggestions, setSuggestions] = useState<MonacoAutocompleteItem[]>([])
    const [autocompleteLoading, setAutocompleteLoading] =
      useState<boolean>(false)
    const [autocompleteOpen, setAutocompleteOpen] = useState(false)
    const [autocompleteSelectedIndex, setAutocompleteSelectedIndex] =
      useState<number>()

    const [highlight, setHighlight] = useState<string>()
    const { enableMonacoAutocomplete } = useFlags()
    const autocompleteOpenRef = useRef<any>(autocompleteOpen)
    autocompleteOpenRef.current = autocompleteOpen
    const suggestionsRef = useRef<MonacoAutocompleteItem[]>([])
    suggestionsRef.current = suggestions
    const editor = (ref as any)?.current as monaco.editor.IStandaloneCodeEditor

    const closeAutocomplete = useCallback(() => {
      setAutocompleteOpen(false)
      setAutocompleteSelectedIndex(undefined)
      setAutocompleteLoading(false)
      setHighlight(undefined)
    }, [])

    const handleSelectSuggestion = useCallback(
      (suggestion: string) => {
        const newPosition = editor.getPosition()!
        const model = editor.getModel()
        model?.applyEdits([
          {
            range: {
              startLineNumber: newPosition.lineNumber,
              startColumn: newPosition.column - (highlight?.length ?? 0),
              endLineNumber: newPosition.lineNumber,
              endColumn: newPosition.column
            },
            text: suggestion,
            forceMoveMarkers: true
          }
        ])
        setSuggestions([])
      },
      [editor, highlight]
    )

    const onKeydown = useCallback(
      (event: KeyboardEvent) => {
        if (
          event.ctrlKey &&
          event.code === 'Space' &&
          !autocompleteOpenRef.current
        ) {
          const model = editor.getModel()
          const newPosition = editor.getPosition()!
          const cursorCoords = editor.getScrolledVisiblePosition(newPosition)
          if (cursorCoords) {
            setPosition({
              top: cursorCoords.top + cursorCoords.height,
              left: cursorCoords.left
            })
          }
          const wordBeforeCursor = getWordBeforeCursor(editor)

          for (const provider of autocompleteProviders) {
            const textBeforePosition = model?.getValueInRange({
              startLineNumber: newPosition.lineNumber,
              startColumn: newPosition.column - provider.triggerText.length,
              endLineNumber: newPosition.lineNumber,
              endColumn: newPosition.column
            })

            if (textBeforePosition === provider.triggerText) {
              setAutocompleteLoading(true)

              provider
                .retrieveItems(wordBeforeCursor)
                .then(setSuggestions)
                .finally(() => {
                  setAutocompleteLoading(false)
                })
              setAutocompleteOpen(true)

              return
            }
          }

          setAutocompleteOpen(true)
          setAutocompleteLoading(true)
          defaultProvider
            .retrieveItems('')
            .then((newSuggestions) => {
              const filteredSuggestions = newSuggestions.filter((suggestion) =>
                suggestion.name
                  .toLowerCase()
                  .startsWith(wordBeforeCursor.toLowerCase())
              )
              if (filteredSuggestions.length > 0) {
                setHighlight(wordBeforeCursor)
                setSuggestions(filteredSuggestions)
              } else {
                setSuggestions(newSuggestions)
              }
            })
            .finally(() => {
              setAutocompleteLoading(false)
            })
          event.preventDefault()
          event.stopImmediatePropagation()
        }

        if (
          (event.key === 'ArrowDown' || event.key === 'ArrowUp') &&
          autocompleteOpenRef.current &&
          suggestions.length > 0
        ) {
          setAutocompleteSelectedIndex(
            autocompleteSelectedIndex !== undefined
              ? mod(
                  autocompleteSelectedIndex +
                    (event.key === 'ArrowDown' ? 1 : -1),
                  suggestions.length
                )
              : 0
          )
          event.preventDefault()
          event.stopImmediatePropagation()
        }

        if (
          event.key === 'Enter' &&
          autocompleteOpen &&
          suggestions.length > 0 &&
          autocompleteSelectedIndex !== undefined
        ) {
          handleSelectSuggestion(suggestions[autocompleteSelectedIndex].name)
          event.preventDefault()
          event.stopImmediatePropagation()
        }
      },
      [
        autocompleteProviders,
        editor,
        autocompleteOpen,
        autocompleteSelectedIndex,
        handleSelectSuggestion,
        defaultProvider,
        suggestions
      ]
    )

    useEffect(() => {
      if (enableMonacoAutocomplete) {
        document.addEventListener('keydown', onKeydown, { capture: true })
      }

      return () => {
        if (enableMonacoAutocomplete) {
          document.removeEventListener('keydown', onKeydown, { capture: true })
        }
      }
    }, [enableMonacoAutocomplete, onKeydown])

    const handleEditorDidMount: OnMount = (monacoEditor, monacoInstance) => {
      if (typeof ref === 'function') {
        ref(monacoEditor)
      } else if (ref) {
        ref.current = monacoEditor
      }

      monacoInstance.editor.defineTheme('customTheme', monacoCustomTheme)
      monacoInstance.editor.setTheme('customTheme')

      if (autoFocus) {
        monacoEditor.focus()
      }

      if (enableMonacoAutocomplete) {
        monacoEditor.onDidChangeCursorPosition((event) => {
          if (event.reason !== monaco.editor.CursorChangeReason.NotSet) {
            closeAutocomplete()
          }
        })
        monacoEditor.onDidChangeModelContent(() => {
          if (autocompleteOpenRef.current) {
            const wordBeforeCursor = getWordBeforeCursor(monacoEditor)
            const filteredSuggestions = suggestionsRef.current.filter(
              (suggestion) =>
                suggestion.name
                  .toLowerCase()
                  .startsWith(wordBeforeCursor.toLowerCase())
            )

            setSuggestions(filteredSuggestions)
            setHighlight(wordBeforeCursor)
            if (filteredSuggestions.length === 0) {
              closeAutocomplete()
            }

            return
          }

          const newPosition = monacoEditor.getPosition()
          if (!newPosition) return

          for (const provider of autocompleteProviders) {
            const model = monacoEditor.getModel()
            const textBeforePosition = model?.getValueInRange({
              startLineNumber: newPosition.lineNumber,
              startColumn: newPosition.column - provider.triggerText.length,
              endLineNumber: newPosition.lineNumber,
              endColumn: newPosition.column
            })

            if (textBeforePosition === provider.triggerText) {
              const cursorCoords =
                monacoEditor.getScrolledVisiblePosition(newPosition)
              if (cursorCoords) {
                setPosition({
                  top: cursorCoords.top + cursorCoords.height,
                  left: cursorCoords.left
                })
                setAutocompleteLoading(true)
                const wordBeforeCursor = getWordBeforeCursor(monacoEditor)
                provider
                  .retrieveItems(wordBeforeCursor)
                  .then(setSuggestions)
                  .finally(() => {
                    setAutocompleteLoading(false)
                  })
                setAutocompleteOpen(true)
              }
              break
            }
          }
        })
      }
    }

    return (
      <>
        <Editor
          language={language}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange as OnChange}
          onMount={handleEditorDidMount}
          wrapperProps={{
            'data-testid': 'code-editor'
          }}
          className={classnames(
            classes.CodeEditor,
            classes[`CodeEditor--${displayType}`],
            {
              [classes['CodeEditor--Disabled']]: !value && readOnly
            },
            className
          )}
          options={{
            bracketPairColorization: { enabled: true },
            lineDecorationsWidth: 0,
            minimap: { enabled: false },
            wordWrap: 'on',
            readOnly,
            scrollBeyondLastLine: false,
            padding: { top: 10 },
            scrollbar: {
              horizontal: 'auto',
              useShadows: false,
              verticalScrollbarSize: 0,
              verticalSliderSize: 10
            }
          }}
        />
        {autocompleteOpen && (
          <MonacoAutocomplete
            onClose={closeAutocomplete}
            loading={autocompleteLoading}
            position={position}
            suggestions={suggestions}
            open={autocompleteOpen}
            onSelect={handleSelectSuggestion}
            selectedIndex={autocompleteSelectedIndex}
            highlight={highlight}
          />
        )}
      </>
    )
  }
)

MonacoEditor.displayName = 'MonacoEditor'

function mod(n: number, m: number) {
  return ((n % m) + m) % m
}
