import { type Classification, type Port } from 'job-lib/types/Components'
import { type JobType } from 'job-lib/types/JobType'
import { type ParameterSlot } from 'job-lib/types/Parameters'

import { type ComponentSummaryId } from '../useGetComponentSummaries'
import { type EditorColumn } from '../useGetParameterOptions/types'

export interface ComponentMetadataResponse {
  componentId: ComponentSummaryId
  metadata: ComponentMetadata
}

export interface ConnectorComponentMetadataResponse {
  componentId: ComponentSummaryId
  metadata: ComponentMetadata
  /** This is for passing back to the backend  */
  additionalProperties: ComponentAdditionalProperties
}

export interface ComponentMetadata {
  schemaVersion: number
  emeraldId: string
  classification: Classification
  componentType: JobType
  dwhTypes?: string[]
  inputPorts: Port[]
  outputPorts: Port[]
  deprecated: boolean
  supersededBy?: string
  executableAsSingleComponent: boolean
  exports: ExportVariableType[] | null
  parameters: ComponentParameter[]
  editorTabs: ComponentEditorTabType[]
  maxSampleSize?: number
  validationDependencies?: string[]
}

export interface ComponentAdditionalProperties {
  connectorProfile: unknown
}

export enum ExportVariableType {
  COMPLETED_AT = 'COMPLETED_AT',
  COMPONENT_NAME = 'COMPONENT_NAME',
  DURATION = 'DURATION',
  FILENAME = 'FILENAME',
  ITERATIONS_ATTEMPTED = 'ITERATIONS_ATTEMPTED',
  ITERATIONS_GENERATED = 'ITERATIONS_GENERATED',
  ITERATIONS_SUCCESSFUL = 'ITERATIONS_SUCCESSFUL',
  MESSAGE = 'MESSAGE',
  ROW_COUNT = 'ROW_COUNT',
  STARTED_AT = 'STARTED_AT',
  STATUS = 'STATUS',
  TABLE_RECREATED = 'TABLE_RECREATED',
  TIME_TAKEN_TO_LOAD = 'TIME_TAKEN_TO_LOAD',
  TIME_TAKEN_TO_STAGE = 'TIME_TAKEN_TO_STAGE'
}

export enum VisibleWhenOperator {
  EQUALS = 'EQUALS',
  NEVER = 'NEVER',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_EQUALS = 'NOT_EQUALS',
  HAS_VALUE = 'HAS_VALUE',
  MATCHES_REGEX_PATTERN = 'MATCHES_REGEX_PATTERN',
  DYNAMIC_VISIBILITY_LOOKUP = 'DYNAMIC_VISIBILITY_LOOKUP'
}

interface VisibleIn {
  param: string
  value: string[]
  operator: VisibleWhenOperator.IN | VisibleWhenOperator.NOT_IN
}

interface VisibleEquals {
  param: string
  value: string
  operator: VisibleWhenOperator.EQUALS | VisibleWhenOperator.NOT_EQUALS
}

interface VisibleNever {
  param: null
  value: null
  operator: VisibleWhenOperator.NEVER
}

interface VisibleHasValue {
  param: string
  value: null
  operator: VisibleWhenOperator.HAS_VALUE
}

interface VisibleMatchesRegexPattern {
  param: string
  value: string
  operator: VisibleWhenOperator.MATCHES_REGEX_PATTERN
}

interface VisibleWhenDynamicLookup {
  operator: VisibleWhenOperator.DYNAMIC_VISIBILITY_LOOKUP
  param: null
  value: null
  lookup?: {
    lookupName: string
    dependencies: string[]
  }
}

export type VisibleWhen =
  | VisibleIn
  | VisibleEquals
  | VisibleNever
  | VisibleHasValue
  | VisibleMatchesRegexPattern
  | VisibleWhenDynamicLookup

export type ComponentMetadataParameterId = string

interface Warehouses {
  metlSlot?: number
}

export interface ComponentParameter {
  authProvider?: string | null
  continuationToken?: string | null
  childProperties?: ComponentParameter[] | null
  dataType: ParameterDataType
  defaultValue?: string | null
  displayName?: string | null
  dplID: ComponentMetadataParameterId
  editorType: EditorType
  lookupDependencies?: string[] | null
  lookupType?: LookUpType | null
  metlSlot: ParameterSlot
  optional: boolean
  options?: string[] | null
  resourceID?: string | null
  secretTypes?: string[] | null
  staticOptions?: EditorColumn[] | null
  useVariable?: UseVariable
  validationDependencies?: string[] | null
  visibleWhen?: VisibleWhen[] | null
  warehouses?: Record<string, Warehouses> | null
  autoFill?: {
    lookupDependencies?: string[] | null
    lookupType?: LookUpType | null
  }
}

export enum UseVariable {
  YES = 'YES',
  NO = 'NO'
}

export enum EditorType {
  BLOB_URL = 'BLOB_URL',
  BLOB_URL_FOLDER_ONLY = 'BLOB_URL_FOLDER_ONLY',
  FREETEXT = 'FREETEXT',
  FREETEXT_MULTI_LINE = 'FREETEXT_MULTI_LINE',
  DROPDOWN = 'DROPDOWN',
  TYPEAHEAD = 'TYPEAHEAD',
  PICKER = 'PICKER',
  MULTI_OPTION_SELECTOR = 'MULTI_OPTION_SELECTOR',
  MULTI_EXPRESSIONS_EDITOR = 'MULTI_EXPRESSIONS_EDITOR',
  MULTI_PROPERTIES_EDITOR = 'MULTI_PROPERTIES_EDITOR',
  S3_URL = 'S3_URL',
  GRID_EDITOR = 'GRID_EDITOR',
  JOIN_EDITOR = 'JOIN_EDITOR',
  BASH = 'BASH',
  PYTHON = 'PYTHON',
  SECRET_REFERENCE = 'SECRET_REFERENCE',
  TEST_TEXTAREA = 'TEST_TEXTAREA',
  SQL_EDITOR = 'SQL_EDITOR',
  SQL_EDITOR_ADVANCED = 'SQL_EDITOR_ADVANCED',
  NESTED_DATA_PICKER_SF = 'NESTED_DATA_PICKER_SF',
  SCALAR_JOB_VARIABLE_OVERRIDE = 'SCALAR_JOB_VARIABLE_OVERRIDE',
  GRID_JOB_VARIABLE_OVERRIDE = 'GRID_JOB_VARIABLE_OVERRIDE',
  GRID_JOB_VARIABLE_POPULATE = 'GRID_JOB_VARIABLE_POPULATE',
  OAUTH = 'OAUTH',
  PARAMETER_GROUP = 'PARAMETER_GROUP',
  CONNECTION_EDITOR = 'CONNECTION_EDITOR',
  OPTIMISE_EDITOR = 'OPTIMISE_EDITOR',
  DYNAMIC_URL_EDITOR = 'DYNAMIC_URL_EDITOR',
  GCS_URL = 'GCS_URL',
  GCS_URL_FOLDER_ONLY = 'GCS_URL_FOLDER_ONLY',
  NESTED_DATA_PICKER = 'NESTED_DATA_PICKER',
  SEMI_STRUCTURED_NESTED_DATA_PICKER = 'SEMI_STRUCTURED_NESTED_DATA_PICKER',
  MULTI_TABLE_CONFIGURATION_EDITOR = 'MULTI_TABLE_CONFIGURATION_EDITOR',
  TABLE_SELECTION = 'TABLE_SELECTION',
  SHEET_SELECTION = 'SHEET_SELECTION',
  COLUMN_CONFIGURATION = 'COLUMN_CONFIGURATION',
  SPREADSHEET_CONFIG = 'SPREADSHEET_CONFIG',
  APPLY_TO_ALL = 'APPLY_TO_ALL',
  SHARED_PIPELINE_SELECTOR = 'SHARED_PIPELINE_SELECTOR'
}

export enum LookUpType {
  DYNAMIC_DWH = 'DYNAMIC_DWH',
  DYNAMIC_DWH_ROLE = 'DYNAMIC_DWH_ROLE',
  DYNAMIC_DWH_WAREHOUSES = 'DYNAMIC_DWH_WAREHOUSES',
  DYNAMIC_DWH_DATABASE = 'DYNAMIC_DWH_DATABASE',
  DYNAMIC_DWH_DATABASES = 'DYNAMIC_DWH_DATABASES',
  DYNAMIC_DWH_SCHEMAS = 'DYNAMIC_DWH_SCHEMAS',
  DYNAMIC_DWH_TABLES = 'DYNAMIC_DWH_TABLES',
  DYNAMIC_DWH_COLUMNS = 'DYNAMIC_DWH_COLUMNS',
  DYNAMIC_DWH_COLUMNS_WITH_ORDER_BY = 'DYNAMIC_DWH_COLUMNS_WITH_ORDER_BY',
  DYNAMIC_MULTI_TABLE_INPUT_COLUMNS = 'DYNAMIC_MULTI_TABLE_INPUT_COLUMNS',
  DESIGNER_ORCHESTRATION_JOBS = 'DESIGNER_ORCHESTRATION_JOBS',
  DESIGNER_ORCHESTRATION_JOB_VARIABLES = 'DESIGNER_ORCHESTRATION_JOB_VARIABLES',
  DESIGNER_SECRET_TYPE = 'DESIGNER_SECRET_TYPE',
  DYNAMIC_BLOB_STORAGE_ACCOUNTS = 'DYNAMIC_BLOB_STORAGE_ACCOUNTS',
  DYNAMIC_BLOB_STORAGE_CONTAINERS = 'DYNAMIC_BLOB_STORAGE_CONTAINERS',
  DYNAMIC_BLOB_STORAGE_CONTAINERS_AND_BLOBS = 'DYNAMIC_BLOB_STORAGE_CONTAINERS_AND_BLOBS',
  DYNAMIC_S3_BUCKETS = 'DYNAMIC_S3_BUCKETS',
  DYNAMIC_S3_FOLDERS = 'DYNAMIC_S3_FOLDERS',
  DYNAMIC_S3_FOLDERS_AND_OBJECTS = 'DYNAMIC_S3_FOLDERS_AND_OBJECTS',
  DYNAMIC_GCS_BUCKETS = 'DYNAMIC_GCS_BUCKETS',
  DYNAMIC_GCS_FOLDERS = 'DYNAMIC_GCS_FOLDERS',
  DYNAMIC_GCS_FOLDERS_AND_OBJECTS = 'DYNAMIC_GCS_FOLDERS_AND_OBJECTS',
  DYNAMIC_SOURCE_COMPONENT_NAMES = 'DYNAMIC_SOURCE_COMPONENT_NAMES',
  DYNAMIC_TRANSFORM_SQL_COLUMNS = 'DYNAMIC_TRANSFORM_SQL_COLUMNS',
  DYNAMIC_TRANSFORM_FILTER_CONDITIONS = 'DYNAMIC_TRANSFORM_FILTER_CONDITIONS',
  DYNAMIC_TRANSFORM_FIXED_FLOW_VALUES = 'DYNAMIC_TRANSFORM_FIXED_FLOW_VALUES',
  DYNAMIC_TRANSFORM_JOIN_ALIASES = 'DYNAMIC_TRANSFORM_JOIN_ALIASES',
  DYNAMIC_TRANSFORM_JOIN_EXPRESSIONS = 'DYNAMIC_TRANSFORM_JOIN_EXPRESSIONS',
  DYNAMIC_TRANSFORM_JOIN_OUTPUT_COLUMNS = 'DYNAMIC_TRANSFORM_JOIN_OUTPUT_COLUMNS',
  DYNAMIC_TRANSFORM_COLUMN_FLATTENS = 'DYNAMIC_TRANSFORM_COLUMN_FLATTENS',
  DYNAMIC_TRANSFORM_FLATTEN_VARIANT_COLUMN_MAPPING = 'DYNAMIC_TRANSFORM_FLATTEN_VARIANT_COLUMN_MAPPING',
  DYNAMIC_TRANSFORM_SOURCE_COLUMNS = 'DYNAMIC_TRANSFORM_SOURCE_COLUMNS',
  DYNAMIC_TRANSFORM_SOURCE_COLUMNS_WITH_ORDER_BY = 'DYNAMIC_TRANSFORM_SOURCE_COLUMNS_WITH_ORDER_BY',
  DYNAMIC_TRANSFORM_ROW_COLUMN_MAPPINGS = 'DYNAMIC_TRANSFORM_ROW_COLUMN_MAPPINGS',
  DYNAMIC_TRANSFORM_WHEN_MATCHED_EXPRESSIONS = 'DYNAMIC_TRANSFORM_WHEN_MATCHED_EXPRESSIONS',
  DYNAMIC_EMERALD_OPTIONS = 'DYNAMIC_EMERALD_OPTIONS',
  DESIGNER_TRANSFORM_JOBS = 'DESIGNER_TRANSFORM_JOBS',
  DESIGNER_API_KEY = 'DESIGNER_API_KEY',
  DESIGNER_TRANSFORMATION_JOB_VARIABLES = 'DESIGNER_TRANSFORMATION_JOB_VARIABLES',
  DESIGNER_TRANSFORMATION_CHILD_GRID_VARIABLES = 'DESIGNER_TRANSFORMATION_CHILD_GRID_VARIABLES',
  DESIGNER_PASSWORD = 'DESIGNER_PASSWORD',
  DESIGNER_OAUTH = 'DESIGNER_OAUTH',
  DYNAMIC_QUERY_COLUMNS = 'DYNAMIC_QUERY_COLUMNS',
  CIS_VARIABLES = 'CIS_VARIABLES',
  OAUTH = 'OAUTH',
  DYNAMIC_MODULAR_OUTPUT_STRUCT_KEYS = 'DYNAMIC_MODULAR_OUTPUT_STRUCT_KEYS',
  DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP = 'DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP',
  DYNAMIC_MODULAR_INPUT_MULTITABLE_SCALAR_LOOKUP = 'DYNAMIC_MODULAR_INPUT_MULTITABLE_SCALAR_LOOKUP',
  DYNAMIC_MODULAR_INPUT_MULTITABLE_STRUCT_KEYS = 'DYNAMIC_MODULAR_INPUT_MULTITABLE_STRUCT_KEYS',
  DYNAMIC_MODULAR_INPUT_MULTITABLE_STRUCT_VALUES = 'DYNAMIC_MODULAR_INPUT_MULTITABLE_STRUCT_VALUES',
  DYNAMIC_MODULAR_INPUT_STRUCT_VALUES = 'DYNAMIC_MODULAR_INPUT_STRUCT_VALUES',
  DYNAMIC_MODULAR_INPUT_STRUCT_KEYS = 'DYNAMIC_MODULAR_INPUT_STRUCT_KEYS',
  DYNAMIC_MODULAR_INPUT_SCALAR_LOOKUP = 'DYNAMIC_MODULAR_INPUT_SCALAR_LOOKUP',
  DYNAMIC_MODULAR_OUTPUT_TYPES = 'DYNAMIC_MODULAR_OUTPUT_TYPES',
  DYNAMIC_MODULAR_OUTPUT_SCALAR_CONNECTION_DEFAULT_LOOKUP = 'DYNAMIC_MODULAR_OUTPUT_SCALAR_CONNECTION_DEFAULT_LOOKUP',
  DYNAMIC_MODULAR_OUTPUT_SCALAR_WITH_INPUT_DEPENDENCY_LOOKUP = 'DYNAMIC_MODULAR_OUTPUT_SCALAR_WITH_INPUT_DEPENDENCY_LOOKUP',
  DYNAMIC_JAVASCRIPT_EXECUTION = 'DYNAMIC_JAVASCRIPT_EXECUTION',
  DYNAMIC_VARIABLES_GRID = 'DYNAMIC_VARIABLES_GRID',
  DYNAMIC_VARIABLE_GRID_COLUMNS = 'DYNAMIC_VARIABLE_GRID_COLUMNS',
  DYNAMIC_SINGLE_SEMI_STRUCTURED_NESTED_DATA = 'DYNAMIC_SINGLE_SEMI_STRUCTURED_NESTED_DATA',
  DYNAMIC_TRANSFORM_SOURCE_TARGET_JOIN_EXPRESSIONS = 'DYNAMIC_TRANSFORM_SOURCE_TARGET_JOIN_EXPRESSIONS',
  XLSX = 'XLSX'
}

export enum ComponentEditorTabType {
  EXPORT = 'EXPORT',
  GRID_EXPORT = 'GRID_EXPORT',
  HELP = 'HELP',
  METADATA = 'METADATA',
  PLAN = 'PLAN',
  PROPERTIES = 'PROPERTIES',
  SAMPLE = 'SAMPLE',
  SQL = 'SQL',
  POSTPROCESSING = 'POSTPROCESSING'
}

// https://bitbucket.org/matillion/saas-component-information/src/8dde20cd036ad8ca18b2cfd407a8f88188fb0998/src/main/java/com/matillion/cloud/metadata/rest/api/v1/model/ComponentParameterDataType.java?at=main#lines-3
export enum ParameterDataType {
  TEXT = 'TEXT',
  TEXT_SQL = 'TEXT_SQL',
  TEXT_BASH = 'TEXT_BASH',
  TEXT_PYTHON = 'TEXT_PYTHON',
  TEXT_SECRET_REF = 'TEXT_SECRET_REF',
  TEXT_CC_PROFILE = 'TEXT_CC_PROFILE',
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  NUMERIC = 'NUMERIC',
  DATE = 'DATE',
  TIME = 'TIME',
  TIMESTAMP = 'TIMESTAMP',
  GRID = 'GRID',
  LIST = 'LIST',
  STRUCT = 'STRUCT',
  STRUCT_LIST = 'STRUCT_LIST'
}
