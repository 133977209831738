import { useQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

import useEosClient from 'api/hooks/useEosClient/useEosClient'

import {
  getStepMessages,
  type GetStepMessagesResponse
} from './__handlers__/getStepMessages'

export const useGetStepMessages = (stepId: string) => {
  const client = useEosClient()
  return useQuery<GetStepMessagesResponse, AxiosError>(
    ['stepMessages', stepId],
    async () => getStepMessages(client, stepId)
  )
}
