const translateIDForDocsMap: { [key: string]: string } = {
  'assert-view-rs': 'assert-view',
  'assert-view-sf': 'assert-view',
  'azure-blob-storage-load-v2': 'azure-blob-storage-load',
  'convert-type-rs': 'convert-type',
  'create-external-table-sf': 'create-external-table',
  'create-external-table-rs': 'create-external-table',
  'data-transfer-object': 'data-transfer',
  'delete-tables-v2': 'delete-tables',
  'extract-nested-data-sf': 'extract-nested-data',
  'extract-nested-data-rs': 'extract-nested-data',
  'extract-nested-data-dl': 'extract-structured-data',
  'microsoft-sql-server-output': 'sql-server-output',
  'query-to-grid': 'query-result-to-grid',
  'query-to-scalar': 'query-result-to-scalar',
  'refresh-table-v2': 'refresh-table',
  'rewrite-table-rs': 'rewrite-table',
  's3-unload-rs': 's3-unload',
  's3-load-v2': 's3-load',
  's3-load-rs': 's3-load',
  'native-create-table': 'create-table',
  'create-table-v2': 'create-table',
  'vacuum-tables': 'vacuum'
}

export const translateIDForDocs = (componentSummaryId: string) => {
  // Translations weren't used here because the componentSummaryId is used in the URL for the component docs, we would need to check with the docs team if they are planning on internationizing slugs for the docs
  return translateIDForDocsMap[componentSummaryId] || componentSummaryId
}
