import { isOrchestrationJob } from 'job-lib/job-functions/job-functions'
import { type RootState } from 'job-lib/store'
import type { ConnectorCollection } from 'job-lib/types/Job'

export interface ConnectorsCollection {
  unconditionalConnectors: ConnectorCollection | null
  successConnectors: ConnectorCollection | null
  failureConnectors: ConnectorCollection | null
  trueConnectors: ConnectorCollection | null
  falseConnectors: ConnectorCollection | null
  iterationConnectors: ConnectorCollection | null
}

export const getConnectorsSelector = (
  state: RootState
): ConnectorsCollection | null => {
  if (!state.job.job?.components || !isOrchestrationJob(state.job.job)) {
    return null
  }

  return {
    unconditionalConnectors: state.job.job.unconditionalConnectors,
    successConnectors: state.job.job.successConnectors,
    failureConnectors: state.job.job.failureConnectors,
    trueConnectors: state.job.job.trueConnectors,
    falseConnectors: state.job.job.falseConnectors,
    iterationConnectors: state.job.job.iterationConnectors
  }
}
