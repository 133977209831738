import { useContext } from 'react'

import { LegacyWorkingCopyContext } from '../context'

/**
 * Returns the legacy working copy context, which is a METL representation
 * of the user's current pipeline. This is generated from the canonical DPL
 * representation of the pipeline, and only intended as a temporary measure
 * until the Designer can speak DPL natively.
 *
 * @deprecated Please use `useWorkingCopy` and a DPL native implementation where possible
 */
export const useLegacyWorkingCopy = () => useContext(LegacyWorkingCopyContext)
