import React, {
  useRef,
  type FunctionComponent,
  type MouseEvent,
  type PropsWithChildren,
  type ReactElement
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  Tooltip,
  TreeItem,
  Typography
} from '@matillion/component-library'

import { ReactComponent as Folder } from 'assets/Folder.svg'

import { PopOverMenu } from 'components/PopOverMenu'

import { type SimpleRefetchRef } from '../../../../types'
import classes from '../../SchemaRow.module.scss'
import { SchemaRowContextMenu } from '../SchemaRowContextMenu/SchemaRowContextMenu'

interface Props {
  id: string
  schemaName: string
  labelKey: string
  children: ReactElement
}

const SchemaFolder: FunctionComponent<PropsWithChildren<Props>> = ({
  id,
  schemaName,
  labelKey,
  children
}) => {
  const { t } = useTranslation()
  const menuRef = useRef<SimpleRefetchRef>(null)

  return (
    <TreeItem
      nodeId={`schema-${schemaName}-${id}s`}
      label={id}
      leaf={
        <PopOverMenu
          content={
            // istanbul ignore next
            <SchemaRowContextMenu refetch={() => menuRef?.current?.refetch()} />
          }
        >
          {({ onClick }) => (
            <div
              className={classes.Folder}
              data-testid={`tree-schema-${id}-${schemaName}`}
            >
              <Folder className={classes.Folder__Icon} aria-hidden />
              <Tooltip content={schemaName} onlyShowOnOverflow>
                <div className={classes.SchemaTooltip}>
                  <Typography
                    format="bcs"
                    className={classes.Folder__Text}
                    as="span"
                  >
                    {t(labelKey)}
                  </Typography>
                </div>
              </Tooltip>
              <Button
                size="sm"
                alt="ghost"
                onClick={(e: MouseEvent) => {
                  e.stopPropagation()
                  onClick()
                }}
                aria-label={t('translation:sideBar.jobPanel.optionMenu')}
                data-testid={`tree-schema-${id}-${schemaName}-actions`}
                className={classes.ContextButton}
              >
                <Icon.Ellipsis className={classes.ButtonIcon} />
              </Button>
            </div>
          )}
        </PopOverMenu>
      }
    >
      {React.cloneElement(children, { ref: menuRef, ...children.props })}
    </TreeItem>
  )
}

export { SchemaFolder }
