import {
  useCallback,
  useMemo,
  useState,
  type ChangeEvent,
  type FC,
  type FormEvent
} from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  Field,
  Input,
  Toaster,
  Typography
} from '@matillion/component-library'
import { getFileTypeTranslationKeySuffix } from '@matillion/git-component-library'
import classnames from 'classnames'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useRenameFile } from 'hooks/useRenameFile'

import {
  isFileExistingFile,
  VALID_NAME_REGEX
} from '../../utils/validateFileName'
import classes from '../Modal.module.scss'

export interface RenamePipelineModalProps {
  jobSummary: JobSummary
  folderPath?: string | null
  onClose: () => void
}

export const RenamePipelineModal: FC<RenamePipelineModalProps> = ({
  jobSummary,
  folderPath,
  onClose
}) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()

  const { isLoading: isRenamingJob, renameFile } = useRenameFile()
  const { files, isLoading: isLoadingPipelineSummaries } = usePipelines()

  const currentFileSummary = useMemo(
    () => files[jobSummary.jobId],
    [jobSummary, files]
  )

  const [jobName, setJobName] = useState(jobSummary.name)
  const [error, setError] = useState<string | null>(null)

  const translationKeySuffix = jobSummary?.fileType
    ? getFileTypeTranslationKeySuffix(jobSummary?.fileType)
    : 'file'
  const isLoading = isRenamingJob || isLoadingPipelineSummaries
  const isValueSameValue = jobName === jobSummary.name
  const isUpdateButtonDisabled = isValueSameValue || isLoading || error !== null

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      setJobName(event.target.value)

      if (!value) {
        setError(t('translation:managePipeline.error.noEmptyString'))
        return
      }

      if (VALID_NAME_REGEX.exec(value) === null) {
        setError(t('translation:managePipeline.error.noRegex'))
        return
      }

      const path = folderPath ? `${folderPath}/` : ''
      const isDuplicatePipeline = isFileExistingFile(
        `${path}${value.trim()}`,
        currentFileSummary.type,
        files
      )

      if (isDuplicatePipeline) {
        setError(
          t(
            `translation:managePipeline.error.noDuplicates.${translationKeySuffix}`
          )
        )
        return
      }

      setError(null)
    },
    [currentFileSummary?.type, folderPath, files, t, translationKeySuffix]
  )

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      try {
        const path = folderPath ? `${folderPath}/` : ''

        await renameFile({
          currentFilePath: jobSummary.jobId,
          newFilePath: `${path}${jobName.trim()}${jobSummary.extension}`
        })

        makeToast({
          title: t(
            `translation:renamePipeline.success.${translationKeySuffix}`
          ),
          type: 'success',
          message: ''
        })

        // TODO: the following code conflicts with invalidation of stale jobs
        // used by the PipelineBrowser. We require this ticket to be resolved first:
        // https://matillion.atlassian.net/browse/DPCD-1078
        // const selectedJobTabs = selectedJobs.map((job) => {
        //   if (job === jobSummary.jobId) {
        //     return updatedJobName
        //   }
        //   return job
        // })
        // if (jobSummaryId === jobSummary.jobId) {
        //   navigateToJob(updatedJobName, selectedJobTabs)
        // } else {
        //   navigateToJob(jobSummaryId, selectedJobTabs)
        // }

        onClose()
      } catch (e) {
        makeToast({
          title: t('translation:managePipeline.error.somethingWrong'),
          type: 'error',
          message: t('translation:managePipeline.error.tryLater')
        })
      }
    },
    [
      folderPath,
      jobName,
      jobSummary.extension,
      jobSummary.jobId,
      makeToast,
      onClose,
      renameFile,
      t,
      translationKeySuffix
    ]
  )

  return (
    <DesignerModal
      id="rename-pipeline-dialog"
      onCancel={onClose}
      ariaLabelledBy="rename-pipeline-title"
      size="mid"
      disableBackdropClick
      className={classes.Modal}
      setFocus={false}
    >
      <div className={classes.Modal__Heading}>
        <Typography
          as="h2"
          format="tl"
          id="rename-pipeline-title"
          data-testid="rename-file-modal-title"
        >
          {t(`translation:renamePipeline.title.${translationKeySuffix}`)}
        </Typography>
      </div>

      <Alert
        className={classes.Modal__AlertInformation}
        data-testid="rename-file-modal-alert"
        theme="dark"
        type="warning"
        title=""
        message={
          <>
            <Typography format="bcs">
              <Trans
                i18nKey={`renamePipeline.alert.${translationKeySuffix}.line1`}
              >
                <strong />
              </Trans>
            </Typography>

            <Typography format="bcs">
              <Trans
                i18nKey={`renamePipeline.alert.${translationKeySuffix}.line2`}
              >
                <strong />
              </Trans>
            </Typography>
          </>
        }
      />

      <form
        onSubmit={(event) => {
          void onSubmit(event)
        }}
      >
        <div
          className={classnames(
            classes.Modal__Content,
            classes['Modal__Content--Input']
          )}
        >
          <Field
            data-testid="rename-file-modal-name-input"
            title={t(`translation:renamePipeline.name.${translationKeySuffix}`)}
            aria-label={t('translation:renamePipeline.name')}
            value={jobName}
            onChange={onChange}
            placeholder={t('translation:renamePipeline.namePlaceholder')}
            hasError={!!error}
            error={!!error}
            errorText={error}
            disabled={isLoading}
            inputComponent={Input}
          />
        </div>

        <div className={classes.Modal__Actions}>
          <Button
            id="rename-pipeline-cancel"
            data-testid="modal-rename-pipeline-cancel"
            text={t('translation:managePipeline.cancel')}
            alt="secondary"
            onClick={onClose}
          />
          <Button
            id="rename-pipeline-create"
            data-testid="modal-rename-pipeline-submit"
            text={t('translation:renamePipeline.update')}
            waiting={isLoading}
            disabled={isUpdateButtonDisabled}
            type="submit"
            alt="primary"
          />
        </div>
      </form>
    </DesignerModal>
  )
}
