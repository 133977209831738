import { type AxiosInstance } from 'axios'

import { type HalLink, type Step } from 'api/hooks/useEosClient/types/eos'

export interface StepsResponse {
  results: Step[]
  limit: number
  offset: number
  total: number
  _links: {
    next?: HalLink
  }
}

export const getSteps = async (
  client: AxiosInstance,
  pipelineId: string,
  offset?: number,
  limit?: number
): Promise<StepsResponse> => {
  const { data } = await client.get<StepsResponse>(
    `/v1/activity/pipeline/${pipelineId}/steps`,
    {
      params: {
        offset,
        limit
      }
    }
  )
  return data
}
