import { createContext } from 'react'

import {
  type MetlWorkingCopyContextBag,
  type WorkingCopyContextBag
} from './types'

export const WorkingCopyContext =
  createContext<WorkingCopyContextBag>(undefined)

export const LegacyWorkingCopyContext =
  createContext<MetlWorkingCopyContextBag>({
    workingCopy: null,
    isLoading: true,
    isError: false
  })
