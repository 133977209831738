import { createContext } from 'react'

import type { ComponentWithMetadata } from '../../../api/hooks/copilot/model'
import type { FeedbackSentiment } from '../../../api/hooks/copilot/types'
import { type CopilotChatMessage, type CopilotContextBag } from './types'

export const CopilotContext = createContext<CopilotContextBag>({
  chatMessages: [],
  addInfoMessage: (message: CopilotChatMessage) => null,
  updatePipeline: async (
    query: CopilotChatMessage,
    selectedComponents?: ComponentWithMetadata[]
  ) => Promise.resolve(),
  submitFeedback: async (
    message: CopilotChatMessage,
    sentiment: FeedbackSentiment,
    content: string
  ) => Promise.resolve(),
  isLoadingUpdatePipeline: false,
  isLoadingSubmitFeedback: false
})
