import { LoadingSpinner } from '@matillion/component-library'
import classNames from 'classnames'
import { t } from 'i18next'
import { v4 as uuid } from 'uuid'

import { ReactComponent as SendPrompt } from 'assets/send-prompt.svg'

import { IconButton } from '../../../../components/IconButton/IconButton'
import { type CopilotChatMessage } from '../../../core/Copilot/types'
import classes from './CopilotPromptInput.module.scss'

interface CopilotPromptInputProps {
  prompt: string
  setPrompt: (value: string) => void
  onComplete: (message: CopilotChatMessage) => Promise<void>
  isLoading: boolean
}

const CopilotPromptInput = ({
  prompt,
  setPrompt,
  onComplete,
  isLoading
}: CopilotPromptInputProps) => {
  const isPromptEmpty = prompt === ''
  const submitDisabled = isLoading || isPromptEmpty
  const handleSubmit = () => {
    onComplete({ id: uuid(), content: prompt, type: 'user' })
  }

  return (
    <div className={classes.CopilotPrompt__InputContainer}>
      <textarea
        data-testid="copilot-textarea"
        autoFocus
        value={prompt}
        onChange={(e) => {
          setPrompt(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            if (!isPromptEmpty) {
              handleSubmit()
            }
          }
        }}
        className={classes.CopilotPrompt__InputTextArea}
        placeholder={t('copilot.chat.placeholder')}
        disabled={isLoading}
      />
      <div className={classes.CopilotPrompt__SendPromptContainer}>
        {isLoading ? (
          <LoadingSpinner
            className={classes.CopilotPrompt__Loading}
            data-testid="loading-spinner"
          />
        ) : (
          <IconButton
            label={t('copilot.chat.submitLabel')}
            onClick={handleSubmit}
            disabled={submitDisabled}
            className={classNames(
              classes.CopilotPrompt__SendPromptButton,
              !isPromptEmpty && classes.CopilotPrompt__SendPromptActivated
            )}
            data-testid="send-prompt-button"
          >
            <SendPrompt />
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default CopilotPromptInput
