import { useCallback } from 'react'

import { type StructList, type StructValue } from 'types/Pipeline'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type Failure } from 'api/hooks/useValidateComponent/types'

import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import {
  type ComponentInstanceId,
  type OrchestrationJob,
  type Parameters,
  type TransformationJob
} from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { convertParameterValueToMetl } from 'modules/core/WorkingCopyProvider/effects/useMetlPipeline/convertParametersToMetl'
import { isStructListParameterValue } from 'modules/core/WorkingCopyProvider/utils/parameters'

import { isMetlParameterVisible } from 'utils/isParameterVisible'

import { isChildStructListParameter } from '../../utils/isChildStructListParameter'
import { useClientChildStructParameterValidation } from './useClientChildStructParameterValidation'
import { useClientParameterValidation } from './useClientParameterValidation'

interface UseClientSideValidationProps {
  job: OrchestrationJob | TransformationJob | null
}
export interface GetInvalidParametersProps {
  componentSummaryId: ComponentSummaryId
  componentInstanceId: ComponentInstanceId
  componentMetadata: ComponentMetadata
}

export type GetInvalidParameters = (
  opts: GetInvalidParametersProps
) => Failure[]

export const useClientSideValidation = ({
  job
}: UseClientSideValidationProps) => {
  const validateParameter = useClientParameterValidation()
  const validateChildStructParameter = useClientChildStructParameterValidation()

  const getInvalidParameters = useCallback<GetInvalidParameters>(
    ({
      componentSummaryId,
      componentInstanceId,
      componentMetadata
    }): Failure[] => {
      const invalidParameters: Failure[] = []

      function processChildStructListEntry(
        childProperties: ComponentParameter[],
        path: string[],
        structValue: StructValue,
        iteratorIndex: number
      ) {
        childProperties.forEach((childProperty) => {
          const error = validateChildStructParameter({
            param: childProperty,
            structValue
          })

          if (error) {
            invalidParameters.push({
              message: error,
              path: [
                'parameters',
                ...path,
                `[${iteratorIndex}]`,
                childProperty.dplID
              ],
              isClientSideFailure: true
            })
          }
        })
      }

      function processChildStructList(
        param: ComponentParameter,
        structList: StructList,
        path: string[],
        parameters: Parameters
      ) {
        if (!param.childProperties) return

        const visibleChildProperties = param.childProperties.filter(
          (childProperty) =>
            isMetlParameterVisible(
              getCurrentPath(true, childProperty),
              parameters,
              componentMetadata,
              path
            )
        )

        structList.forEach((structValue, iteratorIndex) => {
          processChildStructListEntry(
            visibleChildProperties,
            path,
            structValue,
            iteratorIndex
          )
        })
      }

      function processParameter(
        param: ComponentParameter,
        path: string[] = []
      ) {
        if (!job) return

        const componentParameters =
          job.components[componentInstanceId]?.parameters
        const isDPLParameters = isDPLParameterCollection(componentParameters)

        const jobParam = getJobParam(
          isDPLParameters,
          componentParameters,
          param
        )

        const currentPath = getCurrentPath(isDPLParameters, param)
        const fullPath = [...path, currentPath.toString()]

        const parameters = job?.components[componentInstanceId]?.parameters

        if (
          !jobParam ||
          !isMetlParameterVisible(
            currentPath,
            parameters,
            componentMetadata,
            path
          )
        ) {
          return
        }

        if (isDPLParameters && isChildStructListParameter(param)) {
          const parameterValue = getParameterValue(
            componentParameters,
            fullPath
          )
          if (isStructListParameterValue(parameterValue)) {
            processChildStructList(param, parameterValue, fullPath, parameters)
          }
          return
        }

        const parameterElements = isDPLParameters
          ? convertParameterValueToMetl(
              param,
              getParameterValue(componentParameters, fullPath)
            )
          : jobParam.elements

        const value = getFirstValue(parameterElements)

        const error = validateParameter({
          parameterId: param.dplID,
          componentSummaryId,
          value
        })

        const errorPath = isDPLParameters
          ? ['parameters', ...fullPath]
          : ['parameters', param.dplID]
        if (error && param.dataType !== 'STRUCT') {
          invalidParameters.push({
            message: error,
            path: errorPath,
            isClientSideFailure: true
          })
        }

        if (param.childProperties) {
          param.childProperties.forEach((childParam) => {
            processParameter(childParam, fullPath)
          })
        }
      }

      componentMetadata.parameters.forEach((param) => {
        processParameter(param)
      })

      return invalidParameters
    },
    [job, validateChildStructParameter, validateParameter]
  )

  return {
    getInvalidParameters
  }
}

function getFirstValue(parameterElements: ElementCollection) {
  return Object.values(parameterElements).map((el) => el.values[1]?.value)[0]
}

function getCurrentPath(isDPLParameters: boolean, param: ComponentParameter) {
  return isDPLParameters ? param.dplID : param.metlSlot
}

function getJobParam(
  isDPLParameters: boolean,
  componentParameters: Parameters,
  param: ComponentParameter
) {
  return isDPLParameters
    ? componentParameters?.[2]
    : componentParameters?.[param.metlSlot]
}
