export enum Cardinality {
  ZERO = 'ZERO',
  ONE = 'ONE',
  MANY = 'MANY',
  ZEROORMORE = 'ZERO_OR_MORE'
}

export enum OutputPortType {
  ITERATION = 'ITERATION',
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  UNCONDITIONAL = 'UNCONDITIONAL'
}

export enum InputPortType {
  INPUT = 'INPUT'
}

export const ConnectionPortType = {
  ...InputPortType,
  ...OutputPortType
}
export type ConnectionPortTypeT = InputPortType | OutputPortType

export interface Port {
  portId: ConnectionPortTypeT
  cardinality: Cardinality
}

export type Classification =
  | 'TRANSFORMATION'
  | 'EMERALD'
  | 'MODULAR'
  | 'NATIVE'
  | 'CONTROL_FLOW'
  | 'UNKNOWN'
