import { useCallback, useState, type FC, type PropsWithChildren } from 'react'

import { Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { useImportPipelines } from 'api/hooks/useImportPipelines/useImportPipelines'
import { useListProjects } from 'api/hooks/useListProjects'

import { ReactComponent as FolderIcon } from 'assets/root-folder.svg'

import { PopOverMenu } from 'components/PopOverMenu'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import ContextMenuButton from '../ContextMenuButton/ContextMenuButton'
import FileFolderDropDestination, {
  type FileDropDetails
} from '../FileFolderDropDestination/FileFolderDropDestination'
import FolderItemContextMenu from '../FolderItemContextMenu/FolderItemContextMenu'
import classes from './RootFolder.module.scss'

interface RootFolderProps {
  moveFile: (details: FileDropDetails) => void
}

const RootFolder: FC<PropsWithChildren<RootFolderProps>> = ({
  children,
  moveFile
}) => {
  const { projectId } = useProjectInfo()
  const { data: projects } = useListProjects()
  const [isDropping, setIsDropping] = useState(false)

  const { handleImportPipelines, ImportInputField } = useImportPipelines('')

  const currentProject = projects?.results.find(
    (project) => project.id === projectId
  )?.name

  const handleFolderLeave = useCallback(() => {
    setIsDropping(false)
  }, [])

  const handleFolderOver = useCallback(() => {
    setIsDropping(true)
  }, [])

  return (
    <>
      <FileFolderDropDestination
        id="root"
        onDropPipeline={moveFile}
        onFolderEnter={handleFolderOver}
        onFolderLeave={handleFolderLeave}
      >
        <PopOverMenu
          key="root"
          content={
            <FolderItemContextMenu
              path={''}
              handleImportPipelines={handleImportPipelines}
              isRootFolder
            />
          }
        >
          {({ onClick }) => {
            return (
              <div
                data-testid="root-level-folder"
                className={classNames(classes.Wrapper, {
                  [classes.Active]: isDropping
                })}
              >
                <ImportInputField />
                <div className={classes.ProjectNameRow}>
                  <div>
                    <FolderIcon className={classes.Icon} />
                  </div>

                  <Typography format="bcs" className={classes.ProjectName}>
                    {currentProject ?? '...'}
                  </Typography>
                </div>

                <ContextMenuButton id="root" key="root" onClick={onClick} />
              </div>
            )
          }}
        </PopOverMenu>
      </FileFolderDropDestination>

      {children}
    </>
  )
}

export default RootFolder
