import { Typography } from '@matillion/component-library'

import classes from './PreviewLabel.module.scss'

const previewLabel = () => {
  return (
    <div className={classes.PreviewLabel}>
      <Typography format="mc" weight="bold">
        Preview
      </Typography>
    </div>
  )
}

export default previewLabel
