import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Icon } from '@matillion/component-library'
import { FileType } from '@matillion/git-component-library'
import { useCopyComponent } from 'file-editors/canvas/modules/CopyPasteComponent/useCopyComponent'

import { ReactComponent as CopyIcon } from 'assets/copy.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import useCanRunComponent from 'hooks/useCanRunComponent/useCanRunComponent'
import { useFlags } from 'hooks/useFlags'

import {
  startImplementationId,
  unknownOrchestrationImplementationId,
  unknownTransformationImplementationId
} from 'job-lib/cisIds/knownComponentParameters'
import { useDeleteNodes } from 'job-lib/hooks/useDeleteNodes/useDeleteNodes'
import { type RootState } from 'job-lib/store'
import { type ComponentInstance } from 'job-lib/types/Job'

import { getComponentSelector } from 'modules/core/EtlDesigner/selectors/componentSelector'
import { singleStartComponentSelector } from 'modules/core/EtlDesigner/selectors/singleStartComponentSelector'

import classes from './ComponentNodeToolbar.module.scss'
import RunFromButton from './components/RunFromComponentButton'
import RunOnlyButton from './components/RunOnlyComponentButton'

interface ComponentToolbarProps {
  summaryId: string
  componentInstanceId: number
}

export const ComponentNodeToolbar = ({
  summaryId,
  componentInstanceId
}: ComponentToolbarProps) => {
  const { pipelineSummary } = useActivePipelineSummary()
  const jobId = pipelineSummary?.name ?? ''
  const fileType = pipelineSummary?.type ?? undefined
  const { copyComponent } = useCopyComponent()
  const { deleteNodes } = useDeleteNodes()
  const hasSingleStartComponent = useSelector<RootState, boolean>(
    singleStartComponentSelector
  )
  const component = useSelector<RootState, ComponentInstance | null>(
    getComponentSelector(componentInstanceId)
  )
  const { canRunFromComponent, canRunOnlyComponent } = useCanRunComponent(
    componentInstanceId,
    summaryId
  )
  const { t } = useTranslation()
  const { enableRunFromComponent, enableRunOnlyComponent } = useFlags()

  if (!component) {
    return null
  }

  const isUnknown =
    component.implementationID === unknownOrchestrationImplementationId ||
    component.implementationID === unknownTransformationImplementationId

  const isSingleStartComponent =
    component.implementationID === startImplementationId &&
    hasSingleStartComponent

  const isOrchestration = fileType === FileType.ORCHESTRATION_PIPELINE

  return (
    <div
      data-testid={`component-${componentInstanceId}-toolbar`}
      className={classes.Toolbar}
    >
      {enableRunOnlyComponent && isOrchestration && canRunOnlyComponent && (
        <RunOnlyButton jobId={jobId} />
      )}
      {enableRunFromComponent && isOrchestration && (
        <RunFromButton
          jobId={jobId}
          summaryId={summaryId}
          canRunFrom={canRunFromComponent}
        />
      )}
      <IconButton
        disabled={isUnknown}
        onClick={() => {
          copyComponent()
        }}
        className={classes.Toolbar__Button}
        label={t('componentToolbox.copyComponent')}
      >
        <CopyIcon />
      </IconButton>
      <IconButton
        disabled={isSingleStartComponent}
        onClick={() => {
          deleteNodes()
        }}
        className={classes.Toolbar__Button}
        label={t('componentToolbox.deleteComponent')}
      >
        <Icon.Trash />
      </IconButton>
    </div>
  )
}
