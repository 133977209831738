import {
  useReducer,
  type ChangeEvent,
  type FocusEvent,
  type FormEvent
} from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Field, Textarea } from '@matillion/component-library'
import classNames from 'classnames'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'

import { AutoComplete } from 'components/AutoComplete'

import {
  JobVariableBehaviour,
  JobVariableType,
  VariableScope,
  type JobVariable
} from 'job-lib/types/Variables'

import { useJobVariables } from 'modules/core/EtlDesigner/hooks/useJobVariables'
import baseClasses from 'modules/ManageVariables/ManageVariables.module.scss'
import {
  Fields,
  ReducerActions,
  type FormState,
  type ProjectVariable
} from 'modules/ManageVariables/types'
import { getVariableName, isJobVariable } from 'modules/ManageVariables/utils'

import { Footer } from '../Footer/Footer'
import { JobSpecificFields } from './components/JobSpecificFields'
import { ProjectSpecificFields } from './components/ProjectSpecificFields'
import classes from './Form.module.scss'
import { formReducer, getInitialState } from './formReducer/formReducer'

interface FormProps {
  variableScope: VariableScope
  variableType: JobVariableType
  submitting?: boolean
  variableToEdit?: ProjectVariable | JobVariable
  onSubmit: (state: FormState, isProjectVariable: boolean) => void
  onCancel: () => void
}

export const Form = ({
  variableScope,
  variableType,
  onSubmit,
  submitting,
  variableToEdit,
  onCancel
}: FormProps) => {
  const { t } = useTranslation()
  const jobVariables = useJobVariables()
  const { data: projectVariables = [] } = useListProjectVariables()
  const { hasPermission: canEditVariable } = useProjectPermission(
    'edit_environments',
    PermissionType.PROJECT
  )

  const [state, dispatch] = useReducer(
    formReducer,
    getInitialState({
      variableType,
      variableToEdit,
      variableScope,
      variableBehaviour: JobVariableBehaviour.SHARED
    })
  )

  const editing = variableToEdit !== undefined
  const _isJobVariable = editing
    ? isJobVariable(variableToEdit)
    : variableScope === VariableScope.JOB_VARIABLE
  const isDisabled = !_isJobVariable && !canEditVariable // only apply on project variables
  const variableTypeValue = editing ? state.VARIABLE_TYPE.value : variableType

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const isFormValid = state.isFormValid

    if (!isFormValid) {
      dispatch({
        type: ReducerActions.VALIDATE_FORM
      })

      return
    }

    onSubmit(state, _isJobVariable)
  }

  return (
    <form
      className={classNames(baseClasses.Stage, classes.Form)}
      onSubmit={onSubmitHandler}
    >
      <div className={classNames(classes.Form__Content)}>
        {editing && (
          <Field
            name={t('manageVariables.jobVariables.fields.type')}
            className={classNames(classes.Field, {
              [classes['Field--Type']]: !editing
            })}
            title={t('manageVariables.jobVariables.fields.type')}
            errorText={
              state[Fields.VARIABLE_TYPE].isValid === false &&
              t(state[Fields.VARIABLE_TYPE].error, {
                field: t('manageVariables.jobVariables.fields.type')
              })
            }
            value={variableTypeValue}
          >
            <AutoComplete
              inputClassName={classes['Field__Autocomplete--Compact']}
              optionClassName={classes['Field__Autocomplete--Compact']}
              placeholder={t(
                'manageVariables.jobVariables.fields.dropdownPlaceholder'
              )}
              onChange={(value) => {
                dispatch({
                  type: ReducerActions.UPDATE_VARIABLE_TYPE,
                  field: Fields.VARIABLE_TYPE,
                  value: value.target.value?.id as JobVariableType
                })
              }}
              onBlur={() => {
                dispatch({ type: ReducerActions.VALIDATE_FORM })
              }}
              availableItems={[
                {
                  id: JobVariableType.TEXT,
                  name: t(
                    'manageVariables.jobVariables.fields.variableType.text'
                  )
                },
                {
                  id: JobVariableType.NUMBER,
                  name: t(
                    'manageVariables.jobVariables.fields.variableType.number'
                  )
                }
              ]}
              value={variableTypeValue}
              disabled={isDisabled}
            />
          </Field>
        )}

        <Field
          name={Fields.NAME}
          className={classNames(
            classes.Field,
            classes['Field__Input--Compact']
          )}
          title={t('manageVariables.jobVariables.fields.variableName')}
          value={state[Fields.NAME].value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_NAME,
              value: e.target.value,
              originalName: getVariableName(variableToEdit),
              jobVariables,
              projectVariables
            })
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_NAME,
              value: e.target.value,
              originalName: getVariableName(variableToEdit),
              jobVariables,
              projectVariables
            })
          }}
          errorText={
            state[Fields.NAME].isValid === false &&
            t(state[Fields.NAME].error, {
              field: t('manageVariables.jobVariables.fields.variableName')
            })
          }
          disabled={isDisabled}
        />
        <Field
          name={Fields.DESCRIPTION}
          className={classes.Field}
          title={t('manageVariables.jobVariables.fields.description')}
          value={state[Fields.DESCRIPTION].value}
          maxLength={255}
        >
          <Textarea
            className={classes.Form__Description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              dispatch({
                type: ReducerActions.UPDATE_FIELD,
                field: Fields.DESCRIPTION,
                value: e.target.value
              })
            }}
            disabled={isDisabled}
          />
        </Field>

        {_isJobVariable && (
          <JobSpecificFields state={state} dispatch={dispatch} />
        )}
        {!_isJobVariable && (
          <ProjectSpecificFields
            isDisabled={isDisabled}
            state={state}
            dispatch={dispatch}
          />
        )}
      </div>
      <Footer
        warningText={
          !_isJobVariable
            ? t(getFooterWarningKey(isDisabled, editing))
            : undefined
        }
      >
        <Button
          size="md"
          disabled={Boolean(submitting)}
          alt="secondary"
          onClick={onCancel}
          text={t('common.cancel')}
        />
        <Button
          size="md"
          disabled={Boolean(submitting) || isDisabled}
          data-testid={editing ? 'edit-job-variable' : 'add-variable-to-job'}
          type="submit"
          text={
            editing
              ? t('manageVariables.edit.update')
              : t('manageVariables.create.create')
          }
        />
      </Footer>
    </form>
  )
}
function getFooterWarningKey(isDisabled: boolean, editing: boolean): string {
  return `manageVariables.${
    isDisabled ? 'disable' : editing ? 'edit' : 'create'
  }.footerWarning`
}
