import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Toaster, Typography } from '@matillion/component-library'

import { ReactComponent as CopilotIcon } from 'assets/copilot-icon.svg'

import { IconButton } from '../../../components/IconButton/IconButton'
import { useComponentValidationProvider } from '../../core/ComponentValidation'
import { type CopilotChatMessage } from '../../core/Copilot/types'
import { useCopilotContextProvider } from '../hooks/useCopilotContextProvider'
import ChatHistory from './ChatHistory/ChatHistory'
import classes from './Copilot.module.scss'
import CopilotIntro from './CopilotIntro/CopilotIntro'
import CopilotPromptInput from './CopilotPromptInput/CopilotPromptInput'
import { toSelectedComponentWithColumnMetadata } from './toSelectedComponentWithColumnMetadata'

interface CopilotProps {
  sourceComponentNames?: string[]
}

export const Copilot = ({ sourceComponentNames }: CopilotProps) => {
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const [prompt, setPrompt] = useState('')
  const { validationQueryCache } = useComponentValidationProvider()
  const { chatMessages, isLoadingUpdatePipeline, updatePipeline } =
    useCopilotContextProvider()

  const selectedComponentsWithValidationResults = useMemo(() => {
    return (sourceComponentNames || []).map((componentName) => {
      const validationQueryCacheElement = validationQueryCache[componentName]
      return toSelectedComponentWithColumnMetadata(
        componentName,
        validationQueryCacheElement
      )
    })
  }, [sourceComponentNames, validationQueryCache])

  const getSelectedComponentMsg = (components: string[] | undefined) => {
    const hasSelection = components && components.length > 0
    return (
      <div className={classes.Copilot__ComponentSelectionMsg}>
        <Typography format={'mc'} weight={'bold'}>
          {(hasSelection
            ? t('copilot.componentsSelected')
            : t('copilot.noComponentSelected')) + ': '}
        </Typography>
        <Typography format={'mc'}>
          {hasSelection
            ? components.join(', ')
            : t('copilot.selectComponentCta')}
        </Typography>
      </div>
    )
  }

  const handleSubmit = useCallback(
    async (message: CopilotChatMessage) => {
      try {
        await updatePipeline(message, selectedComponentsWithValidationResults)
        setPrompt('')
      } catch (error: unknown) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'
        makeToast({
          type: 'error',
          title: t('copilot.error.title'),
          message: errorMessage
        })
      }
    },
    [makeToast, selectedComponentsWithValidationResults, t, updatePipeline]
  )

  return (
    <div data-testid="copilot" className={classes.Copilot__Container}>
      <div className={classes.Copilot__TopSection}>
        <div className={classes.Copilot__Header}>
          <CopilotIcon className={classes.Copilot__Header__Icon} />
          <div className={classes.Copilot__Header__Options}>
            <IconButton
              className={classes.Copilot__Header__Options__IconButton}
              label={'Help'}
            >
              <a
                href="https://docs.matillion.com/data-productivity-cloud/designer/docs/copilot-overview/"
                target="_blank"
                rel="noopener noreferrer"
                data-testid={'help-button'}
              >
                <Icon.Help />
              </a>
            </IconButton>
          </div>
        </div>
        <div className={classes.Copilot__TopSection}>
          {chatMessages.length > 0 ? (
            <ChatHistory
              messages={chatMessages}
              isLoading={isLoadingUpdatePipeline}
            />
          ) : (
            <CopilotIntro />
          )}
        </div>
      </div>
      <div className={classes.Copilot__BottomSection}>
        <div className={classes.Copilot__SelectedComponents}>
          <Typography
            format={'bcs'}
            data-testid={'copilot-selected-components'}
          >
            {getSelectedComponentMsg(sourceComponentNames)}
          </Typography>
        </div>
        <div className={classes.Copilot__PromptInput}>
          <CopilotPromptInput
            onComplete={handleSubmit}
            isLoading={isLoadingUpdatePipeline}
            prompt={prompt}
            setPrompt={setPrompt}
          />
        </div>
      </div>
    </div>
  )
}
