import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { useGetRootPipelineActivity } from 'api/hooks/useGetRootPipelineActivity/useGetRootPipelineActivity'
import { useGetStepsInfinite } from 'api/hooks/useGetSteps/useGetSteps'

import { CenteredSpinner } from '../components/CenteredSpinner'
import ErrorMessage from '../components/ErrorMessage'
import { InfiniteScrollAnchor } from '../components/InfiniteScrollAnchor'
import { Table, TBody, THead } from '../components/PipelineTable'
import classes from './PipelineTree.module.scss'
import { PipelineTreeHeadRow } from './PipelineTreeHeadRow'
import StepRow from './StepRow'

interface PipelineTreeProps {
  pipelineRunId: string
}

const PipelineTree: FC<PipelineTreeProps> = ({ pipelineRunId }) => {
  const {
    data,
    isLoading: isRootLoading,
    isError: isRootError
  } = useGetRootPipelineActivity(pipelineRunId)
  const { steps, isFetching, isError, hasMore, fetchNextPage } =
    useGetStepsInfinite(pipelineRunId)

  const { t } = useTranslation()

  if (isError || isRootError) {
    return <ErrorMessage>{t('pipelineRunExplorer.networkError')}</ErrorMessage>
  }

  return (
    <div
      className={classes.PipelineTree__Container}
      data-testid="pipeline-tree-container"
    >
      {steps && steps?.length > 0 && (
        <>
          <Table>
            <THead sticky>
              <PipelineTreeHeadRow
                cellClassName={classes.PipelineTree__Cell}
                className={classes.PipelineTree__Row}
              />
            </THead>
            <TBody>
              {steps?.map((step, index) => (
                <StepRow
                  cellClassName={classes.PipelineTree__Cell}
                  rowClassName={classes.PipelineTree__Row}
                  step={step}
                  depth={0}
                  pipelineName={data?.definition.pipelineName}
                  key={step.id}
                  isRoot={true}
                  isLast={index === steps.length - 1}
                  treeLines={[]}
                />
              ))}
            </TBody>
          </Table>

          {hasMore && <InfiniteScrollAnchor onIntersect={fetchNextPage} />}
          {!isFetching && !hasMore && (
            <div className={classes.PipelineTree__EndOfResults}>
              <Typography type="bcl">
                {t('pipelineRunExplorer.endOfResults')}
              </Typography>
            </div>
          )}
          {(isFetching || isRootLoading) && <CenteredSpinner />}
          <div className={classes.PipelineTree__Spacer}></div>
        </>
      )}
    </div>
  )
}

export default PipelineTree
