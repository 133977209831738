import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Toaster, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { ReactComponent as ThumbDown } from 'assets/thumb-down.svg'
import { ReactComponent as ThumbUp } from 'assets/thumb-up.svg'

import { IconButton } from '../../../../../components/IconButton/IconButton'
import {
  AMBIGUOUS_PROMPT_ERROR,
  type CopilotChatMessage
} from '../../../../core/Copilot/types'
import { useCopilotContextProvider } from '../../../hooks/useCopilotContextProvider'
import CopilotFeedbackForm from '../../CopilotFeedbackForm/CopilotFeedbackForm'
import classes from './ChatMessage.module.scss'

interface ChatMessageProps {
  message: CopilotChatMessage
  lastMessage?: boolean
  scrollChat?: () => void
}

type FeedbackSentiment = 'GOOD' | 'BAD'

const ChatMessage = ({
  message,
  lastMessage,
  scrollChat
}: ChatMessageProps) => {
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const [feedbackSentiment, setFeedbackSentiment] = useState<
    FeedbackSentiment | undefined
  >(undefined)
  const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false)
  const { submitFeedback, isLoadingSubmitFeedback } =
    useCopilotContextProvider()

  useEffect(() => {
    scrollChat?.()
  }, [scrollChat, showFeedbackForm])

  const sendFeedback = useCallback(
    async (
      feedbackMessage: CopilotChatMessage,
      sentiment: FeedbackSentiment,
      feedbackContent: string
    ) => {
      try {
        await submitFeedback(feedbackMessage, sentiment, feedbackContent)
      } catch (error: unknown) {
        const errorMessage =
          error instanceof Error ? error.message : t('copilot.error.unexpected')
        makeToast({
          type: 'error',
          title: t('copilot.error.title'),
          message: errorMessage
        })
      }
    },
    [makeToast, submitFeedback, t]
  )

  const setFeedbackTypeHandler = useCallback(
    async (sentiment: FeedbackSentiment) => {
      setFeedbackSentiment(sentiment)
      setShowFeedbackForm(true)
      await sendFeedback(message, sentiment, '')
    },
    [sendFeedback, message]
  )

  return (
    <div className={classes.ChatMessage__Container}>
      <div
        className={
          message.type === 'user'
            ? classes.ChatMessage__Row__User
            : classes.ChatMessage__Row__Bot
        }
        data-testid="chat-message"
      >
        <div className={classes.ChatMessage__Message}>
          <div
            className={
              message.type === 'user'
                ? classes.ChatMessage__Content__User
                : classes.ChatMessage__Content__Bot
            }
          >
            {message.errorType === AMBIGUOUS_PROMPT_ERROR ? (
              <Typography format="bcs" data-testid="msg-error-ambiguous_prompt">
                <Trans
                  t={t}
                  i18nKey="copilot.chat.error.ambiguous_prompt.message"
                  components={{
                    ExamplesLink: (
                      <a
                        aria-label="copilot-example-usage"
                        target="_blank"
                        href="https://docs.matillion.com/data-productivity-cloud/designer/docs/copilot-example-usage/"
                        rel="noreferrer"
                      ></a>
                    )
                  }}
                />
              </Typography>
            ) : (
              <Typography format="bcs">{message.content}</Typography>
            )}
          </div>
          {lastMessage && message.type === 'bot' && !message.errorType && (
            <div className={classes.ChatMessage__Ratings}>
              <IconButton
                className={classNames(
                  classes.ChatMessage__RatingButton,
                  feedbackSentiment === 'BAD' &&
                    classes.ChatMessage__RatingButton__NotSelected,
                  feedbackSentiment === 'GOOD' &&
                    classes.ChatMessage__RatingButton__Selected
                )}
                label={t('copilot.chat.rating.goodLabel')}
                onClick={() => {
                  setFeedbackTypeHandler('GOOD')
                }}
                data-testid="rate-response-good"
              >
                <ThumbUp />
              </IconButton>
              <IconButton
                className={classNames(
                  classes.ChatMessage__RatingButton,
                  feedbackSentiment === 'GOOD' &&
                    classes.ChatMessage__RatingButton__NotSelected,
                  feedbackSentiment === 'BAD' &&
                    classes.ChatMessage__RatingButton__Selected
                )}
                label={t('copilot.chat.rating.badLabel')}
                onClick={() => {
                  setFeedbackTypeHandler('BAD')
                }}
                data-testid="rate-response-bad"
              >
                <ThumbDown />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      {lastMessage && feedbackSentiment && showFeedbackForm && (
        <CopilotFeedbackForm
          isLoading={isLoadingSubmitFeedback}
          onSubmit={(feedback) => {
            sendFeedback(message, feedbackSentiment, feedback).then(() => {
              setShowFeedbackForm(false)
            })
          }}
          feedbackType={feedbackSentiment}
          onClose={() => {
            setShowFeedbackForm(false)
            scrollChat?.()
          }}
        />
      )}
    </div>
  )
}

export default ChatMessage
