import { useCallback, useEffect, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Input } from '@matillion/component-library'

import { type LookUpType } from 'api/hooks/useGetComponentMetadata/types'

import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { ParameterOverlayContent } from 'components/ParameterOverlay/components/Content'

import { type StorageEditorType } from 'modules/ParameterEditors/types'

import classes from '../../UrlEditor.module.scss'
import ContainerList from '../ContainerList/ContainerList'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { useGetContainers } from '../hooks/useGetContainers'
import { useGrid } from '../hooks/useGrid'

export interface CloudStorageExplorerProps {
  parameterName: string
  value: string[]
  lookupType?: LookUpType | null
  fileType?: string | null
  onDone: (editedValue: string[]) => unknown
  uriSchema: string
  storageEditorType: StorageEditorType
  name: string
  translationKey: string
}

export const CloudStorageExplorer = ({
  value,
  onDone,
  parameterName,
  lookupType,
  fileType,
  uriSchema,
  storageEditorType,
  name,
  translationKey: keyPrefix
}: CloudStorageExplorerProps) => {
  const { t } = useTranslation()
  const initialUrl = value[0]?.replace(uriSchema, '') || ''
  const [warehouseUrl, setWarehouseUrl] = useState<string>(initialUrl)
  const [error, setError] = useState<string | null>()

  const initialFullPath = {
    path: initialUrl.split('/').filter(Boolean),
    isFolder: true
  }

  const [fullPath, setFullPath] = useState<{
    path: string[]
    isFolder: boolean
  }>(initialFullPath)

  const onContainerPathChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setWarehouseUrl(e.target.value)
    },
    [setWarehouseUrl]
  )

  const {
    isBucketsLoading,
    isFoldersLoading,
    buckets,
    folders,
    objects,
    foldersError,
    bucketsError
  } = useGetContainers(fullPath, storageEditorType, lookupType, fileType)

  const title = t('table.titles.containersName', { keyPrefix })

  const { rows, columns, setSort } = useGrid({
    title,
    objects: objects ?? [],
    folders: folders ?? [],
    buckets: buckets ?? [],
    fullPath,
    setFullPath,
    setWarehouseUrl
  })

  const handleError = (errorMessage: string) => {
    setError(errorMessage)
    setFullPath({ path: [], isFolder: true })
  }

  useEffect(() => {
    if (foldersError && foldersError.status === 403) {
      handleError(
        `${t('error.accessDenied', { keyPrefix })} ${foldersError.detail}`
      )
    } else if (foldersError) {
      handleError(
        `${t('error.foldersError', { keyPrefix })} ${foldersError.detail}`
      )
    } else if (bucketsError) {
      handleError(
        `${t('error.bucketsError', { keyPrefix })} ${bucketsError.detail}`
      )
    }
  }, [foldersError, setError, bucketsError, warehouseUrl, t, error, keyPrefix])

  useEffect(() => {
    if (warehouseUrl) {
      setError(null)
    }
  }, [warehouseUrl])

  const onSubmit = useCallback(() => {
    const newUrl = warehouseUrl.toLowerCase().includes(uriSchema)
      ? warehouseUrl
      : `${uriSchema}${warehouseUrl}`
    onDone([newUrl])
  }, [warehouseUrl, uriSchema, onDone])

  const handleEnterPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (!objects?.includes(warehouseUrl.split('/').reverse()[0])) {
          setFullPath((fp) => {
            return {
              path: warehouseUrl.split('/').filter(Boolean),
              isFolder: fp.isFolder
            }
          })
        }
      }
    },
    [warehouseUrl, objects]
  )

  const handleBlur = () => {
    if (isBucketsLoading && isFoldersLoading) return
    if (!warehouseUrl) {
      setError(t('error.emptyValue', { keyPrefix }))
    }
  }

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <ParameterOverlayContent>
        <Field
          id={`${name}urleditor-url`}
          name={`${name}urleditor-url`}
          title={t('url.title', { keyPrefix })}
          aria-label={t('url.title', { keyPrefix })}
          placeholder={t('url.placeholder', { keyPrefix })}
          prefix={uriSchema.toUpperCase()}
          value={warehouseUrl}
          onChange={onContainerPathChange}
          onKeyDown={handleEnterPress}
          onBlur={handleBlur}
          hasError={!!error}
          error={!!error}
          errorText={
            error && (
              <ErrorMessage
                storageEditorType={storageEditorType}
                text={error}
              />
            )
          }
          inputComponent={Input}
          className={classes['Buckets--Input']}
        />
        <ContainerList
          isLoading={isBucketsLoading || isFoldersLoading}
          columns={columns}
          rows={rows}
          setSort={setSort}
        />
      </ParameterOverlayContent>
      <ParameterOverlayFooter>
        <ParameterOverlayButton onClick={onSubmit} text={t('common.save')} />
      </ParameterOverlayFooter>
    </>
  )
}
