import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import type { StepsConfig } from './types'
import type { WizardStep, WizardStepParameter } from './wizardConfigs/types'

export const lookForParameter = (
  dplId: string,
  parameters: ComponentParameter[],
  parameterPath: string[]
): [ComponentParameter | null, string[]] => {
  let foundParameter = null

  for (const parameter of parameters) {
    if (parameter.dplID === dplId) {
      foundParameter = parameter
      break
    }

    if (parameter.childProperties) {
      const newPath = [parameter.dplID, ...parameterPath]
      const [foundChildParameter, childPath] = lookForParameter(
        dplId,
        parameter.childProperties,
        newPath
      )
      if (foundChildParameter) {
        return [foundChildParameter, childPath]
      }
    }
  }

  return [foundParameter, parameterPath]
}

const getStepParameters = (
  parameters: WizardStepParameter[],
  componentMetadata: ComponentMetadata
): Array<[ComponentParameter | null, string[]]> => {
  const stepParameters = parameters.map((parameter) => {
    const topLevelParameter = componentMetadata.parameters.find(
      (p) => p.dplID === parameter.dplId
    )

    if (topLevelParameter) {
      return [topLevelParameter, []] as [ComponentParameter | null, string[]]
    }

    return lookForParameter(parameter.dplId, componentMetadata.parameters, [])
  })

  return stepParameters
}

export const getWizardStepsConfigs = (
  steps: WizardStep[],
  componentMetadata: ComponentMetadata
) => {
  return steps.reduce((acc: StepsConfig, step) => {
    acc[step.stepId] = {
      stepId: step.stepId,
      parameters: getStepParameters(step.parameters, componentMetadata)
    }
    return acc
  }, {})
}
