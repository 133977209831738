import { useMutation } from '@tanstack/react-query'

import {
  type OptimisedQueryTask,
  type PipelineContext
} from 'api/hooks/copilot/model'
import useCopilotClient from 'api/hooks/useCopilotClient/useCopilotClient'

import {
  type OrchestrationJob,
  type TransformationJob
} from '../../../../job-lib/types/Job'
import { type Components, type Pipeline } from '../../../../types/Pipeline'
import { updatePipeline } from '../requests'

export interface PipelineUpdateInput {
  requestId: string
  pipeline: PipelineContext
  warehouse: string
  tasks: OptimisedQueryTask[]
  message: string
}

export interface CopilotResponse {
  requestId: string
  choices: Array<{
    id: string
    summary: string
    addedComponents: Components
    source: Pipeline
    legacyMETL: TransformationJob | OrchestrationJob
  }>
}

export const useUpdatePipeline = () => {
  const client = useCopilotClient()

  return useMutation(async (input: PipelineUpdateInput) => {
    const response = await updatePipeline(client, input)

    return response.data
  })
}
