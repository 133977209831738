import { type AxiosInstance } from 'axios'

import { type StepMessage } from 'api/hooks/useEosClient/types/eos'

export interface GetStepMessagesResponse {
  messages: StepMessage[]
}

export const getStepMessages = async (
  client: AxiosInstance,
  stepId: string
): Promise<GetStepMessagesResponse> => {
  const { data } = await client.get<GetStepMessagesResponse>(
    `/v1/activity/step/${stepId}/messages`
  )
  return data
}
