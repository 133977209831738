import { type SourceComponentConnection } from 'file-editors/canvas/modules/Canvas/components/AddNextComponent/AddNextComponent'
import {
  MELTtoDPLTransition,
  type ComponentDesign,
  type ComponentId,
  type ComponentName,
  type Parameters,
  type Pipeline
} from 'types/Pipeline'

import {
  ParameterDataType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { componentNameParameter } from 'job-lib/cisIds/knownComponentParameters'

import {
  getParameterDefaultValue,
  isParameterDefaultVisible
} from 'utils/isParameterVisible'

import { getNextComponentName } from '../../utils/getNextComponentName'

export interface AddComponentPayload {
  componentId: ComponentId
  componentName: ComponentName
  componentMetadata?: ComponentMetadata
  componentDesign: ComponentDesign
  sourceComponentConnection?: SourceComponentConnection
  initialParameters?: Record<string, string>
}

export const createParameters = (
  componentName: ComponentName,
  componentMetadata: ComponentMetadata,
  initialParameters?: Record<string, string>
): Parameters => {
  const createParameter = (
    componentParameters: Parameters,
    parameter: ComponentParameter,
    parameterPath: string[]
  ) => {
    if (
      !isParameterDefaultVisible(
        componentMetadata,
        parameter.visibleWhen,
        parameterPath
      )
    ) {
      return componentParameters
    }

    const parameterId = parameter.dplID

    if (
      parameter.childProperties &&
      parameter.dataType !== ParameterDataType.STRUCT_LIST
    ) {
      componentParameters[parameterId] = {}

      parameter.childProperties.forEach((childProperty) => {
        createParameter(
          componentParameters[parameterId] as Parameters,
          childProperty,
          [...parameterPath, parameter.dplID]
        )
      })
    } else {
      componentParameters[parameterId] = getParameterDefaultValue(
        parameter,
        initialParameters
      )
    }

    return componentParameters
  }

  // the componentName parameter is dynamically generated so can be ignored
  const componentParameters = componentMetadata.parameters.filter(
    ({ dplID }: ComponentParameter) => dplID !== componentNameParameter
  )

  return componentParameters.reduce(
    (createdParameters: Parameters, parameter: ComponentParameter) => {
      return createParameter(createdParameters, parameter, [])
    },
    { componentName }
  )
}

export const addComponent =
  (state: Pipeline) =>
  ({
    componentId,
    componentName,
    componentMetadata,
    componentDesign,
    sourceComponentConnection,
    initialParameters
  }: AddComponentPayload) => {
    if (!componentMetadata) {
      console.error('Component metadata not found:', componentName)
      return
    }

    const nextComponentName = getNextComponentName(
      state.pipeline.components,
      componentName
    )

    state.pipeline.components[nextComponentName] = {
      type: componentId,
      parameters: createParameters(
        nextComponentName,
        componentMetadata,
        initialParameters
      )
    }

    state.design.components[nextComponentName] = componentDesign

    if (sourceComponentConnection) {
      const transitionType =
        MELTtoDPLTransition[sourceComponentConnection.sourceType]
      const sourceComponent =
        state.pipeline.components[sourceComponentConnection.sourceComponentName]

      sourceComponent.transitions = sourceComponent.transitions ?? {}
      sourceComponent.transitions[transitionType] = [
        ...(sourceComponent.transitions[transitionType] ?? []),
        nextComponentName
      ]
    }
  }
