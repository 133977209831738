import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { isCustomConnector } from 'job-lib/cisIds/idType'
import { type JobType } from 'job-lib/types/JobType'

import camelToKebabCase from 'utils/camelToKebabCase'
import { isMacOs } from 'utils/isMacOs'

import { CanvasContextMenu, type ContextMenuItem } from './CanvasContextMenu'

interface ContextMenuProps {
  jobName: string
  jobType?: JobType
  hasSelectedNodes: boolean
  hasSelectedComponents: boolean
  rightClickedComponentSummaryId: string | null
  onCommand: (id: string) => unknown
}

export const ContextMenu: FC<ContextMenuProps> = ({
  jobName,
  jobType,
  hasSelectedNodes,
  hasSelectedComponents,
  rightClickedComponentSummaryId,
  onCommand
}) => {
  const { t } = useTranslation()
  const isContextMacOS = {
    ...(isMacOs() && { context: 'macOS' })
  }

  const isRightClickedCustomConnector = Boolean(
    rightClickedComponentSummaryId &&
      isCustomConnector(rightClickedComponentSummaryId)
  )

  return (
    <>
      {CanvasContextMenu({
        jobName,
        jobType,
        hasSelectedNodes,
        hasSelectedComponents,
        isRightClickedCustomConnector
      }).map(({ id: sectionId, items }) => (
        <PopOverSection key={`canvas-context-menu-section-${sectionId}`}>
          {items.map(
            ({
              id,
              disabled,
              shortcut,
              context,
              subtext,
              preview = false
            }: ContextMenuItem) => (
              <PopOverItem
                key={`canvas-context-menu-item-${id}`}
                data-testid={`btn-context-menu-item-${id}`}
                data-heapid={`etld_context-menu-${camelToKebabCase(
                  id
                )}-cta_click`}
                data-heap-prop-jobtype={jobType}
                onClick={() => onCommand(id)}
                disabled={disabled}
                preview={preview}
                subtext={subtext}
                shortcut={shortcut && t(shortcut, context ?? isContextMacOS)}
              >
                {t(`translation:canvasContextMenu.${id}`)}
              </PopOverItem>
            )
          )}
        </PopOverSection>
      ))}
    </>
  )
}
