import { type CreateProjectVariablesRequest } from '../useCreateProjectVariables/types'
import { type GetProjectResponse } from '../useGetProject/types'
import { type GetProjectEnvironmentResponse } from '../useGetProjectEnvironmentInfo/types'
import { type ListProjectVariablesResponse } from '../useListProjectVariables/types'
import { useProjectExplorerClient } from '../useProjectExplorerClient/useProjectExplorerClient'
import { type UpdateProjectVariablesRequest } from '../useUpdateProjectVariables/types'

export const useProjectExplorerApi = () => {
  const client = useProjectExplorerClient()

  const getProject = async (projectId: string) => {
    return client.get<GetProjectResponse>(`/v1/projects/${projectId}`)
  }

  const getProjectEnvironmentInfo = async (
    projectId: string,
    environmentId: string
  ) => {
    return client.get<GetProjectEnvironmentResponse>(
      `/v1/projects/${projectId}/environments/${environmentId}`
    )
  }

  const createProjectVariables = async (
    projectId: string,
    createProjectVariablesRequest: CreateProjectVariablesRequest
  ) => {
    return client.post(
      `/v1/projects/${projectId}/variables`,
      createProjectVariablesRequest
    )
  }

  const deleteProjectVariables = async (
    projectId: string,
    variableId: string
  ) => {
    return client.delete(`/v1/projects/${projectId}/variables/${variableId}`)
  }

  const listProjectVariables = async (projectId: string) => {
    return client.get<ListProjectVariablesResponse>(
      `/v1/projects/${projectId}/variables`
    )
  }

  const updateProjectVariables = async (
    projectId: string,
    variableId: string,
    updateProjectVariablesRequest: UpdateProjectVariablesRequest
  ) => {
    return client.patch(
      `/v1/projects/${projectId}/variables/${variableId}`,
      updateProjectVariablesRequest
    )
  }

  return {
    getProject,
    getProjectEnvironmentInfo,
    createProjectVariables,
    deleteProjectVariables,
    listProjectVariables,
    updateProjectVariables
  }
}
