import { useRef, useState, type FC } from 'react'

import { Table as T, Tooltip, Typography } from '@matillion/component-library'
import { useVirtualizer } from '@tanstack/react-virtual'
import classNames from 'classnames'

import {
  type SampleComponentResponse,
  type SampleComponentResponseColumns
} from 'api/hooks/useSampleComponent/types'

import { DragHandle } from './DragHandle'
import { useResizeEvents } from './hooks/useResizeEvents'
import SampleTableDataCell from './SampleTableDataCell'
import classes from './VirtualisedSampleComponent.module.scss'

const { Table, TableHead, TableRow, TableHeaderCell, TableBody } = T

interface SampleTableProps {
  title: string
  data: SampleComponentResponse
}

export interface ColumnToResize {
  columnId: string
  columnIndex: number
}

export const VirtualisedSampleTable: FC<SampleTableProps> = ({
  title,
  data
}) => {
  const [columnToResize, setColumnToResize] = useState<null | ColumnToResize>(
    null
  )

  useResizeEvents({
    headerClass: classes.SampleComponentResults__TableHead,
    rowClass: classes.SampleComponentResults__Row,
    metadata: data.metadata,
    rows: data.rows,
    columnToResize,
    setColumnToResize
  })

  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: data.rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 34,
    overscan: 10
  })

  return (
    <div
      data-testid="sample-table-scroll-container"
      className={classes.SampleComponentResultsWizard}
      ref={parentRef}
    >
      <div className={classes.SampleComponentResults__TableWrapper}>
        <Table
          data-testid="sample-table-data"
          className={classes.SampleComponentResults__Table}
          aria-label={title}
        >
          <TableHead className={classes.SampleComponentResults__TableHead}>
            <TableRow className={classes.SampleComponentResults__Row}>
              {data.metadata.map(
                (
                  column: SampleComponentResponseColumns,
                  columnIndex,
                  metadata
                ) => (
                  <TableHeaderCell
                    key={column.name}
                    id={column.name}
                    data-testid={`sample-column-header-${column.name}`}
                    className={classNames(
                      classes.SampleComponentResults__HeaderCell,
                      {
                        [classes.ColumnResizing]:
                          columnToResize?.columnIndex === columnIndex
                      }
                    )}
                  >
                    {columnIndex !== 0 && (
                      <DragHandle
                        position="left"
                        setColumnToResize={setColumnToResize}
                        columnId={metadata[columnIndex - 1].name}
                        columnIndex={columnIndex - 1}
                      />
                    )}
                    <Typography
                      className={classes.SampleComponentResults__HeaderCellText}
                      format="bcs"
                      weight="bold"
                    >
                      <Tooltip content={column.name}>
                        <span>{column.name}</span>
                      </Tooltip>
                    </Typography>
                    <DragHandle
                      position="right"
                      setColumnToResize={setColumnToResize}
                      columnId={column.name}
                      columnIndex={columnIndex}
                    />
                  </TableHeaderCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody
            className={classes.SampleComponentResults__TableBody}
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualItem, index) => {
              const rowData = data.rows[virtualItem.index].values

              if (!rowData) return null

              return (
                <TableRow
                  className={classes.SampleComponentResults__Row}
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  ref={rowVirtualizer.measureElement}
                  style={{
                    transform: `translateY(${virtualItem.start}px)`
                  }}
                >
                  {rowData.map((value: string, cellIndex: number) => {
                    return (
                      <SampleTableDataCell
                        isVirtualised
                        key={`cell-${String(index)}-${String(cellIndex)}`}
                        value={value}
                        isResizing={columnToResize?.columnIndex === cellIndex}
                      />
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
