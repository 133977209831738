import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CollapsiblePanel,
  Typography
} from '@matillion/component-library'

import { type LoadStrategy } from '../../../../types'
import {
  type ApplyToAllCondition,
  type OnColumnsSelectionChange
} from '../../types'
import { ConditionRow } from '../ConditionRow/ConditionRow'
import classes from './Conditions.module.scss'

export interface AddTemplateStatus {
  inProgress: boolean
  tableName: string | null
}

interface ConditionsProps {
  conditions: ApplyToAllCondition[]
  tableName: string
  loadStrategy: LoadStrategy
  availableConditionTables: string[]
  addCondition: (tableName: string) => void
  onColumnsSelectionChange: OnColumnsSelectionChange
  onColumnTableChange: (currentTableName: string, newTableName: string) => void
  onApplyToAll: () => void
}

export const Conditions: FC<ConditionsProps> = ({
  conditions,
  tableName,
  loadStrategy,
  availableConditionTables,
  addCondition,
  onColumnsSelectionChange,
  onColumnTableChange,
  onApplyToAll
}) => {
  const { t } = useTranslation()

  return (
    <CollapsiblePanel
      contentClassName={classes.Accordion__Content}
      title={t('multiTableConfig.applyToAll.conditions.panelTitle')}
      subtitle={tableName}
      theme="grey-white"
    >
      {conditions.length > 0 && (
        <ConditionRow
          conditionId={0}
          condition={conditions[0]}
          loadStrategy={loadStrategy}
          availableConditionTables={availableConditionTables}
          onChange={onColumnsSelectionChange}
          onChangeTable={onColumnTableChange}
        />
      )}
      {conditions.length > 1 && (
        <div className={classes.MoreTemplates__Container}>
          <Typography format="bcm" class={classes.MoreTemplates__Title}>
            {t('multiTableConfig.applyToAll.conditions.secondaryConditions')}
          </Typography>
          <div
            data-testid="add-condition-panel"
            className={classes.AddTemplate__TableRow}
          >
            {conditions.slice(1).map((template, index) => {
              return (
                <ConditionRow
                  conditionId={index + 1}
                  key={`apply-all-template-${template.tableName}`}
                  condition={template}
                  loadStrategy={loadStrategy}
                  availableConditionTables={availableConditionTables}
                  showTableName
                  onChange={onColumnsSelectionChange}
                  onChangeTable={onColumnTableChange}
                />
              )
            })}
          </div>
        </div>
      )}
      <div>
        <button
          className={classes.AddTemplate__StartButton}
          type="button"
          onClick={() => {
            if (availableConditionTables.length > 0) {
              addCondition(availableConditionTables[0])
            }
          }}
        >
          <Typography className={classes.AddTemplate__ButtonText} format="bcs">
            {t('multiTableConfig.applyToAll.addCondition.actionText')}
          </Typography>
        </button>
        <Typography className={classes.AddTemplate__OptionalText} format="bcs">
          ({t('common.optional')})
        </Typography>
        <Typography className={classes.AddTemplate__Text} format="bcs">
          {t('multiTableConfig.applyToAll.addCondition.actionDescription')}
        </Typography>
      </div>
      <div className={classes.Accordion__Footer}>
        <Button data-testid="apply-configuration" onClick={onApplyToAll}>
          <Typography format="bcs" weight="bold">
            {t('multiTableConfig.applyToAll.conditions.apply')}
          </Typography>
        </Button>
      </div>
    </CollapsiblePanel>
  )
}
