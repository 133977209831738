import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import classes from './EditorErrorBoundaryFallback.module.scss'

export interface EditorErrorBoundaryFallbackProps {
  fileName: string
  language: string
}

export const EditorErrorBoundaryFallback = ({
  fileName,
  language
}: EditorErrorBoundaryFallbackProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'textEditorErrorBoundary'
  })

  return (
    <div
      className={classes.ErrorBoundaryFallback}
      data-testid="file-text-editor-error-fallback"
    >
      <div className={classes.ErrorBoundaryFallback__Container}>
        <Typography
          as="h2"
          format="tl"
          className={classes.ErrorBoundaryFallback__Title}
        >
          {t('title')}
        </Typography>

        <Typography className={classes.ErrorBoundaryFallback__Description}>
          {t('description', { language, fileName })}
        </Typography>
      </div>
    </div>
  )
}
