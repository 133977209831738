import { useCallback, useEffect, useState } from 'react'
import { type Node } from 'reactflow'

import { useEtlFlow } from 'file-editors/canvas/hooks/useEtlFlow'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import {
  EtlCanvasNodeType,
  useCanvasModel,
  type ComponentNodeData
} from '../../../hooks/useCanvasModel/useCanvasModel'
import {
  getSelectedNodeIds,
  isComponentNode
} from '../../../hooks/useCanvasModel/utils'
import { type FlowCanvasProps } from '../types'
import { useHasNodeCountChanged } from './useHasNodeCountChanged'

const nodeTypeMap = {
  [EtlCanvasNodeType.NODE]: 'component',
  [EtlCanvasNodeType.ITERATOR]: 'iterator',
  [EtlCanvasNodeType.NOTE]: 'note'
}

const isIteratorNode = (node: Node): node is Node<ComponentNodeData> => {
  return node.type === EtlCanvasNodeType.ITERATOR
}

export const useSyncedCanvasModel = (job: FlowCanvasProps['job']) => {
  const [nodesInit, setNodesInit] = useState(false)
  const reactFlow = useEtlFlow()
  const canvasModel = useCanvasModel(job)
  const { nodes } = canvasModel
  const { componentId: selectedComponentId } = useProjectInfo()
  const hasNodeCountChanged = useHasNodeCountChanged(nodes)

  /*
   * We can't use react-flow's controlled mode, because updating
   * the job model and re-rendering nodes and edges is computationally
   * pretty expensive--doing that as components are moved around makes
   * the UI really laggy. Using the controlled flow lets us mirror changes
   * back into the job model separate from user actions, but also means that
   * we need to synchronise changes back into react-flow manually.
   *
   * In order not to deselect all nodes on change--and, therefore, close
   * the component properties panel--we also need to ensure that the selected
   * state is persisted between updates.
   */

  const syncCanvasModel = useCallback(() => {
    const selectedNodes = getSelectedNodeIds(reactFlow)

    const nodesWithSelectedState = [...canvasModel.nodes.values()].map(
      (node, index, arr) => {
        const isComponentNodeType = isComponentNode(node)
        const type = isComponentNodeType
          ? nodeTypeMap[EtlCanvasNodeType.NODE]
          : nodeTypeMap[node.type as keyof object]

        const internallySelected = selectedNodes.includes(node.id)
        const attachedIteratorSelected =
          isIteratorNode(node) &&
          node.data.attachedNode?.id &&
          selectedNodes.includes(node.data.attachedNode.id)

        /**
         * We only need to set the initial state of the selected component
         * based on the url when the app first loads.
         * The selected componentId can't be used to
         * correctly determine if a component is selected outside of
         * setting the initial selected state.
         * The selected state based on the component id being in the url
         * is later handled in the EtlNode & IteratorNode
         */
        const selectedByUrl =
          !nodesInit &&
          isComponentNodeType &&
          node.id === `${type}-${selectedComponentId}`

        const currentlySelected = [
          internallySelected,
          attachedIteratorSelected,
          selectedByUrl
        ].some(Boolean)

        const selected = hasNodeCountChanged
          ? index === arr.length - 1
          : currentlySelected

        setNodesInit(true)

        return {
          ...node,
          selected
        }
      }
    )

    reactFlow.setNodes(nodesWithSelectedState)
    reactFlow.setEdges([...canvasModel.edges.values()])
  }, [
    reactFlow,
    canvasModel.nodes,
    canvasModel.edges,
    selectedComponentId,
    nodesInit,
    hasNodeCountChanged
  ])

  useEffect(() => {
    syncCanvasModel()
  }, [canvasModel, syncCanvasModel])

  return { canvasModel, syncCanvasModel }
}
