import { type FC } from 'react'
import { NodeToolbar, type NodeProps } from 'reactflow'

import { getIdFromReactFlowId } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/utils'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { unknownComponentIds } from 'job-lib/cisIds/knownComponentParameters'

import { ComponentNodeToolbar } from '../../ComponentNodeToolbar/ComponentNodeToolbar'
import { FlowNodeWrapper } from '../components/FlowNodeWrapper'
import { ComponentNode } from './ComponentNode'
import { type EtlNodeProps } from './models'

export const EtlNode: FC<NodeProps<EtlNodeProps>> = ({
  id,
  selected,
  data: {
    imageUrl,
    label,
    inputPorts,
    outputPorts,
    summaryId,
    hasOutputConnection,
    outputPortsConnected
  }
}) => {
  const { componentId } = useProjectInfo()
  const selectedInUrl = id === `component-${componentId?.toString()}`
  const isSelected = selected || selectedInUrl
  const componentInstanceId = getIdFromReactFlowId(id)
  const { enableComponentToolbar } = useFlags()

  return (
    <FlowNodeWrapper
      id={componentInstanceId}
      showPorts={isSelected}
      inputPorts={inputPorts}
      outputPorts={outputPorts}
      hasOutputConnection={hasOutputConnection}
      isSelected={isSelected}
      outputPortsConnected={outputPortsConnected}
      showOnlyConnectedOutputPorts={unknownComponentIds.includes(summaryId)}
    >
      <NodeToolbar isVisible={enableComponentToolbar && selectedInUrl}>
        <ComponentNodeToolbar
          summaryId={summaryId}
          componentInstanceId={Number(componentInstanceId)}
        />
      </NodeToolbar>

      <ComponentNode
        id={componentInstanceId}
        isSelected={isSelected}
        imageUrl={imageUrl}
        summaryId={summaryId}
      >
        {label}
      </ComponentNode>
    </FlowNodeWrapper>
  )
}
