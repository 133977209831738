import { useMemo } from 'react'

import { useAuth, useUser } from '@matillion/hub-client'
import axios from 'axios'

import config from 'config'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'

const useCopilotClient = () => {
  const { getToken } = useAuth()
  const { organisation } = useUser()
  const { data: agentDetails } = useAgentDetails()
  return useMemo(() => {
    const client = axios.create({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      baseURL: config.getCopilotApiBaseUrl(organisation.region),
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 120000
    })

    client.interceptors.request.use(async (interceptedConfig) => {
      const tokenOptions = {
        audience: config.apiTokenAudience
      }
      interceptedConfig.headers['account-id'] = organisation.id
      interceptedConfig.headers.Authorization = `Bearer ${await getToken(
        tokenOptions
      )}`
      interceptedConfig.headers['agent-version'] =
        agentDetails?.agentVersion ?? ''
      return interceptedConfig
    })
    return client
  }, [organisation.id, organisation.region, getToken, agentDetails])
}

export default useCopilotClient
