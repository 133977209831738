import {
  FileSystemProvider,
  GitApiContextProvider
} from '@matillion/git-component-library'
import { GitLibraryProvider } from 'GitLibraryProvider'

import config, { getMappedGitEnvironment } from 'config'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { CommandPalette } from 'modules/CommandPalette/CommandPalette'
import { ComponentValidationProvider } from 'modules/core/ComponentValidation'
import { LayoutProvider } from 'modules/core/EtlDesigner/Layouts/LayoutProvider'
import { PipelineAccessHistoryProvider } from 'modules/hub/PipelineAccessHistory/PipelineAccessHistoryProvider'

import { WorkingCopyProvider as DPLWorkingCopyProvider } from '../WorkingCopyProvider/WorkingCopyProvider'
import WorkingCopyProvider from './components/WorkingCopyProvider'
import { MainLayout } from './Layouts'

const EtlDesigner = () => {
  const { enableRecentlyAccessedPipelines, enableCommandPalette } = useFlags()
  const { branchId, projectId } = useProjectInfo()

  const layout = (
    <LayoutProvider>
      <MainLayout />
    </LayoutProvider>
  )

  return (
    <GitApiContextProvider
      branch={branchId}
      project={projectId}
      environment={getMappedGitEnvironment(config.environment)}
    >
      <GitLibraryProvider>
        <FileSystemProvider>
          <DPLWorkingCopyProvider>
            <WorkingCopyProvider>
              <ComponentValidationProvider>
                {enableCommandPalette && <CommandPalette />}
                {enableRecentlyAccessedPipelines ? (
                  <PipelineAccessHistoryProvider>
                    {layout}
                  </PipelineAccessHistoryProvider>
                ) : (
                  <>{layout}</>
                )}
              </ComponentValidationProvider>
            </WorkingCopyProvider>
          </DPLWorkingCopyProvider>
        </FileSystemProvider>
      </GitLibraryProvider>
    </GitApiContextProvider>
  )
}

export default EtlDesigner
