import { type ConnectorComponentMetadataResponse } from 'api/hooks/useGetComponentMetadata/types'

import { type Parameters } from 'job-lib/types/Job'

import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'

export const getCustomConnectorProfile = (
  parameterPath: string[],
  parameters: Parameters,
  getConnectorMetadata: (
    profile: string
  ) => ConnectorComponentMetadataResponse | undefined
) => {
  const profile = getParameterValue(parameters, parameterPath) as string
  const metadata = getConnectorMetadata(profile)
  return metadata?.additionalProperties?.connectorProfile
}
