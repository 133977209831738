import { type FunctionComponent } from 'react'

import {
  GitButton,
  GitFeatureFlagProvider,
  type DeploymentEnvironment
} from '@matillion/git-component-library'
import classNames from 'classnames'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import useGetProjectEnvironmentInfo from 'api/hooks/useGetProjectEnvironmentInfo/useGetProjectEnvironmentInfo'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import config from 'config'

import { useFlags } from 'hooks/useFlags'

import { CommandPaletteTrigger } from 'modules/CommandPalette/CommandPaletteTrigger'
import EnvironmentSelector from 'modules/ProjectToolbar/components/EnvironmentSelector'
import ProjectExplorerLink from 'modules/ProjectToolbar/components/ProjectExplorerLink'

import { BannerContent } from './components/BannerContent'
import { ConnectEnvironmentCTAContent } from './components/ConnectEnvironmentCTAContent'
import classes from './HeaderBar.module.scss'

const HeaderBar: FunctionComponent = () => {
  const { hasPermission } = useProjectPermission(
    'edit_project',
    PermissionType.PROJECT
  )
  const { data: projectEnvironmentData } = useGetProjectEnvironmentInfo()
  const { canIPublish } = useSharePipeline()
  const { enableConnectMyEnvironmentCta, enableCommandPalette } = useFlags()
  const isTrialWarehouse =
    projectEnvironmentData?.agentName === 'Matillion Hosted Agent' &&
    projectEnvironmentData.name === 'My first project-default' &&
    projectEnvironmentData.warehouseConnection.secretName === 'snowflaketrial'
  const showConnectEnvironmentCTA =
    enableConnectMyEnvironmentCta && hasPermission && isTrialWarehouse

  return (
    <div
      className={classNames(classes.HeaderBar, {
        [classes.HeaderBar_With_CTA]: showConnectEnvironmentCTA
      })}
    >
      <div className={classes.HeaderBar__Left}>
        <ProjectExplorerLink />
        <EnvironmentSelector />
      </div>

      <div className={classes.HeaderBar__Middle}>
        <GitFeatureFlagProvider
          environment={config.environment as DeploymentEnvironment}
        >
          <GitButton canIPublish={canIPublish} />
        </GitFeatureFlagProvider>
      </div>

      <div className={classes.HeaderBar__Right} data-testid="header-bar-right">
        <BannerContent>
          {showConnectEnvironmentCTA && <ConnectEnvironmentCTAContent />}
        </BannerContent>
        {enableCommandPalette && <CommandPaletteTrigger />}
      </div>
    </div>
  )
}

export default HeaderBar
