import {
  useCallback,
  useEffect,
  useMemo,
  type Dispatch,
  type SetStateAction
} from 'react'

import {
  type SampleComponentResponseColumns,
  type SampleComponentResponseRows
} from 'api/hooks/useSampleComponent/types'

import { columnWidths } from '../utils/columnWidths'
import { cleanupStyle, updateStyle } from '../utils/updateStyle'
import { type ColumnToResize } from '../VirtualisedSampleTable'
import { useGlobalCursor } from './useGlobalCursor'

export const MIN_COLUMN_WIDTH = 90
export const MAX_COLUMN_WIDTH = 300

export const generateCSS = ({
  headerClass,
  rowClass,
  gridTemplateColumnsValue
}: {
  headerClass: string
  rowClass: string
  gridTemplateColumnsValue: string
}) => {
  return `
  .${headerClass},
  .${rowClass} {
    grid-template-columns: ${gridTemplateColumnsValue};
  }
  `
}

export const generateGridColumns = () => {
  const widths = columnWidths.get()
  const gridTemplateColumnsValue = widths.reduce((acc, width, index) => {
    if (index === widths.length - 1) {
      return `${acc} minmax(${width?.toString()}px, 1fr)`
    }

    return `${acc} ${width?.toString()}px`
  }, '')

  return gridTemplateColumnsValue
}

export const measureTextValues = (textValues: string[]) => {
  const CELL_PADDING = 70
  const element = document.createElement('span')

  element.ariaHidden = 'true'
  element.style.position = 'absolute'
  element.style.top = '0'
  element.style.left = '-9999px'
  element.style.color = 'transparent'

  document.body.appendChild(element)

  const measuredValues = textValues.map((value) => {
    element.innerText = value

    const size = Math.ceil(element.getBoundingClientRect().width) + CELL_PADDING
    const smallestSize = Math.max(MIN_COLUMN_WIDTH, size)
    const largestSize = Math.min(MAX_COLUMN_WIDTH, smallestSize)
    return largestSize
  })

  document.body.removeChild(element)

  return measuredValues
}

export const useResizeEvents = ({
  metadata,
  rows,
  columnToResize,
  setColumnToResize,
  headerClass,
  rowClass
}: {
  metadata: SampleComponentResponseColumns[]
  rows: SampleComponentResponseRows[]
  columnToResize: ColumnToResize | null
  setColumnToResize: Dispatch<SetStateAction<ColumnToResize | null>>
  headerClass: string
  rowClass: string
}) => {
  useGlobalCursor(!!columnToResize)

  const columnSizes = useMemo(() => {
    const largestColumnData = rows.reduce<string[]>((acc, row, index) => {
      if (!row.values || row.values.length === 0) {
        return acc
      }

      if (index === 0) {
        return row.values.map((value) => value ?? '')
      }

      const columnData = [...acc]

      row.values.forEach((value, _index) => {
        const currentLargestColumnValue = columnData[_index] ?? ''
        const columnValue = value ?? ''
        const valueSize = columnValue.toString().length

        columnData[_index] =
          currentLargestColumnValue.length > valueSize
            ? currentLargestColumnValue
            : columnValue
      })

      return columnData
    }, [])

    largestColumnData.forEach((value, index) => {
      const newlargestColumnValue =
        metadata[index].name.length > value.length
          ? metadata[index].name
          : value

      largestColumnData[index] = newlargestColumnValue
    })

    const measuredValues = measureTextValues(largestColumnData)

    return measuredValues
  }, [metadata, rows])

  const setStyle = useCallback(() => {
    const gridTemplateColumnsValue = generateGridColumns()
    const style = generateCSS({
      headerClass,
      rowClass,
      gridTemplateColumnsValue
    })

    updateStyle('table', style)
  }, [headerClass, rowClass])

  useEffect(() => {
    return () => {
      columnWidths.reset()
      cleanupStyle()
    }
  }, [])

  useEffect(() => {
    if (!columnToResize) {
      return
    }

    const onMouseMove = (e: globalThis.MouseEvent) => {
      const widths = columnWidths.get()
      const currentColumn = document
        .getElementById(columnToResize.columnId)
        ?.getBoundingClientRect()

      /* istanbul ignore else */
      if (currentColumn) {
        const sizeDifference = e.clientX - currentColumn?.right

        widths[columnToResize.columnIndex] = Math.max(
          MIN_COLUMN_WIDTH,
          widths[columnToResize.columnIndex] + sizeDifference
        )

        columnWidths.set(widths)
        setStyle()
      }
    }

    const onMouseUp = () => {
      /* istanbul ignore else */
      if (columnToResize) {
        setColumnToResize(null)
      }
    }
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [columnToResize, setColumnToResize, setStyle])

  if (columnWidths.get().length === 0) {
    columnWidths.set(columnSizes)
    setStyle()
  }
}
