import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import axios from 'axios'

import useGetProject from 'api/hooks/useGetProject/useGetProject'
import { usePublishJobs } from 'api/hooks/usePublishJobs'
import { type ProblemDetails } from 'api/types/http-problem-details'

const usePublishJobsAction = (
  branchName?: string,
  environmentId?: string,
  version?: string
) => {
  const [loading, setLoading] = useState(false)
  const { doPublish } = useDoPublishJobs()

  const publish = useCallback(
    async (commitId: string) => {
      if (!loading && environmentId && branchName && version) {
        setLoading(true)

        await doPublish({
          commitId,
          branch: branchName,
          environment: environmentId,
          version
        })

        setLoading(false)
      }
    },
    [branchName, doPublish, environmentId, loading, version]
  )

  return { publish, loading }
}

interface DoPublishArgs {
  branch: string
  commitId: string
  environment: string
  version: string
}

export const useDoPublishJobs = () => {
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''

  const { mutateAsync: publish } = usePublishJobs(warehouse)

  const { t } = useTranslation('translation', {
    keyPrefix: 'publish.toast'
  })
  const { makeToast } = Toaster.useToaster()

  return {
    doPublish: async ({
      branch,
      environment,
      commitId,
      version
    }: DoPublishArgs) => {
      return publish({
        commitId,
        branchName: branch,
        environmentId: environment,
        version
      })
        .then(() => {
          makeToast({
            type: 'success',
            title: t('success.title'),
            message: t('success.message')
          })
        })
        .catch((error: unknown) => {
          if (
            axios.isAxiosError<ProblemDetails>(error) &&
            error.response?.data.title &&
            error.response?.data.detail
          ) {
            makeToast({
              type: 'error',
              title: error.response?.data.title,
              message: error.response?.data.detail
            })
          } else {
            makeToast({
              type: 'error',
              title: t('error.title'),
              message: t('error.message')
            })
          }
        })
    }
  }
}

export default usePublishJobsAction
