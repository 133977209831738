import { useEffect, useMemo, type FC, type HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'

import { type TreeExpandedState } from '@matillion/component-library'
import { FileType, useFiles } from '@matillion/git-component-library'
import classnames from 'classnames'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'
import { useFetchSharedPipelinesConfig } from 'api/hooks/useSharePipeline/useSharePipeline'

import { Loading } from 'components/Loading/Loading'
import { ErrorPanel } from 'components/Panels/ErrorPanel'

import { useFlags } from 'hooks/useFlags'
import { usePipelines } from 'hooks/usePipelines/usePipelines'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { JobType } from 'job-lib/types/JobType'

import { EmptyFileBrowserList } from './components/EmptyFileBrowserList/EmptyFileBrowserList'
import FileBrowserToolBar from './components/FileBrowserToolBar/FileBrowserToolBar'
import { FileBrowserTree } from './components/FileBrowserTree/FileBrowserTree'
import { PipelineBrowserCommandProvider } from './context/PipelineBrowserCommandProvider'
import classes from './FileBrowser.module.scss'
import { useExperimentMyFirstPipeline } from './hooks/useExperimentMyFirstPipeline'

export interface FileBrowserProps extends HTMLProps<HTMLDivElement> {
  pipelines: JobSummary[]
  isLoading?: boolean
  isError?: boolean
  onToggle?: (nodeId: string, isExpanded: boolean) => void
  expandedItems?: TreeExpandedState
}

const useFileBrowserItems = () => {
  const { enableHighCode } = useFlags()

  const highCodeFiles = useFiles()
  const pipelineFiles = usePipelines()

  return enableHighCode ? highCodeFiles : pipelineFiles
}

const BaseFileBrowser: FC<FileBrowserProps> = ({
  pipelines,
  isLoading,
  isError,
  onToggle,
  expandedItems,
  ...props
}) => {
  const { t } = useTranslation()
  const { invalidateStaleSelectedJobs } = useSelectedJobs()
  useFetchSharedPipelinesConfig()

  useEffect(() => {
    if (isLoading || isError || !pipelines) {
      return
    }

    invalidateStaleSelectedJobs(pipelines.map((summary) => summary.jobId))
  }, [pipelines, invalidateStaleSelectedJobs, isLoading, isError])

  return (
    <PipelineBrowserCommandProvider>
      {(hasFolderTree) => (
        <div
          className={classes.PipelineBrowser}
          aria-live="polite"
          aria-busy={isLoading}
          {...props}
        >
          <div
            className={classnames(
              classes.PipelineBrowser__Header,
              classes.PipelineBrowser__WizardHeader
            )}
            data-testid="section-jobs-header"
          >
            <FileBrowserToolBar />
          </div>

          {isLoading && (
            <Loading className={classes.PipelineBrowser__Loading} />
          )}

          {isError && <ErrorPanel text={t('sideBar.jobPanel.networkError')} />}

          <div className={classes.PipelineBrowser__ListWrapper}>
            <div className={classes.PipelineBrowser__Content}>
              {pipelines && (pipelines.length > 0 || hasFolderTree) ? (
                <FileBrowserTree
                  jobs={pipelines}
                  onToggle={onToggle}
                  expandedItems={expandedItems}
                />
              ) : (
                <EmptyFileBrowserList />
              )}
            </div>
          </div>
        </div>
      )}
    </PipelineBrowserCommandProvider>
  )
}

export const FileBrowser = (
  props: Omit<FileBrowserProps, 'pipelines' | 'isLoading' | 'isError'>
) => {
  const { files, isLoading, isError } = useFileBrowserItems()
  const { isLoading: isCreatingPipeline } = useExperimentMyFirstPipeline(files)

  const pipelines: JobSummary[] = useMemo(
    () =>
      Object.values(files).map((file) => {
        /*
         * TODO: when FileBrowser becomes the default, we
         * should remove this remapping of name and jobId,
         * in favour of the FileInfo type interface from
         * the FileSystemProvider module.
         */
        return {
          ...file,
          name: file.fileName,
          displayName: file.displayName,
          jobId: file.name,
          type:
            file.type === FileType.ORCHESTRATION_PIPELINE
              ? JobType.Orchestration
              : JobType.Transformation,
          fileType: file.type ?? FileType.UNKNOWN,
          extension: file.extension,
          class: file.class
        }
      }),
    [files]
  )

  return (
    <BaseFileBrowser
      pipelines={pipelines}
      isLoading={isLoading || isCreatingPipeline}
      isError={isError}
      {...props}
    />
  )
}
