import { useQueries, type UseQueryOptions } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

import useEosClient from 'api/hooks/useEosClient/useEosClient'

import { type ActivityItem } from '../useEosClient/types/eos'
import { getRootPipelineActivity } from '../useGetRootPipelineActivity/__handlers__/getRootPipelineActivity'

export const useGetRootPipelineActivities = (
  pipelineIds: string[],
  options?: UseQueryOptions<ActivityItem | undefined, AxiosError>
) => {
  const client = useEosClient()

  const queryResults = useQueries({
    queries: pipelineIds.map((pipelineId) => ({
      queryKey: ['rootPipelineActivity', pipelineId],
      queryFn: async () => getRootPipelineActivity(client, pipelineId),
      ...options
    }))
  })

  return queryResults
}
