import {
  useCallback,
  useMemo,
  useState,
  type MouseEvent,
  type ReactElement,
  type ReactNode
} from 'react'
import { type PopoverProps } from 'react-tiny-popover'

import { Popover } from '@matillion/component-library'
import classnames from 'classnames'

import { PopOverContext, type OnContextMenuEvent } from './PopOverContext'
import classes from './PopOverMenu.module.scss'

export declare type PopOverPosition = 'left' | 'right' | 'bottom' | 'top'

export type RenderChildren = ({
  open,
  onClick,
  onContextMenu
}: {
  open: boolean
  onClick: () => void
  onContextMenu: (e: MouseEvent) => void
}) => ReactElement

export interface RenderContentProps {
  popOverClientPosition: PosXy | null
  data: unknown
}

export type RenderPopOverContent = ({
  popOverClientPosition,
  data
}: RenderContentProps) => ReactNode

export interface PopOverMenuProps {
  positionAtMouse?: boolean
  content: ReactNode | RenderPopOverContent
  children: RenderChildren
  position?: PopOverPosition | PopOverPosition[]
  className?: string
  boundaryElement?: HTMLElement
}

export interface PosXy {
  x: number
  y: number
}

export const PopOverMenu = ({
  positionAtMouse = false,
  content,
  children,
  position,
  className,
  boundaryElement
}: PopOverMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [{ x, y }, setXy] = useState<PosXy>({ x: 400, y: 400 })
  const [popOverClientPosition, setPopOverClientPosition] =
    useState<PosXy | null>(null)

  const [data, setData] = useState<unknown>(null)

  const onContextMenu = useCallback(
    (e: OnContextMenuEvent, newData: unknown = null) => {
      e.preventDefault()
      e.stopPropagation()
      setData(newData)
      setXy({ x: e.pageX, y: e.pageY })
      setPopOverClientPosition({
        x: e.clientX,
        y: e.clientY
      })
      setIsOpen(true)
    },
    []
  )
  const child = children({
    open: isOpen,
    onClick: () => {
      setIsOpen((prevState) => !prevState)
    },
    onContextMenu
  })
  const contentLocation: PopoverProps['contentLocation'] = ({
    childRect,
    popoverRect,
    parentRect,
    boundaryRect,
    padding,
    align
  }) => {
    if (!boundaryElement || !positionAtMouse) {
      return { top: y, left: x }
    }

    let newX = x
    let newY = y
    // Check if the popover is going off the right side of the boundary element
    if (x + popoverRect.width > boundaryRect.right) {
      newX = boundaryRect.right - popoverRect.width - padding
    }

    // Check if the popover is going off the bottom of the boundary element
    if (y + popoverRect.height > boundaryRect.bottom) {
      newY = boundaryRect.bottom - popoverRect.height - padding
    }

    // Return the adjusted top and left values for the transform.
    // Since the popover always opens to the right and below the cursor, this is all we need
    // to check
    return { top: newY, left: newX }
  }
  const conditionalPopOverProps = positionAtMouse
    ? {
        contentLocation,
        anchor: <div className="u-visually-hidden" />
      }
    : {
        anchor: child
      }

  return (
    <PopOverContext.Provider
      value={useMemo(() => {
        return { setIsOpen, onContextMenu }
      }, [onContextMenu])}
    >
      <Popover
        className={classnames(classes.PopOver, className)}
        align="start"
        onClickOutside={() => {
          setIsOpen(false)
        }}
        reposition={true}
        position={position ?? ['right', 'top', 'bottom']}
        isOpen={isOpen}
        boundaryElement={boundaryElement}
        {...conditionalPopOverProps}
      >
        <ul data-testid="popover-menu" className={classes.PopOver__Content}>
          {typeof content === 'function'
            ? content({ popOverClientPosition, data })
            : content}
        </ul>
      </Popover>
      {positionAtMouse && child}
    </PopOverContext.Provider>
  )
}
