import { type PropsWithChildren } from 'react'

import { DesignerApiClientContext } from './DesignerApiClientContext'
import { useDesignerApiClient } from './useDesignerApiClient'

const DesignerApiClientProvider = ({ children }: PropsWithChildren) => {
  const client = useDesignerApiClient()

  return (
    <DesignerApiClientContext.Provider value={client}>
      {children}
    </DesignerApiClientContext.Provider>
  )
}

export default DesignerApiClientProvider
