import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type KeyboardEvent
} from 'react'
import { useTranslation } from 'react-i18next'

import { SearchBar } from '@matillion/component-library'
import { FileType } from '@matillion/git-component-library'
import { usePendo, useServiceUrl } from '@matillion/hub-client'
import { useQueryClient } from '@tanstack/react-query'

import { FilterGroup } from 'components/FilterGroup/FilterGroup'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { searchComponentList } from 'hooks/useAvailableComponents'
import { type ExtendedProps } from 'hooks/useAvailableComponents/useAvailableComponents'
import { useFlags } from 'hooks/useFlags'

import { isCustomConnector, isFlexConnector } from 'job-lib/cisIds/idType'

import componentSummariesJson from 'locales/en/component-summaries'

import { track } from 'utils/heap'

import { useActivePipelineSummary } from '../../../../hooks/useActivePipelineSummary/useActivePipelineSummary'
import { ComponentSummaryListItem } from '../ComponentSummaryListItem/ComponentSummaryListItem'
import { CustomConnectorCTA } from '../CustomConnectorCTA/CustomConnectorCTA'
import classes from './ComponentSummaryList.module.scss'

interface ComponentSummaryListProps {
  componentSummaries: ExtendedProps[]
  onSelectComponent?: (id: string, name?: string) => Promise<void>
  isDraggable: boolean
}

export interface ComponentDisplay {
  [componentId: string]: {
    displayName: string
    tags: string[]
    synonyms: string[]
    filter: string[]
  }
}
const sortAlphabetically = (a: string, b: string) => a.localeCompare(b)
export const componentDisplayProperties =
  componentSummariesJson as ComponentDisplay

export const getUniqueFilters = (
  componentSummaries: ExtendedProps[],
  componentProperties: ComponentDisplay
) => {
  const filters = new Set<string>()

  componentSummaries.forEach(({ componentId }) => {
    if (isFlexConnector(componentId) || isCustomConnector(componentId)) {
      return filters.add('$t(filter.connector)')
    }

    const component = componentProperties[componentId]
    const componentFilter = component?.filter
    if (componentFilter) {
      componentFilter.forEach((filter: string) => {
        filters.add(filter)
      })
    }
  })

  return Array.from(filters).sort(sortAlphabetically)
}

export const ComponentSummaryList = ({
  componentSummaries,
  onSelectComponent,
  isDraggable
}: ComponentSummaryListProps) => {
  const [selectedComponentIndex, setSelectedComponentIndex] = useState<
    number | null
  >(null)
  const { t } = useTranslation()
  const { enableAddCustomConnectorCta } = useFlags()
  const pendo = usePendo()

  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const filterOptions = useMemo(
    () => getUniqueFilters(componentSummaries, componentDisplayProperties),
    [componentSummaries]
  )

  const [searchTerm, setSearchTerm] = useState('')
  const searchRef = useRef<HTMLInputElement>(null)

  const inAddComponentContext = Boolean(onSelectComponent)
  useEffect(() => {
    if (inAddComponentContext && searchRef.current) {
      setTimeout(() => {
        searchRef.current?.focus()
      })
    }
  }, [inAddComponentContext])

  const translateFilterOptions = useCallback(
    (options: string[]) => {
      if (options.length === 0) {
        return []
      }
      return options.map((filter) => t(`componentSummaries:${t(filter)}`))
    },
    [t]
  )

  const filteredComponentSummaries = useMemo(() => {
    const translatedFilterOptions = translateFilterOptions(filterOptions)
    const activeFilters = selectedFilters.filter((filter) =>
      translatedFilterOptions.includes(filter)
    )

    if (activeFilters.length === 0) {
      return componentSummaries
    } else {
      return componentSummaries.filter(({ componentId }) => {
        if (isFlexConnector(componentId) || isCustomConnector(componentId)) {
          return activeFilters.includes('Connector')
        }

        const component = componentDisplayProperties[componentId]

        if (!component) {
          return false
        }

        const filters = component?.filter ?? []
        const translatedFilters = translateFilterOptions(filters)

        return translatedFilters.some((filter) =>
          activeFilters.includes(filter)
        )
      })
    }
  }, [
    componentSummaries,
    filterOptions,
    selectedFilters,
    translateFilterOptions
  ])

  const handleFilterChange = (category: string) => {
    setSelectedComponentIndex(null)

    if (selectedFilters.includes(category)) {
      setSelectedFilters(
        selectedFilters.filter((filter) => filter !== category)
      )
    } else {
      setSelectedFilters([...selectedFilters, category])
    }
  }

  const returnedComponentSummaries = useMemo(
    () => searchComponentList(searchTerm, filteredComponentSummaries),
    [searchTerm, filteredComponentSummaries]
  )

  useEffect(() => {
    if (!returnedComponentSummaries.length) {
      track('etld_add-component-result-search-term', { searchTerm })
      pendo.track('etld_add-component-result-search-term', { searchTerm })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnedComponentSummaries, pendo])

  const getOnKeyDown = (index: number) => {
    const maxIndex = returnedComponentSummaries.length - 1
    const isLastComponent = index === maxIndex
    const isFirstComponent = index === 0

    const getNextComponent = () => {
      if (isLastComponent) {
        return 0
      }

      return index + 1
    }

    const getPrevComponent = () => {
      if (isFirstComponent) {
        return maxIndex
      }

      return index - 1
    }

    return (e: KeyboardEvent) => {
      e.stopPropagation()

      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        e.preventDefault()
        setSelectedComponentIndex(getNextComponent())
      }

      if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
        e.preventDefault()
        setSelectedComponentIndex(getPrevComponent())
      }

      if (e.key === 'Home') {
        setSelectedComponentIndex(0)
      }

      if (e.key === 'End') {
        setSelectedComponentIndex(maxIndex)
      }
    }
  }

  const customConnectorUrl = useServiceUrl('cyoc-frontend', true)
  const { pipelineSummary: activePipelineSummary } = useActivePipelineSummary()
  const shouldShowCustomConnectorBtn = Boolean(
    enableAddCustomConnectorCta &&
      activePipelineSummary?.type === FileType.ORCHESTRATION_PIPELINE &&
      customConnectorUrl
  )

  const queryClient = useQueryClient()

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        queryClient.invalidateQueries({
          queryKey: ['connectorComponentSummaries']
        })
      }
    }

    window.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [queryClient])

  return (
    <div
      className={classes.ComponentSummaryList}
      data-testid="component-summary-list"
    >
      <div className={classes.ComponentSummaryList__Search}>
        <SearchBar
          ref={searchRef}
          data-testid="add-components-search"
          value={searchTerm}
          onChange={({ target: { value } }) => {
            setSelectedComponentIndex(null)
            setSearchTerm(value)
          }}
          aria-label={t('sideBar.componentPanel.searchPlaceholder')}
          placeholder={t('sideBar.componentPanel.searchPlaceholder', {
            searchableItem: ''
          })}
          onBlur={() => {
            if (returnedComponentSummaries.length && searchTerm) {
              track('etld_add-component-result-search-term', { searchTerm })
              pendo.track('etld_add-component-result-search-term', {
                searchTerm
              })
            }
          }}
        />
      </div>
      <div className={classes.ComponentSummaryList__FilterGroupWrapper}>
        <FilterGroup
          options={translateFilterOptions(filterOptions)}
          selectedOptions={translateFilterOptions(selectedFilters)}
          onChange={handleFilterChange}
        />
      </div>
      <span id="gridLabel" className="u-visually-hidden">
        {t('sideBar.componentPanel.availableComponents')}
      </span>
      {!returnedComponentSummaries.length ? (
        <>
          <EmptyPanel
            className={
              shouldShowCustomConnectorBtn
                ? classes.ComponentSummaryList__EmptyPanel
                : undefined
            }
          >
            {t('sideBar.componentPanel.noResultsFound')}
          </EmptyPanel>
          {shouldShowCustomConnectorBtn && (
            <CustomConnectorCTA inAddComponentContext={inAddComponentContext} />
          )}
        </>
      ) : (
        <div
          role="grid"
          aria-labelledby="gridLabel"
          className={classes.ComponentSummaryList__Components}
        >
          {returnedComponentSummaries.map((component, index) => (
            <ComponentSummaryListItem
              // Now we are reusing component ids for pseudo components, this needs to be unique
              key={`${component.componentId}-${component.displayName}`}
              componentIndex={index}
              selectedComponentIndex={selectedComponentIndex}
              onKeyDown={getOnKeyDown(index)}
              component={component}
              displayName={component.displayName}
              description={component.description}
              searchTerm={searchTerm}
              onSelectComponent={async () => {
                return onSelectComponent?.(
                  component.componentId,
                  component.displayName
                )
              }}
              isDraggable={isDraggable}
            />
          ))}
        </div>
      )}
      {shouldShowCustomConnectorBtn &&
        returnedComponentSummaries.length > 0 &&
        searchTerm.length > 0 && (
          <CustomConnectorCTA inAddComponentContext={inAddComponentContext} />
        )}
    </div>
  )
}
