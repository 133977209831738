import { useCallback, useMemo, type PropsWithChildren } from 'react'

import {
  GitContextProvider,
  GitEventProvider,
  type WorkingTreeRefreshEvent
} from '@matillion/git-component-library'

import useListBranches from './api/hooks/useListBranches'
import { useListEnvironments } from './api/hooks/useListEnvironments'
import { useFetchSharedPipelinesConfig } from './api/hooks/useSharePipeline/useSharePipeline'
import { useDoPublishJobs } from './hooks/usePublishJobsAction/usePublishJobsAction'
import useRefreshPipelines from './modules/core/EtlDesigner/hooks/useRefreshPipelines'

export const GitLibraryProvider = ({ children }: PropsWithChildren) => {
  const { data: environmentsResponse } = useListEnvironments()
  const { refreshPipelines } = useRefreshPipelines()
  const { doPublish } = useDoPublishJobs()
  const { refetch: refetchSharedPipelinesConfig } =
    useFetchSharedPipelinesConfig(false)

  const branchesQueryResult = useListBranches()

  const environments = useMemo(
    () =>
      environmentsResponse?.results.map((response) => ({
        id: response.id,
        name: response.name
      })) ?? [],
    [environmentsResponse?.results]
  )

  /**
   * When a server-side WTS state mutation occurs, we currently
   * must refresh pipelines to ensure the file-browser reflects
   * the current state of the server-side local repo in the WTS.
   *
   * If both events in {@link WorkingTreeRefreshEvent} are true,
   * it means either a commit, pull, merge or hard reset was performed.
   * In these cases we need to refresh the shared pipelines config contents.
   */
  const handleWTSRefresh = useCallback(
    async (e: WorkingTreeRefreshEvent) => {
      await refreshPipelines(e)

      if (e.refreshFileContents && e.refreshFileSummaries) {
        await refetchSharedPipelinesConfig()
      }
    },
    [refreshPipelines, refetchSharedPipelinesConfig]
  )

  return (
    <GitEventProvider
      onRefreshWorkingTreeStatus={handleWTSRefresh}
      onPublishPipelines={doPublish}
    >
      <GitContextProvider
        environments={environments}
        branchesQueryResult={branchesQueryResult}
      >
        {children}
      </GitContextProvider>
    </GitEventProvider>
  )
}
