import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FileClassification, FileType } from '@matillion/git-component-library'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useExportPipelines } from 'api/hooks/useExportPipelines/useExportPipelines'
import type { JobSummary } from 'api/hooks/useGetJobSummaries'
import { useSharePipeline } from 'api/hooks/useSharePipeline/useSharePipeline'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { FileBrowserCommand } from 'modules/FileBrowser/context/types'
import { usePipelineBrowserCommandProvider } from 'modules/FileBrowser/effects/usePipelineBrowserCommand'

import { FileTreeReducerType } from '../../../reducers/fileTreeReducer/types'

export interface FileItemContextMenuProps {
  file: JobSummary
  path?: string
}

const FileItemContextMenu: FC<FileItemContextMenuProps> = ({ file, path }) => {
  const { navigateToJob } = useSelectedJobs()
  const { enableImportExportPipelines, enableSharedPipelines } = useFlags()
  const { branchId } = useProjectInfo()
  const { projectName } = useProjectNames()
  const { onCommand, onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const { hasPermission: canDelete } = useProjectPermission(
    'delete_pipelines',
    PermissionType.PROJECT
  )
  const { t } = useTranslation()

  const { mutate: exportPipelines } = useExportPipelines('pipeline')
  const { checkPipelineShared } = useSharePipeline()

  const handleRenamePipeline = () => {
    onCommand({
      type: FileBrowserCommand.RENAME_FILE,
      payload: file
    })

    onFileTreeCommand({
      type: FileTreeReducerType.SET_FOLDER_PATH,
      fileDetails: { path }
    })
  }

  const handleExportFiles = () => {
    exportPipelines({
      paths: [file.jobId],
      fileName: `${projectName}_${branchId}_${file.jobId.replace(
        /\//g,
        '_'
      )}_${new Date().toISOString()}.zip`
    })
  }

  const canSharePipeline = !checkPipelineShared(file.jobId)
  const fileClass = file.class ?? FileClassification.CLASS_1
  const isPipeline = file.fileType
    ? file.fileType === FileType.ORCHESTRATION_PIPELINE ||
      file.fileType === FileType.TRANSFORMATION_PIPELINE
    : true

  return (
    <PopOverSection>
      {fileClass <= FileClassification.CLASS_2 && (
        <PopOverItem
          onClick={() => {
            navigateToJob(file.jobId)
          }}
          data-testid="file-context-menu-open"
          shortcut={t('shortcuts.enter')}
        >
          {t('translation:jobContextMenu.openJob')}
        </PopOverItem>
      )}

      <PopOverItem
        data-testid="job-context-menu-rename-job"
        onClick={handleRenamePipeline}
      >
        {t('translation:jobContextMenu.renameJob')}
      </PopOverItem>

      <PopOverItem
        disabled={!canDelete}
        onClick={() => {
          onCommand({
            type: FileBrowserCommand.DELETE_FILE,
            payload: file
          })
        }}
        data-testid="job-context-menu-delete-job"
      >
        {t('translation:jobContextMenu.deleteJob')}
      </PopOverItem>

      {enableImportExportPipelines && (
        <PopOverItem
          data-testid="file-context-menu-export"
          onClick={() => {
            handleExportFiles()
          }}
        >
          {t('translation:jobContextMenu.exportPipeline.title')}
        </PopOverItem>
      )}

      {enableSharedPipelines && canSharePipeline && isPipeline && (
        <PopOverItem
          data-testid="file-context-menu-share"
          onClick={() => {
            onCommand({
              type: FileBrowserCommand.SHARE_PIPELINE,
              payload: file
            })
          }}
        >
          {t('translation:jobContextMenu.sharePipeline.title')}
        </PopOverItem>
      )}

      {enableSharedPipelines && !canSharePipeline && isPipeline && (
        <PopOverItem
          data-testid="file-context-menu-unshare"
          onClick={() => {
            onCommand({
              type: FileBrowserCommand.UNSHARE_PIPELINE,
              payload: file
            })
          }}
        >
          {t('translation:jobContextMenu.unsharePipeline.title')}
        </PopOverItem>
      )}
    </PopOverSection>
  )
}

export default FileItemContextMenu
