import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'
import {
  type ColumnType,
  type EditorColumn
} from 'api/hooks/useGetParameterOptions/types'

import {
  type ElementCollection,
  type ValueCollection
} from 'job-lib/types/Parameters'

export interface ValueAndTypes {
  [name: string]: {
    value: string | null
    type: ColumnType
  }
}

enum Columns {
  SOURCE_COLUMNS = 'sourceColumns',
  TARGET_COLUMNS = 'targetColumns'
}

const getDefaultValue = (editorColumn: EditorColumn) => {
  switch (editorColumn.name) {
    case 'Clean Staged Files':
      return 'On'
    case 'Recreate Target Table':
      return 'On'
    case 'Trim String Columns':
      return 'On'
    case 'String Null is Null':
      return 'Off'
    case 'File Prefix':
      return ''
    case 'Compression type':
      return 'Gzip'
    default:
      return editorColumn.defaultValue
  }
}

const getDefaultListValues = (
  editorColumns: EditorColumn[],
  elements: ElementCollection
): ValueAndTypes => {
  const initialValues: ValueAndTypes = {}

  editorColumns.forEach((column, index) => {
    if (column.name && elements?.[index + 1]?.values?.['1']?.value) {
      initialValues[column.name] = {
        value: elements[index + 1].values['1'].value,
        type: column.type
      }
      return
    }

    const value = getDefaultValue(column)

    if (!column.name) {
      return
    }

    initialValues[column.name] = {
      value,
      type: column.type
    }
  })

  return initialValues
}

const getDefaultGridValues = (
  editorColumns: EditorColumn[],
  elements: ElementCollection
): ValueAndTypes => {
  const initialValues: ValueAndTypes = {}

  const sourceColumn = editorColumns.find(
    (c) => c.resourceID === Columns.SOURCE_COLUMNS
  ) as EditorColumn

  const targetColumns =
    editorColumns.find((c) => c.resourceID === Columns.TARGET_COLUMNS)
      ?.options ?? []
  targetColumns.forEach((column, index) => {
    if (column && column === elements?.[index + 1]?.values?.['2'].value) {
      initialValues[column] = {
        value: elements[index + 1].values['1'].value,
        type: sourceColumn?.type
      }
    }
  })

  return initialValues
}

export const getDefaultValues = (
  parameterDataType: ParameterDataType,
  editorColumns: EditorColumn[],
  elements: ElementCollection
) => {
  switch (parameterDataType) {
    case ParameterDataType.GRID:
      return getDefaultGridValues(editorColumns, elements)
    default:
      return getDefaultListValues(editorColumns, elements)
  }
}

function convertToListElements(entries: ValueAndTypes): ElementCollection {
  const asValues: ValueCollection[] = Object.values(entries).map((entry) => {
    return {
      1: {
        slot: 1,
        value: entry.value ?? '',
        type: 'STRING',
        dataType: entry.type as ParameterDataType
      }
    }
  })

  const elementsCollection: ElementCollection = {}

  asValues.forEach((values, index) => {
    const slot = index + 1

    elementsCollection[slot] = {
      slot,
      values
    }
  })

  return elementsCollection
}

function convertToGridElements(entries: ValueAndTypes): ElementCollection {
  const elementsCollection: ElementCollection = {}

  Object.keys(entries).forEach((key: string, index: number) => {
    const entry = entries[key]
    const values = {
      1: {
        slot: 1,
        value: entry.value ?? '',
        type: 'STRING',
        dataType: entry.type as ParameterDataType
      },
      2: {
        slot: 2,
        value: key,
        type: 'STRING',
        dataType: entry.type as ParameterDataType
      }
    } as const

    const slot = index + 1
    elementsCollection[slot] = {
      slot,
      values
    }
  })

  return elementsCollection
}

export const convertToElements = (
  parameterDataType: ParameterDataType,
  entries: ValueAndTypes
) => {
  switch (parameterDataType) {
    case ParameterDataType.GRID:
      return convertToGridElements(entries)
    default:
      return convertToListElements(entries)
  }
}

export const getColumns = (
  parameterDataType: ParameterDataType,
  editorColumns: EditorColumn[]
) => {
  if (parameterDataType !== ParameterDataType.GRID) {
    return editorColumns
  }

  const sourceColumn = editorColumns.find(
    (c) => c.resourceID === Columns.SOURCE_COLUMNS
  ) as EditorColumn

  const targetColumns =
    editorColumns.find((c) => c.resourceID === Columns.TARGET_COLUMNS)
      ?.options ?? []

  return targetColumns.map((columnName) => {
    const column: EditorColumn = {
      name: columnName,
      type: sourceColumn.type,
      resourceID: sourceColumn?.resourceID,
      options: sourceColumn?.options ?? [],
      columnEditorType: sourceColumn?.columnEditorType ?? null,
      lookupType: sourceColumn?.lookupType ?? null,
      defaultValue: sourceColumn?.defaultValue ?? null,
      lookupDependencies: sourceColumn?.lookupDependencies ?? null
    }
    return column
  })
}
