import { createContext } from 'react'

import { noop } from 'lodash'

import { getDefaultSampleLimitItem } from 'modules/ComponentProperties/components/SampleComponent/utils/utils'

import {
  type UserPreference,
  type UserPreferenceProviderContextInterface
} from './types'

export const userPreferenceInitialValue: UserPreference = {
  snapToGridEnabled: true,
  sampleLimit: getDefaultSampleLimitItem()
}

export const UserPreferenceProviderContext =
  createContext<UserPreferenceProviderContextInterface>({
    userPreference: userPreferenceInitialValue,
    setUserPreference: noop,
    setSpecificUserPreference: noop
  })
