import React, { useRef } from 'react'

import { Popover, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { Loading } from 'components/Loading/Loading'

import classes from './MonacoAutocomplete.module.scss'
import { type MonacoAutocompleteItem } from './MonacoAutocompleteProvider'

interface MonacoAutocompleteProps {
  loading: boolean
  suggestions: MonacoAutocompleteItem[]
  position: {
    top: number
    left: number
  }
  onSelect: (suggestion: string) => void
  open: boolean
  onClose: () => void
  selectedIndex: number | undefined
  highlight: string | undefined
}

export const MonacoAutocomplete: React.FC<MonacoAutocompleteProps> = ({
  loading,
  suggestions,
  position,
  onSelect,
  open,
  onClose,
  selectedIndex,
  highlight
}) => {
  const autocompleteRef = useRef<HTMLUListElement>(null)

  // useLayoutEffect(() => {
  //   if (open && autocompleteRef.current) {
  //     console.log(autocompleteRef.current.firstChild)
  //     const fc = autocompleteRef.current.firstChild as HTMLElement
  //     if (fc) {
  //       console.log(fc)
  //       autocompleteRef.current?.focus()
  //     }
  //   }
  // }, [open, loading, suggestions])

  return (
    <Popover
      onClickOutside={onClose}
      containerClassName={classes.Popover}
      isOpen={open}
      anchor={
        <div
          className={classes.Anchor}
          style={{
            top: position.top + 90,
            left: position.left + 20
          }}
        />
      }
      align="start"
      position={'bottom'}
    >
      <div className={classes.MonacoAutocomplete}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {suggestions.length === 0 && (
              <Typography className={classes.NotFound}>
                No suggestions found
              </Typography>
            )}
            <ul ref={autocompleteRef} className={classes.List}>
              {suggestions.map((suggestion, index) => {
                return (
                  <li
                    tabIndex={1}
                    key={index}
                    className={classNames(
                      classes.ListItem,
                      selectedIndex === index && classes.ListItemSelected
                    )}
                    onClick={() => {
                      onSelect(suggestion.name)
                    }}
                  >
                    <div className={classes.SuggestionContainer}>
                      {getText(suggestion.name, highlight)}
                      <Typography
                        className={classNames(
                          classes.SuggestionType,
                          'u-truncate-text'
                        )}
                      >
                        {suggestion.type}
                      </Typography>
                    </div>
                    {suggestion.description && (
                      <div className={classes.DescriptionContainer}>
                        <Typography
                          className={classNames(
                            classes.Description,
                            'u-truncate-text-2-lines'
                          )}
                        >
                          {suggestion.description}
                        </Typography>
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          </>
        )}
      </div>
    </Popover>
  )
}

function getText(text: string, highlight: string | undefined) {
  if (highlight === undefined) {
    return (
      <Typography className={classNames(classes.Suggestion, 'u-truncate-text')}>
        {text}
      </Typography>
    )
  }

  return (
    <div className={classes.HighlightContainer}>
      <Typography
        weight="bold"
        className={classNames(classes.Suggestion, 'u-truncate-text')}
      >
        {text.slice(0, highlight.length)}
      </Typography>
      <Typography className={classNames(classes.Suggestion, 'u-truncate-text')}>
        {text.slice(highlight.length)}
      </Typography>
    </div>
  )
}
