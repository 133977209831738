import { Badge } from '@matillion/component-library'

import classes from './ComponentStatus.module.scss'

interface ComponentStatusProps {
  labelText: string
}

export const ComponentStatus = ({ labelText }: ComponentStatusProps) => (
  <div className={classes.Component__ComponentStatus}>
    <Badge
      data-testid="component-status-label"
      theme="filled"
      colour="blue"
      weight="bold"
      format="mc"
    >
      {labelText}
    </Badge>
  </div>
)
