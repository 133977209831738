import { type AxiosInstance } from 'axios'

import {
  type ActivityItem,
  type PipelineStatus
} from '../../useEosClient/types/eos'

export interface ActivityResponse {
  results: ActivityItem[]
}

export const getActivity = async (
  client: AxiosInstance,
  projectId: string,
  workspaceId: string,
  status: PipelineStatus[],
  timeFrame?: string
): Promise<ActivityResponse> => {
  const { data } = await client.get<ActivityResponse>('/v1/activity', {
    params: {
      projectId,
      workspaceId,
      status: status.join(','),
      timeFrame,
      limit: 50
    }
  })
  return data
}
