import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { type OrchestrationComponentInstance } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useValidationDependencies } from 'modules/core/ComponentValidation'
import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { useDesignerApi } from '../useDesignerApi/useDesignerApi'
import {
  type SampleComponentRequest,
  type SampleComponentRequestBody
} from './types'

export const SAMPLE_ROW_LIMIT = 1000

export const useSampleComponent = (jobType: JobType, limit: number) => {
  const { projectId, agentId, environmentId } = useProjectInfo()
  const { job } = useWorkingCopy()
  const { sampleTransformationJob, sampleOrchestrationJob } = useDesignerApi()
  const getValidationDependencies = useValidationDependencies(job)

  return useMutation(
    async ({ componentCache, componentInstance }: SampleComponentRequest) => {
      const requestBody: SampleComponentRequestBody &
        Record<string, string | number> = {
        projectId,
        agentId,
        environmentId,
        limit
      }

      if (jobType === JobType.Orchestration) {
        requestBody.component =
          componentInstance as OrchestrationComponentInstance
        requestBody.variables = job?.variables
        requestBody.gridVariables = job?.grids

        if (componentInstance) {
          // Sampling uses the same dependencies as validation
          requestBody.dependencies = await getValidationDependencies(
            componentInstance?.implementationID,
            componentInstance?.parameters
          )
        }
      }

      if (jobType === JobType.Transformation) {
        requestBody.componentCache = componentCache
      }

      try {
        const { data } =
          jobType === JobType.Transformation
            ? await sampleTransformationJob(requestBody)
            : await sampleOrchestrationJob(requestBody)
        return data
      } catch (err) {
        if (axios.isAxiosError(err)) {
          throw err.response?.data
        }
        throw Error((err as Error).message)
      }
    }
  )
}
