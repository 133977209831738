export interface MonacoAutocompleteProvider {
  triggerText: string
  retrieveItems: (wordBeforeCursor: string) => Promise<MonacoAutocompleteItem[]>
}

export interface MonacoAutocompleteItem {
  name: string
  type?: string
  description?: string
}

export const sampleVariablesProvider: MonacoAutocompleteProvider = {
  triggerText: '{{',
  retrieveItems: async () => {
    return new Promise((resolve, reject) => {
      resolve([
        {
          name: 'start_date',
          type: 'text',
          description: 'Start of the range of dates '
        },
        {
          name: 'sum_of_items',
          type: 'number',
          description: 'Calculated cost of all items '
        },
        {
          name: 'worksheet_name_prefix',
          type: 'text',
          description: 'Default prefix for worksheets'
        },
        {
          name: 'longer_variable_test_with_no_description',
          type: 'text'
        }
      ])
    })
  }
}

export const sampleFieldsProvider: MonacoAutocompleteProvider = {
  // we may want to get more sophisticated with this trigger in future
  triggerText: '.',
  retrieveItems: async (wordBeforeCursor: string) => {
    const lowerCasedWord = wordBeforeCursor.toLowerCase()
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (lowerCasedWord === 'customers') {
          resolve([
            { name: 'customerId', type: 'NUMBER' },
            { name: 'customerName', type: 'VARCHAR' }
          ])
        } else if (lowerCasedWord === 'orders') {
          resolve([
            { name: 'customerId', type: 'NUMBER' },
            { name: 'orderDate', type: 'DATE' }
          ])
        }

        resolve([])
      }, 1000)
    })
  }
}

export const defaultAutocompleteProvider: MonacoAutocompleteProvider = {
  triggerText: '',
  retrieveItems: async () => {
    return Promise.resolve<MonacoAutocompleteItem[]>([
      {
        name: 'DATEDIFF',
        description:
          'Calculates the difference between two date, time, or timestamp expressions based on the date or time part requested. The function returns the result of subtracting the second argument from the third argument.'
      },
      {
        name: 'COUNT(*)',
        description: 'Returns the total number of rows'
      }
    ])
  }
}

export const sampleLongProvider: MonacoAutocompleteProvider = {
  triggerText: '@',
  retrieveItems: async () => {
    const items: MonacoAutocompleteItem[] = []
    for (let i = 0; i < 200; i++) {
      items.push({
        name: 'test' + ' ' + i,
        description: 'some example item',
        type: 'TEXT'
      })
    }

    return Promise.resolve(items)
  }
}
