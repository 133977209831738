import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  MicroCta,
  Toaster,
  Typography
} from '@matillion/component-library'
import classNames from 'classnames'

import { PermissionType } from 'api/external/checkPermission/checkPermission'
import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { type SampleComponentFailResponse } from 'api/hooks/useSampleComponent/types'
import { useSampleComponent } from 'api/hooks/useSampleComponent/useSampleComponent'
import { useSampleRowCount } from 'api/hooks/useSampleRowCount/useSampleRowCount'

import { ReactComponent as RotateRightIcon } from 'assets/rotate-right.svg'

import { AutoComplete } from 'components/AutoComplete'
import type { AutoCompleteItemId } from 'components/AutoComplete/types'
import { useUserPreference } from 'components/UserPreferenceProvider'

import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'

import { type ComponentInstance } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useComponentValidationResult } from 'modules/core/ComponentValidation'
import { useLayoutProvider } from 'modules/core/EtlDesigner/Layouts/LayoutProvider'

import * as heap from 'utils/heap'

import classes from './SampleComponent.module.scss'
import { SampleContent } from './SampleContent'
import { SampleIconAndLabel } from './SampleIconAndLabel'
import { useSampleProvider } from './SampleProvider'
import {
  calculateInitialDropdownValue,
  getDefaultSampleLimitItem,
  getSampleLimitItems
} from './utils/utils'

interface SampleComponentProps {
  componentInstance: ComponentInstance
  componentName: string
  componentMetadataId: string | undefined
  jobType: JobType
}

export const SampleComponent = ({
  componentInstance,
  componentName,
  componentMetadataId,
  jobType
}: SampleComponentProps) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const { userPreference, setSpecificUserPreference } = useUserPreference()
  const { metadata } = useComponentInstanceMetadataQuery(componentInstance.id)

  const defaultLimitItem = getDefaultSampleLimitItem()
  const availableSampleLimits = useMemo(() => {
    return getSampleLimitItems(metadata?.maxSampleSize)
  }, [metadata?.maxSampleSize])

  /* istanbul ignore next */
  const [dropdownValue, setDropdownValue] = useState<AutoCompleteItemId>(
    calculateInitialDropdownValue(
      userPreference?.sampleLimit,
      metadata?.maxSampleSize
    )
  )

  const {
    isSampleFetchRequested,
    setIsSampleFetchRequested,
    isSampleRowCountFetchRequested,
    setIsSampleRowCountFetchRequested
  } = useLayoutProvider()

  const {
    isError: isValidationError,
    isLoading: isValidating,
    isSuccess: isValidated,
    componentCache
  } = useComponentValidationResult(componentInstance.id)

  const {
    mutateAsync: sampleComponent,
    isLoading: isSampling,
    error: sampleErrorDetail,
    isError
  } = useSampleComponent(
    jobType,
    /* istanbul ignore next */
    (userPreference?.sampleLimit?.id as number) ?? defaultLimitItem.id
  )

  const { mutateAsync: sampleRowCount, isLoading: isFetchingRowCount } =
    useSampleRowCount(jobType)

  const {
    currentRowCount,
    setCurrentRowCount,
    setCurrentSample,
    currentSample
  } = useSampleProvider()
  const { hasPermission } = useProjectPermission(
    'sampling_pipelines',
    PermissionType.ENVIRONMENT
  )

  const isTransformationJob = jobType === JobType.Transformation
  const isLoading = isValidating || isSampling
  const isComponentValidated = isValidationError || isValidated
  const isDisabled =
    !isComponentValidated || isValidationError || !hasPermission
  const isRowCountDisabled = isDisabled || isFetchingRowCount

  const handleSample = useCallback(() => {
    if (isTransformationJob && !componentCache) {
      return
    }

    const runSample = async () =>
      sampleComponent({ componentCache, componentInstance })

    runSample().then((response) => {
      /* istanbul ignore else */
      if (response) {
        setCurrentSample(response)
      }
    })
  }, [
    componentCache,
    componentInstance,
    isTransformationJob,
    sampleComponent,
    setCurrentSample
  ])

  const handleRowCount = useCallback(() => {
    const getRowCount = async () => {
      try {
        return await sampleRowCount({ componentCache })
      } catch (error) {
        const rowCountError = error as SampleComponentFailResponse

        makeToast({
          type: 'error',
          title: t('sampleComponent.rowCountErrorTitle'),
          message: rowCountError?.detail ?? t('sampleComponent.rowCountError')
        })
      }
    }

    getRowCount().then((response) => {
      /* istanbul ignore else */
      if (response !== undefined) {
        setCurrentRowCount(response)
      }
    })
  }, [t, makeToast, componentCache, sampleRowCount, setCurrentRowCount])

  useEffect(() => {
    if (currentSample && isValidating) {
      setCurrentSample(null)
      setCurrentRowCount(null)
    }
  }, [currentSample, isValidating, setCurrentRowCount, setCurrentSample])

  useEffect(() => {
    if (isSampleFetchRequested) {
      setIsSampleFetchRequested(false)
      handleSample()
    }
    if (isSampleRowCountFetchRequested) {
      setIsSampleRowCountFetchRequested(false)
      handleRowCount()
    }
  }, [
    handleSample,
    handleRowCount,
    isSampleFetchRequested,
    setIsSampleFetchRequested,
    isSampleRowCountFetchRequested,
    setIsSampleRowCountFetchRequested
  ])

  return (
    <>
      <form
        className={classNames(
          classes.SampleComponentForm,
          classes['SampleComponentForm--DragHandle']
        )}
      >
        <div className={classes.SampleComponentForm__Container}>
          <SampleIconAndLabel componentInstance={componentInstance} />
          {
            <AutoComplete
              readOnly
              allowFreetext={false}
              enableHighlight={false}
              scrollableContainerSelectors={['#bottom-panel']}
              className={classNames(classes.SampleComponentForm__Limit)}
              inputClassName={classNames(
                classes.SampleComponentForm__Limit__Input
              )}
              optionClassName={classNames(
                classes.SampleComponentForm__Limit__Option
              )}
              value={dropdownValue}
              availableItems={availableSampleLimits}
              onChange={(selectedItem) => {
                /* istanbul ignore next */
                const selectedValue = selectedItem.target.value
                  ? selectedItem.target.value
                  : defaultLimitItem
                setDropdownValue(selectedValue)
                setSpecificUserPreference('sampleLimit', selectedValue)
                heap.track('etld_sample_limit', {
                  selectedSampleLimit: selectedValue.id,
                  componentMetadataId
                })
              }}
            />
          }
          <Button
            data-testid="sample-button"
            className={classes.SampleComponentForm__SampleButton}
            size="md"
            text={t('sampleComponent.sampleButtonText')}
            disabled={isDisabled}
            waiting={isLoading}
            onClick={() => {
              handleSample()
              handleRowCount()
            }}
          />
        </div>
        {isTransformationJob && (
          <div
            className={classNames(
              classes.SampleComponentForm__Container,
              classes['SampleComponentForm__Container--DragHandle']
            )}
          >
            <Typography format="bcs" data-testid="sample-row-count">
              {t('sampleComponent.totalRowCount', {
                rowCount: currentRowCount ?? '-'
              })}
            </Typography>
            <MicroCta
              data-testid="sample-row-count-button"
              className={classes.SampleComponentForm__SampleRowCountButton}
              aria-label={t('sampleComponent.rowCountButtonLabel')}
              alt="transparent"
              size="lg"
              disabled={isRowCountDisabled}
              onClick={handleRowCount}
            >
              {isFetchingRowCount ? <Icon.Loading /> : <RotateRightIcon />}
            </MicroCta>
          </div>
        )}
      </form>

      <SampleContent
        componentName={componentName}
        isLoading={isLoading}
        isComponentValidated={isComponentValidated}
        isValidationError={isValidationError}
        isSampleError={isError}
        sampleErrorDetail={sampleErrorDetail as SampleComponentFailResponse}
      />
    </>
  )
}
