import { useMutation } from '@tanstack/react-query'

import useCopilotClient from 'api/hooks/useCopilotClient/useCopilotClient'

import { submitFeedback } from '../requests'
import { type CopilotService, type FeedbackSentiment } from '../types'

export interface SubmitFeedbackRequest {
  requestId: string
  response: FeedbackSentiment
  type: CopilotService
  comments: string
}

export const useSubmitFeedback = () => {
  const client = useCopilotClient()

  return useMutation(async (request: SubmitFeedbackRequest) => {
    await submitFeedback(client, request)
  })
}
