import { useEffect, type FC } from 'react'

import { type TreeExpandedState } from '@matillion/component-library'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import BrowserTree from 'components/BrowserTree/BrowserTree'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { FileStatus } from 'job-lib/types/FileStatus'

import { MovePipelineModal } from 'modules/FileBrowser/commands/MovePipelineModal/MovePipelineModal'
import useMoveFile from 'modules/FileBrowser/hooks/useMoveFile'

import {
  usePipelineBrowserCommandProvider,
  usePipelineBrowserProvider
} from '../../effects/usePipelineBrowserCommand'
import { FileTreeReducerType } from '../../reducers/fileTreeReducer/types'
import FileTree from '../FileTree/FileTree'
import RootFolder from '../RootFolder/RootFolder'
import classes from './FileBrowserTree.module.scss'

export interface Props {
  jobs: JobSummary[]
  onToggle?: (nodeId: string, isExpanded: boolean) => void
  expandedItems?: TreeExpandedState
}

export const FileBrowserTree: FC<Props> = ({
  jobs,
  onToggle,
  expandedItems
}) => {
  const { branchId, projectId } = useProjectInfo()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()
  const fileTree = usePipelineBrowserProvider()
  const {
    moveFile,
    onConfirmMove,
    showWarningModal,
    setShowWarningModal,
    fileType
  } = useMoveFile()

  useEffect(() => {
    onFileTreeCommand({
      summaries: jobs.filter((job) => job.status !== FileStatus.DELETED),
      type: FileTreeReducerType.OVERWRITE_TREE,
      project: projectId,
      branch: branchId
    })
  }, [jobs, branchId, projectId, onFileTreeCommand])

  return (
    <>
      <RootFolder moveFile={moveFile}>
        <BrowserTree
          className={classes.PipelineBrowserTree}
          onToggle={onToggle}
          expandedItems={expandedItems}
        >
          <FileTree data={fileTree} moveFile={moveFile} />
        </BrowserTree>
      </RootFolder>

      {showWarningModal && !!fileType && (
        <MovePipelineModal
          fileType={fileType}
          onConfirm={onConfirmMove}
          onClose={() => {
            setShowWarningModal(false)
          }}
        />
      )}
    </>
  )
}
