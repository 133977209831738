import { useOptimiseQuery } from 'api/hooks/copilot/useOptimiseQuery/useOptimiseQuery'
import { useUpdatePipeline } from 'api/hooks/copilot/usePipelineUpdate/useUpdatePipeline'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { type ComponentWithMetadata } from '../model'

// This is a custom hook that aggregates the useOptimiseQuery and useUpdatePipeline hooks. Its done this way so that
// we can easily call them independently in the future if we need to decouple them for UX purposes, etc.
export const useGeneratePipeline = () => {
  const { jobSummaryId: jobId, projectId, branchId } = useProjectInfo()
  const { job, jobType } = useWorkingCopy()

  const { mutateAsync: optimiseQuery, isLoading: isLoadingOQ } =
    useOptimiseQuery()
  const { mutateAsync: updatePipeline, isLoading: isLoadingUP } =
    useUpdatePipeline()

  const generatePipeline = async (
    message: string,
    selectedComponents?: ComponentWithMetadata[]
  ) => {
    const requestId = crypto.randomUUID()

    const pipeline = {
      projectId,
      branchId,
      jobId,
      type: jobType,
      source: JSON.stringify(job),
      selectedComponentNames: selectedComponents?.map(
        (component) => component.componentName
      ),
      selectedComponents
    }

    const tasks = await optimiseQuery({
      requestId,
      pipeline,
      warehouse: 'SNOWFLAKE',
      message
    })

    const response = await updatePipeline({
      requestId,
      pipeline,
      warehouse: 'SNOWFLAKE',
      tasks,
      message
    })

    return response
  }

  return {
    generatePipeline,
    isLoading: isLoadingOQ || isLoadingUP
  }
}
