import { useMemo } from 'react'

import { PipelineType, type ComponentName, type Pipeline } from 'types/Pipeline'

import { isDplParamsRequired } from 'job-lib/cisIds/idType'
import {
  type Connector,
  type ConnectorId,
  type OrchestrationComponentInstanceCollection,
  type OrchestrationJob,
  type TransformationComponentInstanceCollection,
  type TransformationJob
} from 'job-lib/types/Job'

import { idGenerator } from '../../utils/idGenerator'
import { type MetlConversionMetadata } from '../useMetlConversionMetadata'
import { convertDplParametersToMetl } from './convertDplParametersToMetl'
import convertParametersToMetl from './convertParametersToMetl'
import {
  convertSourcesToMetlConnectors,
  convertTransitionsToMetlConnectors
} from './convertToMetlConnectors'
import convertVariablesToMetl from './convertVariablesToMetl'

const getDesignForComponent = (pipeline: Pipeline, componentName: string) =>
  pipeline.design.components[componentName] ?? {
    position: { x: 0, y: 0 }
  }

const convertTransformationPipelineToMetl = (
  pipeline: Pipeline,
  metadata: MetlConversionMetadata
): TransformationJob => {
  const metlComponentInstances: TransformationComponentInstanceCollection = {}
  const metlConnectorIdGenerator = idGenerator()
  const metlConnectors = new Map<ConnectorId, Connector>()
  const { metlVariables, metlGridVariables } = convertVariablesToMetl(
    pipeline.pipeline.variables
  )

  Object.entries(pipeline.pipeline.components ?? {}).forEach(
    ([componentName, componentInstance]) => {
      const componentID = metadata.metlComponentIdsByName[componentName]
      const componentMetadata =
        metadata.componentMetadata[componentInstance.type]

      convertSourcesToMetlConnectors(
        metadata.metlComponentIdsByName,
        componentID,
        componentInstance.sources,
        metlConnectors,
        metlConnectorIdGenerator
      )

      metlComponentInstances[componentID] = {
        id: componentID,
        implementationID: Number(componentMetadata.emeraldId),

        parameters: convertParametersToMetl(
          componentInstance,
          componentMetadata
        ),

        ...getDesignForComponent(pipeline, componentName).position,

        /* Connectors are created afterwards to ensure that components exist */
        inputConnectorIDs: [],
        outputConnectorIDs: [],

        /* Unused placeholder properties */
        gridExportMappings: {},
        exportMappings: {},
        width: 32,
        height: 32
      }
    }
  )

  metlConnectors.forEach((metlConnector) => {
    metlComponentInstances[metlConnector.targetID].inputConnectorIDs.push(
      metlConnector.id
    )
    metlComponentInstances[metlConnector.sourceID].outputConnectorIDs.push(
      metlConnector.id
    )
  })

  return {
    revision: 0,
    variables: metlVariables,
    grids: metlGridVariables,
    notes: {
      ...pipeline.design?.notes
    },
    components: metlComponentInstances,
    connectors: Object.fromEntries(metlConnectors)
  }
}

const convertOrchestrationPipelineToMetl = (
  pipeline: Pipeline,
  metadata: MetlConversionMetadata
): OrchestrationJob => {
  const metlComponentInstances: OrchestrationComponentInstanceCollection = {}
  const metlConnectorIdGenerator = idGenerator()
  const metlConnectors: Record<string, Map<ConnectorId, Connector>> = {
    successConnectors: new Map<ConnectorId, Connector>(),
    unconditionalConnectors: new Map<ConnectorId, Connector>(),
    failureConnectors: new Map<ConnectorId, Connector>(),
    trueConnectors: new Map<ConnectorId, Connector>(),
    falseConnectors: new Map<ConnectorId, Connector>(),
    iterationConnectors: new Map<ConnectorId, Connector>()
  }
  const { metlVariables, metlGridVariables } = convertVariablesToMetl(
    pipeline.pipeline.variables
  )

  Object.entries(pipeline.pipeline.components ?? {}).forEach(
    ([componentName, componentInstance]) => {
      const componentID = metadata.metlComponentIdsByName[componentName]
      const componentMetadata =
        metadata.componentMetadata[componentInstance.type]

      // A dplInMetl component is a modular connector or native orchestration component that uses a special parameter format (slot 1: componentName, slot 2: DPL as JSON blob)
      // We know the type of component from its classification
      const isDplInMetlComponent = isDplParamsRequired(
        componentMetadata.classification
      )

      const convertComponentConnectorsToMetl = (
        metlConnectorsMap: Map<ConnectorId, Connector>,
        connectors?: ComponentName[]
      ) =>
        convertTransitionsToMetlConnectors(
          metadata.metlComponentIdsByName,
          componentID,
          connectors,
          metlConnectorsMap,
          metlConnectorIdGenerator
        )

      if (componentInstance.transitions) {
        convertComponentConnectorsToMetl(
          metlConnectors.successConnectors,
          componentInstance.transitions.success
        )
        convertComponentConnectorsToMetl(
          metlConnectors.unconditionalConnectors,
          componentInstance.transitions.unconditional
        )
        convertComponentConnectorsToMetl(
          metlConnectors.failureConnectors,
          componentInstance.transitions.failure
        )
        convertComponentConnectorsToMetl(
          metlConnectors.trueConnectors,
          componentInstance.transitions.true
        )
        convertComponentConnectorsToMetl(
          metlConnectors.falseConnectors,
          componentInstance.transitions.false
        )
      }

      if (componentInstance.iterationTarget) {
        convertComponentConnectorsToMetl(metlConnectors.iterationConnectors, [
          componentInstance.iterationTarget
        ])
      }

      metlComponentInstances[componentID] = {
        id: componentID,
        implementationID: Number(componentMetadata.emeraldId),

        // Components like modular connectors and native orchestrations should be converted to the special parameter format (slot 1: name, slot 2: dpl params as json)
        parameters: isDplInMetlComponent
          ? convertDplParametersToMetl(componentInstance)
          : convertParametersToMetl(componentInstance, componentMetadata),

        ...getDesignForComponent(pipeline, componentName).position,

        /* Connectors are created afterwards to ensure that components exist */
        inputConnectorIDs: [],
        inputIterationConnectorIDs: [],
        outputSuccessConnectorIDs: [],
        outputFailureConnectorIDs: [],
        outputUnconditionalConnectorIDs: [],
        outputTrueConnectorIDs: [],
        outputFalseConnectorIDs: [],
        outputIterationConnectorIDs: [],

        /* Unused placeholder properties */
        gridExportMappings: {},
        exportMappings: {},
        width: 32,
        height: 32
      }
    }
  )

  metlConnectors.successConnectors.forEach((metlConnector) => {
    metlComponentInstances[metlConnector.targetID].inputConnectorIDs.push(
      metlConnector.id
    )
    metlComponentInstances[
      metlConnector.sourceID
    ].outputSuccessConnectorIDs.push(metlConnector.id)
  })
  metlConnectors.unconditionalConnectors.forEach((metlConnector) => {
    metlComponentInstances[metlConnector.targetID].inputConnectorIDs.push(
      metlConnector.id
    )
    metlComponentInstances[
      metlConnector.sourceID
    ].outputUnconditionalConnectorIDs.push(metlConnector.id)
  })
  metlConnectors.failureConnectors.forEach((metlConnector) => {
    metlComponentInstances[metlConnector.targetID].inputConnectorIDs.push(
      metlConnector.id
    )
    metlComponentInstances[
      metlConnector.sourceID
    ].outputFailureConnectorIDs.push(metlConnector.id)
  })
  metlConnectors.trueConnectors.forEach((metlConnector) => {
    metlComponentInstances[metlConnector.targetID].inputConnectorIDs.push(
      metlConnector.id
    )
    metlComponentInstances[metlConnector.sourceID].outputTrueConnectorIDs.push(
      metlConnector.id
    )
  })
  metlConnectors.falseConnectors.forEach((metlConnector) => {
    metlComponentInstances[metlConnector.targetID].inputConnectorIDs.push(
      metlConnector.id
    )
    metlComponentInstances[metlConnector.sourceID].outputFalseConnectorIDs.push(
      metlConnector.id
    )
  })
  metlConnectors.iterationConnectors.forEach((metlConnector) => {
    metlComponentInstances[
      metlConnector.targetID
    ].inputIterationConnectorIDs.push(metlConnector.id)
    metlComponentInstances[
      metlConnector.sourceID
    ].outputIterationConnectorIDs.push(metlConnector.id)
  })

  return {
    revision: 0,
    variables: metlVariables,
    grids: metlGridVariables,
    notes: {
      ...pipeline.design?.notes
    },
    components: metlComponentInstances,
    failureConnectors: Object.fromEntries(metlConnectors.failureConnectors),
    falseConnectors: Object.fromEntries(metlConnectors.falseConnectors),
    iterationConnectors: Object.fromEntries(metlConnectors.iterationConnectors),
    successConnectors: Object.fromEntries(metlConnectors.successConnectors),
    trueConnectors: Object.fromEntries(metlConnectors.trueConnectors),
    unconditionalConnectors: Object.fromEntries(
      metlConnectors.unconditionalConnectors
    )
  }
}

const useMetlPipeline = (
  pipeline?: Pipeline,
  metadata?: MetlConversionMetadata
) =>
  useMemo(() => {
    if (!metadata || !pipeline?.pipeline) {
      return null
    }

    const startTime = performance.now()

    const metlPipeline =
      pipeline.type === PipelineType.Orchestration
        ? convertOrchestrationPipelineToMetl(pipeline, metadata)
        : convertTransformationPipelineToMetl(pipeline, metadata)

    const endTime = performance.now()

    return {
      metlPipeline,
      startTime,
      endTime
    }
  }, [metadata, pipeline])

export default useMetlPipeline
