import { useEffect, useState } from 'react'

import { type TreeExpandedState } from '@matillion/component-library'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

export const useFileBrowserState = () => {
  const [treeState, setTreeState] = useState<TreeExpandedState>({})
  const { jobSummaryId } = useProjectInfo()

  const toggleNode = (nodeId: string, isExpanded: boolean) => {
    setTreeState({
      ...treeState,
      [nodeId]: isExpanded
    })
  }

  useEffect(() => {
    const nodes = jobSummaryId.split('/')
    const paths = nodes
      .slice(0, nodes.length - 1)
      .reduce((acc: TreeExpandedState, value: string, idx: number) => {
        const path = nodes.slice(0, idx).join('/')
        acc[idx === 0 ? value : `${path}/${value}`] = true
        return acc
      }, {})

    setTreeState((currentTreeState) => {
      return { ...currentTreeState, ...paths }
    })
  }, [jobSummaryId])

  return {
    toggleNode,
    pipelineTreeState: treeState
  }
}
