import { type ComponentWithMetadata } from '../../../api/hooks/copilot/model'
import {
  type CopilotService,
  type FeedbackSentiment
} from '../../../api/hooks/copilot/types'

export const AMBIGUOUS_PROMPT_ERROR: string = 'copilot/message/ambiguous_prompt'

export interface CopilotChatMessage {
  id: string
  content: string
  type: 'user' | 'bot'
  requestId?: string
  service?: CopilotService
  errorType?: string
}

export interface CopilotContextBag {
  chatMessages: CopilotChatMessage[]
  addInfoMessage: (message: CopilotChatMessage) => void
  updatePipeline: (
    query: CopilotChatMessage,
    selectedComponents?: ComponentWithMetadata[]
  ) => Promise<void>
  submitFeedback: (
    message: CopilotChatMessage,
    sentiment: FeedbackSentiment,
    content: string
  ) => Promise<void>
  isLoadingUpdatePipeline: boolean
  isLoadingSubmitFeedback: boolean
}
