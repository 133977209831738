import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
  type KeyboardEvent
} from 'react'
import { useTranslation } from 'react-i18next'
import { Panel, type ImperativePanelHandle } from 'react-resizable-panels'

import { AppMenu, AppMenuItem, Icon } from '@matillion/component-library'
import classNames from 'classnames'

import { ProjectType } from 'api/hooks/useGetProject/types'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { ReactComponent as CopilotStars } from 'assets/copilot-stars.svg'
import { ReactComponent as VariableSvg } from 'assets/variable-icon.svg'

import { ResizeableHandler } from 'components/ResizeableHandler/ResizeableHandler'
import {
  DragHandlePosition,
  Orientation
} from 'components/ResizeableHandler/types'

import { useFlags } from 'hooks/useFlags'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { ComponentSummaries } from 'modules/ComponentSummaries/ComponentSummaries'
import { CopilotChat } from 'modules/CopilotChat/CopilotChat'
import { CopilotProvider } from 'modules/core/Copilot/CopilotProvider'
import { FileBrowser } from 'modules/FileBrowser'
import { ManageVariables } from 'modules/ManageVariables/ManageVariables'
import SchemaBrowser from 'modules/ui/SchemaBrowser/SchemaBrowser'

import { useFileBrowserState } from '../../hooks/useFileBrowserState'
import classes from './RailSidebar.module.scss'
import { menuItems, type MenuItem } from './types'

const SIDE_RAIL_WIDTH_PX = 90
const DEFAULT_WIDTH_PX = 270
const VARIABLES_WIDTH_PX = 500
const MAX_PANEL_WIDTH_PCT = 50

const MenuItemIcon: FC<{ menuItem: MenuItem }> = ({ menuItem }) => {
  switch (menuItem) {
    case 'Files':
      return <Icon.Pipelines2 />
    case 'Components':
      return <Icon.Components />
    case 'Schemas':
      return <Icon.Schemas />
    case 'Variables':
      return <VariableSvg />
    case 'Copilot':
      return <CopilotStars />
    default:
      return null
  }
}

const RailSidebar = () => {
  const {
    enableOrchestrationCopilot,
    enableTransformationCopilot,
    enableHighCode
  } = useFlags()
  const sidebarPanelRef = useRef<ImperativePanelHandle>(null)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [activeItem, setActiveItem] = useState<MenuItem | null>(null)
  const lastPanelSize = useRef(0)
  const { toggleNode, pipelineTreeState } = useFileBrowserState()
  const { data } = useGetProject()
  const { t } = useTranslation('translation', { keyPrefix: 'sideBar' })

  const enableCopilotOption =
    enableOrchestrationCopilot || enableTransformationCopilot

  const defaultSize = useMemo(() => {
    const groupWidth = window.innerWidth - SIDE_RAIL_WIDTH_PX
    const targetWidth =
      activeItem === 'Variables' ? VARIABLES_WIDTH_PX : DEFAULT_WIDTH_PX
    return Math.min((targetWidth / groupWidth) * 100, MAX_PANEL_WIDTH_PCT)
  }, [activeItem])

  const resizePanel = useCallback(() => {
    const newPanelSize = Math.max(lastPanelSize.current, defaultSize)
    sidebarPanelRef.current?.resize(newPanelSize)
    lastPanelSize.current = newPanelSize
  }, [defaultSize])

  useEffect(() => {
    if (isCollapsed) {
      sidebarPanelRef.current?.resize(0)
    } else {
      resizePanel()
    }
  }, [isCollapsed, resizePanel])

  const { selectedJobs } = useSelectedJobs()

  useEffect(() => {
    setActiveItem('Files')
    setIsCollapsed(false)
  }, [])

  useEffect(() => {
    if (selectedJobs.length === 0) {
      setActiveItem('Files')
      setIsCollapsed(false)
    }
  }, [selectedJobs])

  const getItemDisplayName = useCallback(
    (item: string) => {
      if (item === 'Files' && enableHighCode) {
        return t(`menu-items.${item.toLowerCase()}-high-code`)
      }

      return t(`menu-items.${item.toLowerCase()}`)
    },
    [enableHighCode, t]
  )

  const menuItemSelect = (item: MenuItem) => {
    setActiveItem(item)

    if (isCollapsed) {
      setIsCollapsed(false)
      resizePanel()
      return
    }

    if (!isCollapsed && activeItem === item) {
      setIsCollapsed(true)
      setActiveItem(null)
    }
  }

  const menuItemKeyDownHandler = (e: KeyboardEvent, item: MenuItem) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      menuItemSelect(item)
    }
  }

  return (
    <>
      <div
        className={classNames(classes.RailSidebar__Rail, {
          [classes['RailSidebar__Rail--Collapsed']]: isCollapsed
        })}
        data-testid="sidebar-rail"
        data-wtf={activeItem?.toLowerCase()}
      >
        <AppMenu className={classes.RailSidebar__AppMenu}>
          {menuItems
            .filter((item) => {
              if (item === 'Copilot') {
                return (
                  enableCopilotOption &&
                  data?.warehouse === ProjectType.SNOWFLAKE
                )
              }

              return true
            })
            .map((item) => (
              <AppMenuItem
                key={item}
                tabIndex={activeItem === item ? -1 : 0}
                id={`app-menu-item-${item}`}
                data-test-menu-item={`rail-sidebar-${item}`}
                data-testid={`rail-sidebar-${item}-active:${
                  item === activeItem
                }`}
                data-tracker-id={`rail-sidebar-${item}`}
                active={activeItem === item}
                name={getItemDisplayName(item)}
                icon={<MenuItemIcon menuItem={item} />}
                onKeyDown={(e: KeyboardEvent) => {
                  menuItemKeyDownHandler(e, item)
                }}
                onClick={() => {
                  menuItemSelect(item)
                }}
                className={classNames({
                  [classes['RailSidebar__MenuItem--Active']]:
                    activeItem === item,
                  [classes['RailSidebar__MenuItem--Copilot']]:
                    item === 'Copilot'
                })}
              />
            ))}
        </AppMenu>
      </div>

      <Panel
        id="side-rail-panel"
        order={1}
        ref={sidebarPanelRef}
        aria-labelledby={`app-menu-item-${activeItem}`}
        className={classes.RailSidebar__Panel}
        minSize={isCollapsed ? 0 : defaultSize}
        maxSize={50}
        defaultSize={isCollapsed ? 0 : defaultSize}
        onResize={(size: number, _) => {
          if (size >= defaultSize) {
            lastPanelSize.current = size
          }
        }}
      >
        <CopilotProvider>
          <div
            data-testid="sidebar-rail-content"
            className={classes.RailSidebar__ContentPanel}
          >
            {activeItem === 'Files' && (
              <FileBrowser
                data-testid="pipeline-browser"
                onToggle={toggleNode}
                expandedItems={pipelineTreeState}
              />
            )}
            {activeItem === 'Components' && <ComponentSummaries />}
            {activeItem === 'Variables' && <ManageVariables />}
            {activeItem === 'Schemas' && <SchemaBrowser />}
            {activeItem === 'Copilot' && <CopilotChat />}
          </div>
        </CopilotProvider>
      </Panel>

      <ResizeableHandler
        orientation={Orientation.VERTICAL}
        id="sidebar"
        hidden={isCollapsed}
        showCollapseButton={!isCollapsed}
        collapseButtonClassName={classes.RailSidebar__CollapseButton}
        onCollapseButtonClick={() => {
          sidebarPanelRef.current?.collapse()
          setIsCollapsed(true)
          setActiveItem(null)
        }}
        hideDragHandle={isCollapsed}
        dragHandlePosition={DragHandlePosition.BEFORE}
      />
    </>
  )
}

export default RailSidebar
