import { useEffect } from 'react'

import { LoadingSpinner } from '@matillion/component-library'

import classes from '../../UrlEditor.module.scss'
import { SortOrder, type GridColumns, type GridRow } from '../hooks/useGrid'
import ContainerGrid from './ContainerGrid'

interface ContainerListProps {
  isLoading: boolean
  columns: GridColumns
  rows: GridRow[]
  setSort: (sort: SortOrder) => void
}

const ContainerList = ({
  isLoading,
  columns,
  rows,
  setSort
}: ContainerListProps) => {
  useEffect(() => {
    return () => {
      setSort(SortOrder.ASC)
    }
  }, [setSort])

  return (
    <div className={classes.Buckets}>
      <div className={classes['Buckets--TableContainer']}>
        {isLoading ? (
          <div className={classes['Buckets--LoadingContainer']}>
            <LoadingSpinner />
          </div>
        ) : (
          <ContainerGrid columns={columns} rows={rows} setSort={setSort} />
        )}
      </div>
    </div>
  )
}

export default ContainerList
