import { getComponentName } from '../../job-lib/job-functions/getComponentName'
import { useWorkingCopy } from '../../modules/core/EtlDesigner/hooks/useWorkingCopy'

export const useGetComponentsNameInCurrentPipeline = (): string[] => {
  const { job } = useWorkingCopy()
  if (job === null || job === undefined) {
    return []
  }
  return Object.values(job.components).map(getComponentName)
}
