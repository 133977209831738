import { type Pipeline, type Transition } from 'types/Pipeline'

import { Cardinality } from 'job-lib/types/Components'

import { deleteLink } from '../deleteLink/deleteLink'
import { type LinkType, type UpdateLinksPayload } from './updateLinks'

interface UpdateTransitionsPayload extends UpdateLinksPayload {
  linkType: LinkType
}

export const updateTransitions = (
  state: Pipeline,
  {
    sourceComponent,
    targetComponent,
    sourceCardinality,
    targetCardinality,
    linkType
  }: UpdateTransitionsPayload
) => {
  const source = state.pipeline.components[sourceComponent]

  if (linkType === 'iteration') {
    source.iterationTarget = targetComponent

    for (const componentName in state.pipeline.components) {
      const component = state.pipeline.components[componentName]

      for (const transitionType in component.transitions) {
        // istanbul ignore else
        if (
          component.transitions[transitionType as Transition]?.includes(
            targetComponent
          )
        ) {
          component.transitions[transitionType as Transition] =
            component.transitions[transitionType as Transition]
              ?.filter((transition) => transition !== targetComponent)
              .concat(sourceComponent)
        }
      }
    }
    return
  }

  if (targetCardinality === Cardinality.ONE) {
    /* If the target can only accept one link
     * Go through all components in the pipeline
     * and remove any links connected to the target component
     */
    Object.keys(state.pipeline.components).forEach((componentName) => {
      deleteLink(state)({
        sourceName: componentName,
        targetName: targetComponent
      })
    })
  }

  if (sourceCardinality === Cardinality.ONE) {
    /* If the source can only have one link
     * Remove any existing links from the source component
     */
    deleteLink(state)({
      sourceName: sourceComponent,
      targetName: targetComponent
    })
  }

  source.transitions ??= {}

  if (!source.transitions[linkType]?.includes(targetComponent)) {
    source.transitions[linkType] ??= []
    source.transitions[linkType]?.push(targetComponent)
  }
}
