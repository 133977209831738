import { type AxiosInstance, type AxiosResponse } from 'axios'

import { type OptimisedQueryTask } from './model'
import {
  type SQLExpressionRequest,
  type SQLExpressionResponse
} from './useGenerateSQLExpression/useGenerateSQLExpression'
import { type OptimiseQueryInput } from './useOptimiseQuery/useOptimiseQuery'
import {
  type CopilotResponse,
  type PipelineUpdateInput
} from './usePipelineUpdate/useUpdatePipeline'
import { type SubmitFeedbackRequest } from './useSubmitFeedback/useSubmitFeedback'

export const updatePipeline = async (
  client: AxiosInstance,
  input: PipelineUpdateInput
): Promise<AxiosResponse<CopilotResponse>> => {
  const response = await client.post<CopilotResponse>(
    '/api/v1/sidekick/update-pipeline',
    input
  )

  return response
}

export const submitFeedback = async (
  client: AxiosInstance,
  request: SubmitFeedbackRequest
): Promise<AxiosResponse> => {
  const response = await client.post('/api/v1/feedback', request)

  return response
}

export const generateSQLExpression = async (
  client: AxiosInstance,
  request: SQLExpressionRequest
): Promise<AxiosResponse<SQLExpressionResponse>> => {
  const response = await client.post<SQLExpressionResponse>(
    '/api/v1/sidekick/sql/expression',
    request
  )

  return response
}

export const optimiseQuery = async (
  client: AxiosInstance,
  input: OptimiseQueryInput
): Promise<AxiosResponse<OptimisedQueryTask[]>> => {
  const response = await client.post<OptimisedQueryTask[]>(
    '/api/v1/sidekick/optimise-query',
    input
  )

  return response
}
