import { type FC } from 'react'
import { NodeToolbar, type NodeProps } from 'reactflow'

import { getIdFromReactFlowId } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/utils'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { unknownComponentIds } from 'job-lib/cisIds/knownComponentParameters'

import { ComponentNodeToolbar } from '../../ComponentNodeToolbar/ComponentNodeToolbar'
import { FlowNodeWrapper } from '../components/FlowNodeWrapper'
import { ComponentNode } from './ComponentNode'
import { type EtlNodeProps } from './models'

export const IteratorNode: FC<NodeProps<EtlNodeProps>> = ({
  id,
  selected: nodeSelected,
  data: {
    imageUrl,
    label,
    inputPorts,
    outputPorts,
    iteratorPorts,
    hasOutputConnection,
    attachedNode,
    summaryId,
    outputPortsConnected
  }
}) => {
  const { componentId } = useProjectInfo()
  const iteratorNodeSelected = id === `component-${componentId?.toString()}`
  const attachedNodeSelected =
    attachedNode?.id === `component-${componentId?.toString()}`

  const selectedInUrl = iteratorNodeSelected || attachedNodeSelected

  const isSelected = selectedInUrl || nodeSelected
  const { enableComponentToolbar } = useFlags()

  return (
    <FlowNodeWrapper
      id={getIdFromReactFlowId(id)}
      showPorts={isSelected}
      inputPorts={inputPorts}
      outputPorts={outputPorts}
      /* we can't rely on iteratorPorts to determine if it's an iterator component
       * because when an iterator is attached to a node we remove its iteration ports
       * this prevents us from being able to connect an iteration port to an attached component
       */
      isIterator={true}
      isSelected={isSelected}
      iteratorPorts={attachedNode ? [] : iteratorPorts}
      hasOutputConnection={hasOutputConnection}
      outputPortsConnected={outputPortsConnected}
      showOnlyConnectedOutputPorts={unknownComponentIds.includes(summaryId)}
    >
      <NodeToolbar isVisible={enableComponentToolbar && selectedInUrl}>
        <ComponentNodeToolbar
          summaryId={summaryId}
          componentInstanceId={
            attachedNodeSelected
              ? Number(getIdFromReactFlowId(attachedNode.id))
              : Number(getIdFromReactFlowId(id))
          }
        />
      </NodeToolbar>
      <ComponentNode
        id={getIdFromReactFlowId(id)}
        isIterator
        isAttached={!!attachedNode}
        isSelected={isSelected}
        imageUrl={imageUrl}
        summaryId={summaryId}
      >
        {label}
      </ComponentNode>

      {attachedNode && (
        <ComponentNode
          id={getIdFromReactFlowId(attachedNode.id)}
          imageUrl={attachedNode.data.imageUrl}
          isAttached
          isSelected={isSelected}
          summaryId={attachedNode.data.summaryId}
        >
          {attachedNode.data.label}
        </ComponentNode>
      )}
    </FlowNodeWrapper>
  )
}
