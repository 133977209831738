import { useMemo } from 'react'

import { cloneDeep, isEqual } from 'lodash'
import { PipelineType } from 'types/Pipeline'
import { temporal } from 'zundo'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { createStore } from 'zustand/vanilla'

import config, { Environment } from 'config'

import * as mutations from './mutations'
import { type MappedPipelineMutations, type WorkingCopyStore } from './types'

export const DEFAULT_PIPELINE = {
  version: '0',
  type: PipelineType.Orchestration,
  pipeline: {
    components: {},
    variables: {}
  },
  design: {
    components: {},
    notes: {}
  }
}

export const createWorkingCopyStore = (id: string) =>
  createStore<WorkingCopyStore>()(
    devtools(
      temporal(
        (set, get) => ({
          workingCopy: DEFAULT_PIPELINE,
          update: (mutator) => {
            const workingCopy = cloneDeep(get().workingCopy)

            const mappedMutations = Object.keys(mutations).reduce(
              (bindings, key) => {
                const mutationKey = key as keyof typeof mutations

                return {
                  ...bindings,
                  [mutationKey]: mutations[mutationKey](workingCopy)
                }
              },
              // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter, @typescript-eslint/consistent-type-assertions
              {} as MappedPipelineMutations
            )

            mutator(mappedMutations)
            set({ workingCopy })
          }
        }),
        {
          limit: 5,
          // scopes zundo to only store temporal states for the working copy
          partialize: (state) => {
            return { workingCopy: state.workingCopy }
          },
          wrapTemporal: (storeInitializer) =>
            persist(storeInitializer, {
              name: `pipeline-history-${id}`,
              storage: createJSONStorage(() => sessionStorage)
            }),
          equality: (a, b) => {
            return isEqual(a.workingCopy, b.workingCopy)
          }

          // TODO: implement diff using microdiff as in zundo docs
          // rather than storing full pipeline states
        }
      ),
      {
        name: `pipeline-store-${id}`,
        enabled: config.environment === Environment.dev
      }
    )
  )

/**
 * **This hook is only for internal use within the WorkingCopyProvider--if you want
 * access to the current pipeline, please call `useWorkingCopy` instead.**
 *
 * Creates a zustand store for the pipeline represented by the provided id.
 * If one has been created previously, a cached version will be returned.
 *
 * @private
 */
export const useWorkingCopyStore = (id: string) =>
  useMemo(() => createWorkingCopyStore(id), [id])
