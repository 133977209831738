import { useMutation } from '@tanstack/react-query'

import useCopilotClient from 'api/hooks/useCopilotClient/useCopilotClient'

import { generateSQLExpression } from '../requests'

export interface PipelineMetadata {
  projectId: string
  branchId: string
  pipelineId: string
}

export interface SQLExpressionRequest {
  requestId: string
  warehouse: string
  message: string
  pipelineMetadata: PipelineMetadata
  columns: Array<{
    name: string
    type: string
  }>
}

export interface SQLExpressionResponse {
  sql: string
}

export const useGenerateSQLExpression = () => {
  const client = useCopilotClient()

  return useMutation(async (request: SQLExpressionRequest) => {
    const response = await generateSQLExpression(client, request)
    return response.data
  })
}
