import { useEffect, useRef } from 'react'

import { LoadingSpinner } from '@matillion/component-library'

import { type CopilotChatMessage } from '../../../core/Copilot/types'
import { scrollToBottom } from '../../util/scrollUtil'
import classes from './ChatHistory.module.scss'
import ChatMessage from './ChatMessage/ChatMessage'

interface ChatHistoryProps {
  messages: CopilotChatMessage[]
  isLoading: boolean
}

const ChatHistory = ({ messages, isLoading }: ChatHistoryProps) => {
  const scrollableChatRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    scrollToBottom(scrollableChatRef)
  }, [messages, isLoading])

  return (
    <div
      className={classes.ChatHistory__Container}
      ref={scrollableChatRef}
      data-testid={'scrollable-chat-container'}
    >
      {messages.map((message, index) => {
        return (
          <ChatMessage
            key={message.id}
            message={message}
            lastMessage={index === messages.length - 1}
            scrollChat={() => {
              scrollToBottom(scrollableChatRef)
            }}
          />
        )
      })}
      {isLoading && <LoadingSpinner className={classes.ChatHistory__Loading} />}
    </div>
  )
}

export default ChatHistory
