import type { Job } from 'file-editors/canvas/modules/Canvas/hooks/useCanvasModel/useCanvasModel'

import type {
  ParameterOptionsRequest,
  ParameterOptionsRequestType
} from 'hooks/useParameterOptions/useParameterOptionsTypes'

import type { OrchestrationJob, TransformationJob } from 'job-lib/types/Job'
import type { JobType } from 'job-lib/types/JobType'

import type {
  AutoDocsRequest,
  AutoDocsResponse
} from '../sidekick/useGeneratePipelineDocs/useGeneratePipelineDocs'
import type { AgentDetailsResponse } from '../useAgentDetails/useAgentDetails'
import type { StackTraceMessages } from '../useAgentStackTraces/useAgentStackTraces'
import { useDesignerApiClientContext } from '../useDesignerApiClient/useDesignerApiClientContext'
import type { ComponentMetadataResponse } from '../useGetComponentMetadata/types'
import type { ConnectorComponentSummaryResult } from '../useGetComponentSummaries'
import type { JobSummaryResponse } from '../useGetJobSummaries'
import type { GetParameterOptionsResponse } from '../useGetParameterOptions/types'
import type { ListBranchesResponse } from '../useListBranches/types'
import type { ListEnvironmentsResponse } from '../useListEnvironments/types'
import type { ListProjectResponse } from '../useListProjects/types'
import type {
  MetlJobMigrationResponse,
  MigrationMetlJobRequest
} from '../useMigrationMetlJob'
import type { PublishJobsRequest } from '../usePublishJobs/types'
import type { RunJobRequest, RunJobResponse } from '../useRunJob/types'
import type { SampleComponentRequestBody } from '../useSampleComponent/types'
import type { SampleRowCountRequestBody } from '../useSampleRowCount/types'
import type { SaveJobResponse } from '../useSaveJob/useSaveJob'
import type { ListSchemasResponse } from '../useSchemas'
import type { ListSchemaTablesResponse } from '../useSchemaTables/types'
import type { ValidateComponentResponse } from '../useValidateComponent/types'

export const useDesignerApi = () => {
  const client = useDesignerApiClientContext()

  // Agents

  const getAgentDetails = async (agentId: string) => {
    return client.get<AgentDetailsResponse>(`/v1/agents/${agentId}`)
  }

  const getAgentStackTraces = async (workerRequestIds: string) => {
    return client.get<StackTraceMessages>(
      `/v1/tasks/traces?ids=${encodeURIComponent(workerRequestIds)}`
    )
  }

  // Branches

  const getBranches = async (projectId: string) => {
    return client.get<ListBranchesResponse[]>(
      `/v1/branches?projectId=${projectId}`
    )
  }

  // Components

  const getComponentMetadata = async (cisId: string, agentVersion: string) => {
    return client.get<ComponentMetadataResponse>(
      `/v1/components/${encodeURIComponent(cisId)}?agentVersion=${agentVersion}`
    )
  }

  const getComponentSummaries = async (
    agentVersion: string,
    componentSources: 'CUSTOM' | 'STATIC'
  ) => {
    return client.get<ConnectorComponentSummaryResult>(
      `/v1/components?agentVersion=${agentVersion}&componentSources=${componentSources}`
    )
  }

  // Docs

  const generatePipelineDocs = async (
    agentVersion: string,
    request: AutoDocsRequest
  ) => {
    const requestConfig = {
      headers: {
        'agent-version': agentVersion
      }
    }
    return client.post<AutoDocsResponse>(
      '/v1/sidekick/generate-pipeline-docs',
      request,
      requestConfig
    )
  }

  // Environments

  const getEnvironments = async (projectId: string) => {
    return client.get<ListEnvironmentsResponse>(
      `/v1/environments?projectId=${projectId}`
    )
  }

  // Jobs

  const getJobSummaries = async (projectId: string, branchId: string) => {
    return client.get<JobSummaryResponse>(
      `/v1/projects/${projectId}/branches/${branchId}/jobs`
    )
  }

  const getJob = async (
    warehouse: string,
    agentVersion: string,
    projectId: string,
    branchId: string,
    jobId: string
  ) => {
    return client.get<OrchestrationJob | TransformationJob>(
      `/v1/projects/${projectId}/branches/${branchId}/jobs/${jobId}?dataWarehouseType=${warehouse}`,
      {
        headers: {
          'agent-version': agentVersion
        }
      }
    )
  }

  const saveJob = async (
    warehouse: string,
    agentVersion: string,
    projectId: string,
    branchId: string,
    jobSummaryId: string,
    request: Job
  ) => {
    return client.put<SaveJobResponse>(
      `/v2/projects/${projectId}/branches/${branchId}/jobs/${jobSummaryId}?dataWarehouseType=${warehouse}`,
      request,
      {
        headers: {
          // agent-version is required for DPL conversion.
          'agent-version': agentVersion
        }
      }
    )
  }

  const runJob = async (warehouse: string, request: RunJobRequest) => {
    return client.post<RunJobResponse>(
      `/v1/job-executions?dataWarehouseType=${warehouse}`,
      request
    )
  }

  const publishJobs = async (
    warehouse: string,
    request: PublishJobsRequest
  ) => {
    return client.post(
      `/v1/job-publications?dataWarehouseType=${warehouse}`,
      request
    )
  }

  const migrateMetlJob = async ({
    projectId,
    branchId,
    warehouse,
    file,
    agentVersion,
    dryRun,
    path,
    mappings
  }: MigrationMetlJobRequest) => {
    const endpoint = `/v1/projects/${projectId}/branches/${branchId}/migrate`

    const searchParams = new URLSearchParams({
      dataWarehouseType: warehouse.toUpperCase(),
      dryRun: Boolean(dryRun).toString(),
      path
    })

    const formData = new FormData()
    formData.append('file', file)
    if (mappings !== '') {
      formData.append(
        'mappings',
        new Blob([mappings], { type: 'application/json' })
      )
    }

    const url = `${endpoint}?${searchParams.toString()}`
    return client.post<MetlJobMigrationResponse>(url, formData, {
      headers: {
        'agent-version': agentVersion
      }
    })
  }

  // Parameters

  const getParameterOptions = async <T extends ParameterOptionsRequestType>({
    requestType,
    lookupType,
    projectId,
    branchId,
    warehouse,
    environmentId,
    agentId,
    requestBody
  }: ParameterOptionsRequest) => {
    return client.post<GetParameterOptionsResponse<T>>(
      `/v1/${requestType}/${lookupType}?projectId=${projectId}&branchName=${branchId}&environmentId=${environmentId}&agentId=${agentId}&dataWarehouseType=${warehouse}`,
      requestBody
    )
  }

  // Projects

  const getProjects = async () => {
    return client.get<ListProjectResponse>('/v1/projects')
  }

  // Sampling

  const sampleTransformationRowCount = async (
    request: SampleRowCountRequestBody
  ) => {
    return client.post('/v1/sample/transformation/rowcount', request)
  }

  const sampleTransformationJob = async (
    request: SampleComponentRequestBody
  ) => {
    return client.post('/v2/sample/transformation', request)
  }

  const sampleOrchestrationJob = async (
    request: SampleComponentRequestBody
  ) => {
    return client.post('/v1/sample/orchestration', request)
  }

  // Schemas

  const getSchemas = async (
    projectId: string,
    environmentId: string,
    warehouse: string
  ) => {
    return client.get<ListSchemasResponse>(
      `/v1/projects/${projectId}/environments/${environmentId}/warehouse/${warehouse}/schemas`
    )
  }

  const getSchemaTables = async (
    projectId: string,
    environmentId: string,
    warehouse: string,
    schemaName: string
  ) => {
    return client.get<ListSchemaTablesResponse>(
      `/v1/projects/${projectId}/environments/${environmentId}/warehouse/${warehouse}/schemas/${schemaName}/tables`
    )
  }

  const getSchemaViews = async (
    projectId: string,
    environmentId: string,
    warehouse: string,
    schemaName: string
  ) => {
    return client.get<ListSchemaTablesResponse>(
      `/v1/projects/${projectId}/environments/${environmentId}/warehouse/${warehouse}/schemas/${schemaName}/views`
    )
  }

  // Validation

  const validateComponent = async (
    jobType: JobType,
    request: any // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => {
    return client.post<ValidateComponentResponse>(
      `/v1/validation/${jobType.toLowerCase()}`,
      request
    )
  }

  return {
    // Agents
    getAgentDetails,
    getAgentStackTraces,
    // Branches
    getBranches,
    // Components
    getComponentMetadata,
    getComponentSummaries,
    // Docs
    generatePipelineDocs,
    // Environments
    getEnvironments,
    // Jobs
    getJobSummaries,
    getJob,
    saveJob,
    runJob,
    publishJobs,
    migrateMetlJob,
    // Parameters
    getParameterOptions,
    // Projects
    getProjects,
    // Sampling
    sampleTransformationRowCount,
    sampleTransformationJob,
    sampleOrchestrationJob,
    // Schemas
    getSchemas,
    getSchemaTables,
    getSchemaViews,
    // Validation
    validateComponent
  }
}
