import { useCallback } from 'react'

import {
  unknownOrchestrationSummaryId,
  unknownTransformationSummaryId
} from 'job-lib/cisIds/knownComponentParameters'
import { isOrchestrationJob } from 'job-lib/job-functions/job-functions'
import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'
import { type ParameterCollection } from 'job-lib/types/Parameters'

import {
  getDPLParameters,
  getParameterDPLValue
} from 'modules/ComponentParameters/utils/getParameterValue'

import { useComponentSummaries } from '.'

const API_EXTRACT_COMPONENTS: Record<string, string> = {
  720344340: 'api-extract-input-v1',
  720361501: 'api-extract-input-v2'
}
export const UNKNOWN_ORCHESTRATION_ID = 'unknown-orchestration'

export const useGetComponentSummary = () => {
  const componentSummaries = useComponentSummaries()
  return {
    isLoading: componentSummaries.isLoading,
    isError: componentSummaries.isError,
    getByComponentId: useCallback(
      (componentId: string) =>
        componentSummaries.data.find((x) => x.componentId === componentId),
      [componentSummaries]
    ),
    /**
     * The problem here is that we use implementation ID
     * as the key in the job model. All custom connectors have the same implementation ID, the API extract ID.
     * Therefore, we must use the profile parameter to determine which custom connector is being used.
     */
    getByImplementationId: useCallback(
      (
        implementationId: string,
        parameters: ParameterCollection,
        job: TransformationJob | OrchestrationJob | null
      ) => {
        if (implementationId in API_EXTRACT_COMPONENTS) {
          const params = getDPLParameters(null, parameters)
          const connectorId = getParameterDPLValue(
            [API_EXTRACT_COMPONENTS[implementationId], 'profile'],
            params
          )
          return (
            componentSummaries.data.find(
              (x) => x.componentId === connectorId
            ) ??
            componentSummaries.data.find(
              (x) => x.componentId === unknownOrchestrationSummaryId
            )
          )
        } else {
          const summary = componentSummaries.data.find(
            (x) => x.emeraldId === implementationId
          )

          if (summary) {
            return summary
          }

          const isOrchestrationPipeline = isOrchestrationJob(job)
          const unknownComponentId = isOrchestrationPipeline
            ? unknownOrchestrationSummaryId
            : unknownTransformationSummaryId

          return componentSummaries.data.find(
            (x) => x.componentId === unknownComponentId
          )
        }
      },
      [componentSummaries]
    )
  }
}
