import { useCallback, useMemo, useState, type FC, type ReactNode } from 'react'
import { useDispatch } from 'react-redux'

import axios from 'axios'
import { PipelineType } from 'types/Pipeline'
import { v4 as uuid } from 'uuid'

import type { ComponentWithMetadata } from 'api/hooks/copilot/model'
import { type FeedbackSentiment } from 'api/hooks/copilot/types'
import { useGeneratePipeline } from 'api/hooks/copilot/useGeneratePipeline/useGeneratePipeline'
import { useSubmitFeedback } from 'api/hooks/copilot/useSubmitFeedback/useSubmitFeedback'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { jobActions } from 'job-lib/store'
import { type TransformationJob } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { CopilotContext } from './context'
import { setMETLPositionFromPipeline } from './formatMETL'
import { formatTransformation } from './formatter'
import { type CopilotChatMessage } from './types'

export interface CopilotProviderProps {
  children: ReactNode
}

export const CopilotProvider: FC<CopilotProviderProps> = ({ children }) => {
  const dispatch = useDispatch()
  const [chatMessages, setChatMessages] = useState<CopilotChatMessage[]>([])

  const { generatePipeline, isLoading: isLoadingUpdatePipeline } =
    useGeneratePipeline()
  const {
    mutateAsync: submitFeedbackRequest,
    isLoading: isLoadingSubmitFeedback
  } = useSubmitFeedback()
  const addInfoMessage = useCallback((message: CopilotChatMessage) => {
    setChatMessages((existing) => [...existing, message])
  }, [])

  const submitFeedback = useCallback(
    async (
      message: CopilotChatMessage,
      sentiment: FeedbackSentiment,
      content: string
    ) => {
      if (!message.requestId || !message.service) {
        throw new Error('An unexpected error occurred')
      }
      await submitFeedbackRequest({
        requestId: message.requestId,
        response: sentiment,
        type: message.service,
        comments: content
      })
    },
    [submitFeedbackRequest]
  )

  const updatePipeline = useCallback(
    async (
      query: CopilotChatMessage,
      selectedComponents?: ComponentWithMetadata[]
    ): Promise<void> => {
      try {
        addInfoMessage(query)
        const response = await generatePipeline(
          query.content,
          selectedComponents
        )
        addInfoMessage({
          id: uuid(),
          type: 'bot',
          content: response.choices[0].summary,
          requestId: response.requestId,
          service: 'PIPELINE_GENERATION'
        })

        const choice = response?.choices[0]

        // Only layout components if the job is a transformation for now.
        if (choice.source.type === PipelineType.Transformation) {
          const newComponentNames = new Set<string>()
          Object.keys(choice?.addedComponents).forEach((comp) =>
            newComponentNames.add(comp)
          )

          // We format the actual DPL and then apply that back to the METL job. This means that we can just remove the
          // setMETLPositionFromPipeline function in the future.
          formatTransformation(choice.source, newComponentNames)
          setMETLPositionFromPipeline(
            choice?.legacyMETL as TransformationJob,
            choice.source
          )

          dispatch(
            jobActions.setJobAndSave({
              job: choice?.legacyMETL,
              jobType: JobType.Transformation
            })
          )
        } else {
          dispatch(
            jobActions.setJobAndSave({
              job: choice?.legacyMETL,
              jobType: JobType.Orchestration
            })
          )
        }
      } catch (error: unknown) {
        if (
          axios.isAxiosError<ProblemDetails>(error) &&
          error.response?.data.type?.startsWith('copilot/message/')
        ) {
          addInfoMessage({
            id: uuid(),
            type: 'bot',
            content:
              error.response.data.detail ??
              'I am unable to handle that request.',
            errorType: error.response.data.type
          })
        } else {
          throw error
        }
      }
    },
    [addInfoMessage, dispatch, generatePipeline]
  )

  const value = useMemo(
    () => ({
      chatMessages,
      addInfoMessage,
      updatePipeline,
      submitFeedback,
      isLoadingUpdatePipeline,
      isLoadingSubmitFeedback
    }),
    [
      chatMessages,
      addInfoMessage,
      updatePipeline,
      submitFeedback,
      isLoadingUpdatePipeline,
      isLoadingSubmitFeedback
    ]
  )

  return (
    <CopilotContext.Provider value={value}>{children}</CopilotContext.Provider>
  )
}
