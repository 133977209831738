import { type FileType } from '@matillion/git-component-library'

import {
  type JobSummary,
  type JobSummaryId
} from 'api/hooks/useGetJobSummaries'

export const initialFileTreeReducerState: FileTreeState = {
  fileTree: [],
  folderPath: null,
  deletedFiles: [],
  isCreatingFolder: false,
  isDeletingFolder: false,
  hasReachedFolderLimit: false
}

export type FileTree = FileNode[]

export type FileNodeType = 'file' | 'folder'

export interface FileNode {
  /**
   * The name of the file or folder.
   * For a file this is the name without the path or extension.
   * For a folder this is just the folder name with no ancestors.
   */
  name: string

  /**
   * Whether the node is in the root (top-level).
   */
  root: boolean

  /**
   * The fully qualified path to the file or folder.
   * For a file this the ID (path + name + extension).
   * For a folder this is the full path from the root.
   */
  path: string

  /**
   * The file summary object. Only assigned for
   * nodes that are files.
   */
  file?: JobSummary

  /**
   * The type of node. Folder or file.
   */
  type: FileNodeType

  /**
   * Child nodes that sit inside the current one.
   * Only applicable for folder nodes.
   */
  children: FileNode[]
}

export enum FileTreeReducerType {
  /**
   * Adds a new folder to the file tree at the given path.
   */
  ADD_FOLDER,

  /**
   * Adds a new folder to the root of the file tree.
   */
  ADD_ROOT_FOLDER,

  /**
   * Removes a single folder from the tree.
   */
  REMOVE_FOLDER,

  /**
   * Removes a single file from the tree.
   */
  REMOVE_FILE,

  /**
   * Moves a file from one folder to another.
   */
  MOVE_FILE,

  /**
   * Overwrites all file tree data with a new structure
   * built from an array of file summaries. Any empty folders
   * currently persisted client-side will be first pruned based on
   * the folder nodes present in the summaries, and then the resulting
   * folders will be merged into the tree.
   */
  OVERWRITE_TREE,

  /**
   * Initiates the folder creation flow. This action is not
   * responsible for actually creating a folder, but to render
   * the modal instead.
   */
  CREATE_FOLDER,

  /**
   * Handles the deletion of a single folder node and all its children from
   * the tree.
   *
   * Sets a  collection of file IDs in the state for all
   * the files that were nested under the deleted folder. The tree itself
   * is not actually mutated (unless the folder is empty) as it is rebuilt
   * from the server-side WTS data after the deletion has happened.
   */
  DELETE_FOLDER,

  /**
   * Manually sets the folder path in the file tree state. Used
   * to ensure that a file created in a folder is prepended with
   * the path of that folder upon creation.
   */
  SET_FOLDER_PATH,

  /**
   * Resets the file tree to its default state, excluding
   * the tree data itself.
   */
  RESET
}

export interface FileDetails {
  /**
   * The raw name of the file entered by the user
   * to act upon. Does not include the folder path or
   * the file extension.
   */
  name?: string

  /**
   * The folder path the file from the root of the project.
   * Should not contain a trailing slash.
   */
  path?: string

  /**
   * The type of the file that is being acted upon.
   */
  fileType?: FileType

  /**
   * The extension of the file.
   */
  extension?: string
}

export interface FileTreeAction {
  /**
   * The type of command to be dispatched.
   */
  type: FileTreeReducerType

  /**
   * The details of the file that is being acted upon.
   * */
  fileDetails?: FileDetails

  /**
   * The current branch checkout out in Git.
   */
  branch?: string

  /**
   * The current project name that is active.
   */
  project?: string

  /**
   * The target directory for the given action. This should
   * be a full file path from the root of the project, with
   * no trailing slash.
   */
  targetDirectory?: string

  /**
   * An array of file summaries for the most up-to-date
   * working tree copy for the project.
   */
  summaries?: JobSummary[]
}

export interface FileTreeState {
  /**
   * The path of the folder in question. Used by several folder actions.
   * A null folder path indicates the root directory.
   */
  folderPath: string | null

  /**
   * The file tree itself. This is the main piece of state that tracks
   * the whole tree data structure of file and folder nodes.
   */
  fileTree: FileTree

  /**
   * Indicates that the user has actioned the creation of a folder.
   * Drives conditional rendering logic such as the create folder modal.
   */
  isCreatingFolder: boolean

  /**
   * Indicates that the user has actioned the deletion of a folder.
   * Drives conditional rendering logic such as the delete folder modal.
   */
  isDeletingFolder: boolean

  /**
   * Indicates that the user has reached the maximum number of nested folders
   * for the current (create folder) operation. Drives logic to ensure the
   * user cannot create more folders that the limit.
   */
  hasReachedFolderLimit: boolean

  /**
   * An array of file IDs that are to be deleted.
   */
  deletedFiles: JobSummaryId[]
}
