import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryKeys } from '../../queryKeys'
import useEosClient from '../useEosClient/useEosClient'

interface CancelTaskParams {
  flowInstanceId: string
  force: boolean
}

export const useCancelTask = () => {
  const client = useEosClient()
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, CancelTaskParams>(
    async ({ flowInstanceId, force }) =>
      client.patch(
        `/v1/activity/${flowInstanceId}/cancellation?force=${force}&source=saas-etl-designer`
      ),
    {
      // for either success or fail, refresh the task history
      onSettled: async () =>
        Promise.all([
          queryClient.invalidateQueries([queryKeys.taskStatus]),
          queryClient.invalidateQueries([queryKeys.activity])
        ])
    }
  )
}
