import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

import useEosClient from 'api/hooks/useEosClient/useEosClient'

import { type ActivityItem } from '../useEosClient/types/eos'
import { getRootPipelineActivity } from './__handlers__/getRootPipelineActivity'

export const useGetRootPipelineActivity = (
  pipelineId: string,
  options?: UseQueryOptions<ActivityItem | undefined, AxiosError>
) => {
  const client = useEosClient()
  return useQuery<ActivityItem | undefined, AxiosError>(
    ['rootPipelineActivity', pipelineId],
    async () => getRootPipelineActivity(client, pipelineId),
    options
  )
}
