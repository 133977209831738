import { useMemo, type ReactNode } from 'react'

import { Typography } from '@matillion/component-library'

import classes from './ComponentName.module.scss'

interface Props {
  displayName: string
  searchTerm: string
  children?: ReactNode
  isDraggable: boolean
}

const ComponentName = ({
  displayName,
  searchTerm,
  isDraggable,
  children
}: Props) => {
  const typographySize = isDraggable ? 'bcs' : 'bcm'
  const text = useMemo(() => {
    return searchTerm
      ? displayName
          .split(new RegExp(`(\\b${searchTerm})`, 'gi'))
          .map((part, i) =>
            part.localeCompare(searchTerm, undefined, {
              sensitivity: 'base'
            }) ? (
              part
            ) : (
              <Typography
                key={i}
                format={typographySize}
                as="span"
                weight="bold"
              >
                {part}
              </Typography>
            )
          )
      : displayName
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  return (
    <div className={classes.ComponentName}>
      <Typography
        format={typographySize}
        as="span"
        className={classes.ComponentName__Text}
      >
        {text}
      </Typography>
      {children}
    </div>
  )
}

export { ComponentName }
