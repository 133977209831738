import React, { lazy, Suspense, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'

import { DesignerModal } from '../../../../../components/DesignerModal/DesignerModal'
import { Loading } from '../../../../../components/Loading/Loading'
import classes from './MappingModal.module.scss'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

export interface MappingModalProps {
  onClose: () => void
  value: string
  onValueChange: (value: string) => void
  onRerun: () => void
}

const MappingModal = ({
  onClose,
  value,
  onValueChange,
  onRerun
}: MappingModalProps) => {
  const { t } = useTranslation()

  const isValidValue = useCallback(() => {
    if (!value || value === '') {
      return true
    }

    try {
      JSON.parse(value)
      return true
    } catch (e) {
      return false
    }
  }, [value])

  return (
    <DesignerModal
      className={classes.Modal}
      size={'mid-large'}
      onCancel={() => {
        onClose()
      }}
      ariaLabelledBy="mapping-modal"
      data-testid="mapping-modal"
    >
      <Typography as="h2" format="tm" data-testid="mapping-modal-title">
        {t('conversion.report.mappings.modal.title')}
      </Typography>
      <Typography as="p" format="bcm">
        {t('conversion.report.mappings.modal.summary')}
      </Typography>
      <Typography as="p" format="bcm">
        <Trans
          t={t}
          i18nKey="conversion.report.mappings.modal.help"
          components={{
            DocsLink: (
              <a
                aria-label="mappings-help-docs"
                target="_blank"
                href="https://docs.matillion.com/data-productivity-cloud/designer/docs/migration-mappings"
                rel="noreferrer"
              ></a>
            )
          }}
        />
      </Typography>
      <div className={classes.EditorContainer}>
        <Suspense fallback={<Loading />}>
          <CodeEditor value={value} onChange={onValueChange} language="yaml" />
        </Suspense>
      </div>
      <div className={classes.Buttons}>
        <Button
          alt="secondary"
          onClick={onClose}
          text={t('cancel')}
          data-testid="mappings-cancel"
        />
        <Button
          alt="primary"
          type="submit"
          text={t('conversion.report.mappings.modal.applyButton')}
          disabled={!isValidValue()}
          onClick={() => {
            onRerun()
          }}
          data-testid="conversion-tool-add-mappings-modal-apply"
        />
      </div>
    </DesignerModal>
  )
}

export default MappingModal
