import { useContext } from 'react'

import { DesignerApiClientContext } from './DesignerApiClientContext'

export const useDesignerApiClientContext = () => {
  const context = useContext(DesignerApiClientContext)

  if (context === undefined) {
    throw new Error(
      'useDesignerApiClientContext must be used within a DesignerApiClientProvider'
    )
  }

  return context
}
