import { type FC } from 'react'

import {
  type FileNode,
  type FileTree as PipelineTreeData
} from '../../reducers/fileTreeReducer/types'
import FileFolder from '../FileFolder/FileFolder'
import { type FileDropDetails } from '../FileFolderDropDestination/FileFolderDropDestination'
import FileItem from '../FileItem/FileItem'

interface FileTreeProps {
  data: PipelineTreeData
  moveFile: (details: FileDropDetails) => void
}
const extractFolderPathFromFilePath = (path: string): string => {
  const segments = path.split('/')
  const pathSegments = segments.slice(0, segments.length - 1)
  return pathSegments.join('/')
}

const nodeIsEmpty = (node: FileNode): boolean => {
  for (const child of node.children) {
    if (
      child.type === 'file' ||
      (child.type === 'folder' && !nodeIsEmpty(child))
    ) {
      return false
    }
  }
  return true
}

const FileTree: FC<FileTreeProps> = ({ data, moveFile }) => {
  return (
    <div>
      {data.map((node: FileNode) => {
        if (node.type === 'file' && node.file) {
          return (
            <FileItem
              isLeaf={!node.root}
              file={node.file}
              key={node.file.jobId}
              path={extractFolderPathFromFilePath(node.path)}
            />
          )
        }

        return (
          <FileFolder
            id={node.path}
            key={node.path}
            name={node.name}
            isEmpty={nodeIsEmpty(node)}
            moveFile={moveFile}
          >
            <FileTree data={node.children} moveFile={moveFile} />
          </FileFolder>
        )
      })}
    </div>
  )
}

export default FileTree
