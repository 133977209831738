import { useCallback, useContext } from 'react'
import { type XYPosition } from 'reactflow'

import { type Dispatch } from 'redux'
import { PipelineType } from 'types/Pipeline'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { jobActions } from 'job-lib/store'
import { JobType } from 'job-lib/types/JobType'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'
import { useWorkingCopy as useDPLWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useWorkingCopy'

import { useEtlFlow } from '../../hooks/useEtlFlow'
import {
  calculatePastedComponentsPositionDPL,
  calculatePastedComponentsPositions
} from './calculatePastedComponentsPositions'
import {
  CopyPasteContext,
  useCopyPasteContext,
  type BaseCopiedComponentContent
} from './CopyPasteContext'

export const usePasteComponent = (dispatch: Dispatch) => {
  const {
    componentInstances,
    componentType,
    currentJobId,
    currentConnectors,
    copiedComponentOffsets,
    setCopiedComponentContent
  } = useContext(CopyPasteContext)

  const {
    components,
    links,
    componentOffsets,
    sourcePipelineType,
    sourcePipelineName
  } = useCopyPasteContext()
  const reactFlowInstance = useEtlFlow()
  const { jobSummaryId } = useProjectInfo()
  const { jobType } = useWorkingCopy()
  const { rolloutEnableWorkingCopyProvider } = useFlags()
  const update = useDPLWorkingCopy((state) => state.update)

  const pasteComponent = useCallback(
    (manualPosition?: XYPosition) => {
      if (!componentInstances || componentInstances.length === 0) {
        console.warn('Paste component failed, no component has been copied')
        return
      }
      if (componentType && jobType && jobType !== componentType) {
        console.warn(
          `Cannot paste ${componentType.toLowerCase()} component into ${jobType.toLowerCase()} job`
        )
        return
      }

      // @ts-expect-error the union is collapsed when we deconstruct the componentInstances and componentType
      const pastedState: BaseCopiedComponentContent = {
        componentInstances,
        componentType,
        currentConnectors
      }

      pastedState.componentInstances = calculatePastedComponentsPositions(
        componentInstances,
        {
          reactFlowInstance,
          copiedComponentOffsets,
          manualPosition,
          currentJobId,
          jobSummaryId
        }
      )

      setCopiedComponentContent(jobSummaryId, pastedState)

      if (pastedState.componentInstances) {
        dispatch(jobActions.cloneComponentGroup(pastedState))
      }
    },
    [
      componentInstances,
      componentType,
      jobType,
      currentConnectors,
      reactFlowInstance,
      copiedComponentOffsets,
      currentJobId,
      jobSummaryId,
      setCopiedComponentContent,
      dispatch
    ]
  )

  // istanbul ignore next
  const dplPasteComponent = (pastePosition?: XYPosition) => {
    if (!components || components.length === 0) {
      console.warn('Paste component failed, no component has been copied')
      return
    }

    const targetPipelineType =
      jobType === JobType.Orchestration
        ? PipelineType.Orchestration
        : PipelineType.Transformation

    if (
      sourcePipelineType &&
      jobType &&
      targetPipelineType !== sourcePipelineType
    ) {
      console.warn(
        `Cannot paste ${sourcePipelineType.toLowerCase()} component into ${jobType.toLowerCase()} job`
      )
      return
    }

    const defaultPosition = reactFlowInstance.project({
      x: 40,
      y: 40
    })

    const componentsWithPosition = components.map((component) => {
      const componentDesign = component.componentDesign

      return {
        ...component,
        componentDesign: {
          ...componentDesign,
          position: calculatePastedComponentsPositionDPL(
            componentDesign,
            sourcePipelineName ?? '',
            jobSummaryId,
            defaultPosition,
            pastePosition,
            componentOffsets?.find(
              (c) =>
                c.componentName === component.component.parameters.componentName
            )
          )
        }
      }
    })

    update((state) => {
      state.copyComponent({
        components: componentsWithPosition,
        links,
        pastePosition,
        componentOffsets
      })
    })
  }

  return {
    // istanbul ignore if
    pasteComponent: rolloutEnableWorkingCopyProvider
      ? dplPasteComponent
      : pasteComponent
  }
}
