import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { usePipelineBrowserCommandProvider } from 'modules/FileBrowser/effects/usePipelineBrowserCommand'

import { FileTreeReducerType } from '../../reducers/fileTreeReducer/types'
import CreatePipelineContextMenuEntries from '../CreatePipelineContextMenuEntries/CreatePipelineContextMenuEntries'

const FilePanelContextMenu: FC = () => {
  const { t } = useTranslation()
  const { onFileTreeCommand } = usePipelineBrowserCommandProvider()

  const handleCreateFolder = () => {
    onFileTreeCommand({
      fileDetails: { path: '' },
      type: FileTreeReducerType.CREATE_FOLDER
    })
  }

  return (
    <PopOverSection>
      <CreatePipelineContextMenuEntries entryContext="addButton" />
      <PopOverItem
        onClick={handleCreateFolder}
        data-testid="job-action-create-folder"
      >
        {t('jobContextMenu.createFolder')}
      </PopOverItem>
    </PopOverSection>
  )
}

export default FilePanelContextMenu
