import { useQuery } from '@tanstack/react-query'

import useEosClient from 'api/hooks/useEosClient/useEosClient'

import { queryKeys } from '../../queryKeys'
import { type PipelineStatus } from '../useEosClient/types/eos'
import { getActivity } from './__handlers__/getActivity'

export const useGetActivity = (
  projectId: string,
  workspaceId: string,
  status: PipelineStatus[],
  timeFrame?: string
) => {
  const client = useEosClient()
  const refetchInterval = process.env.REACT_APP_STATUS_OVERRIDE_REFETCH
    ? Number(process.env.REACT_APP_STATUS_OVERRIDE_REFETCH)
    : 3000
  return useQuery(
    [queryKeys.activity, projectId, workspaceId, ...status],
    async () =>
      (await getActivity(client, projectId, workspaceId, status, timeFrame))
        .results,
    { refetchInterval }
  )
}
